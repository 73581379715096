.purchase-order-form-multi-email {
  max-width: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  outline: 0;
  -webkit-tap-highlight-color: var(--white);
  text-align: left;
  line-height: 1.21428571em;
  padding: 0.4em 0.5em;
  background: var(--white);
  border: 1px solid var(--gray-200);
  border-radius: var(--border-radius-md);
  color: var(--dark-grey);
  -webkit-transition:
    box-shadow 0.1s ease,
    border-color 0.1s ease;
  transition:
    box-shadow 0.1s ease,
    border-color 0.1s ease;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: flex-start;
  margin: var(--project-screen-element-margin) 0;
}
.purchase-order-form-multi-email > span[data-placeholder] {
  display: none;
  position: absolute;
  left: 0.5em;
  top: 0.4em;
  padding: 0.4em;
  line-height: 1.21428571em;
}
.purchase-order-form-multi-email.focused {
  background: var(--warm-light-grey);
}

.purchase-order-form-multi-email.empty > span[data-placeholder] {
  display: inline;
  background-color: var(--white);
  color: var(--dark-grey);
}
.purchase-order-form-multi-email.focused > span[data-placeholder] {
  display: none;
}

.purchase-order-form-multi-email > input {
  flex: 1;
  width: 100% !important;
  outline: none !important;
  border: 0 none !important;
  display: inline-block !important;
  line-height: 1;
  vertical-align: baseline !important;
  padding: 0.4em 0.1em !important;
  background-color: transparent;
  color: var(--text-primary-color);
}

.purchase-order-form-multi-email [data-tag] {
  line-height: 1.2;
  vertical-align: baseline;
  margin: 4px;
  background-color: var(--white);
  height: 28px;
  background-image: none;
  padding: 0 10px;
  color: var(--header-text);
  text-transform: none;
  font-weight: 600;
  border: 1px solid var(--gray-200);
  border-radius: var(--border-radius-md);
  -webkit-transition: background 0.1s ease;
  -o-transition: background 0.1s ease;
  transition: background 0.1s ease;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
}

.budget-approval-email-field {
  cursor: pointer;
}

.approval-not-required-email {
  background-color: var(--light-grey) !important;
  color: var(--text-primary-color) !important;
}

.circle-icon-small {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-block;
}

.purchase-order-form-multi-email [data-tag] [data-tag-item] {
  max-width: 100%;
  overflow: hidden;
}
.purchase-order-form-multi-email [data-tag]:first-child {
  margin-left: 0;
}
.purchase-order-form-multi-email [data-tag] [data-tag-handle] {
  margin-left: 0.833em;
  cursor: pointer;
}

.purchase-order-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  gap: 8px;
}

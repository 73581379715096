.generate-booking-select-service_container {
  align-items: flex-start;
  border-radius: var(--border-radius-md);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  padding: 8px;
  transition: all 0.1s;
  width: 100%;
}

.generate-booking-select-service_container--selected {
  background-color: var(--midground-color);
}

.generate-booking-select-service_children {
  width: 100%;
}

.generate-booking-select-service_container:hover {
  background-color: var(--midground-color);
}

.generate-booking-select-service_index {
  background-color: var(--midground-color);
  border-radius: 50%;
  height: 24px;
  min-width: 24px;
  position: relative;
}

.generate-booking-select-service_index-mix-master {
  margin: 14px 12px 14px 0;
}

.generate-booking-select-service_index-recording {
  margin: 10px 10px 10px 0;
}

.generate-booking-select-service_index-number {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.generate-booking-select-service_controls-container {
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 12px;
}

.generate-booking-select-service_controls-container > * {
  margin-left: 16px;
}

.generate-booking-select-service_delete-container {
  display: flex;
  align-items: center;
  color: var(--text-tertiary-color);
  border: none;
  background-color: transparent;
  padding-block: 0;
  padding-inline: 0;
}

.generate-booking-select-service_delete-container--selected {
  color: var(--text-primary-color) !important;
}

.generate-booking-select-service_delete-container--disabled {
  cursor: not-allowed !important;
}

.generate-booking-select-service_controls-container-text {
  color: inherit !important;
}

.generate-booking-select-service_delete-container > svg {
  margin-right: 4px;
  color: inherit !important;
}

.generate-booking-select-service_delete-container:hover {
  color: var(--text-primary-color);
  cursor: pointer;
}

.generate-booking-select-service_toggle-label {
  margin-right: 0 !important;
  margin-left: 6px !important;
}

.generate-booking-select-service_alts-container {
  margin-top: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: wrap;
  row-gap: 12px;
  column-gap: 8px;
  margin-left: 36px;
}

.generate-booking-select-service_alts-checkmark {
  margin-top: 0 !important;
  margin-right: 8px;
}

@media (max-width: 768px) {
  .generate-booking-select-service_container {
    flex-direction: column;
  }
}

.generate-booking-select-service_alts-checkmark-text {
  font-weight: 400 !important;
}

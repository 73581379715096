.studio-teammates-modal-container {
  background-color: var(--white);
  border-radius: 4px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 97vw;
  max-width: 600px;
  max-height: 90vh;
  min-height: 395px;
  padding: 30px;
}

.studio-teammates-header-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  border-bottom: 1px solid var(--light-grey);
  padding-top: 20px;
  padding-bottom: 30px;
}

.studio-teammates-close-modal {
  display: flex;
  margin-left: auto;
  cursor: pointer;
}

.studio-teammates-modal-body {
  height: 100%;
  overflow-y: scroll;
}

.studio-teammates-modal-section {
  padding-top: 15px;
}

.studio-teammates-display-container {
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.studio-teammates-display-container img {
  height: 85px;
  border-radius: 50%;
  border: 1px solid var(--light-grey);
  cursor: pointer;
}

.studio-teammates-display-name {
  cursor: pointer;
  padding: 0 10px;
  color: var(--black);
}

.studio-teammates-modal-section-title {
  text-transform: uppercase;
  color: var(--medium-grey);
}

.admin-dashboard-screen-container {
  display: flex;
  padding: 30px 0;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}

.admin-dashboard-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 64px;
  box-sizing: border-box;
}

.admin-dashboard-row-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 1290px;
}

.admin-dashboard-columns-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  max-width: 1290px;
  gap: 20px;
}

.admin-dashboard-right-column-container {
  width: 100%;
  max-width: 660px;
  overflow: hidden;
}

.admin-dashboard-left-column-container {
  width: 100%;
  max-width: 660px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: hidden;
}

.admin-dashboard-shared-container {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 768px) {
  .admin-dashboard-screen-container {
    height: 100%;
    width: 100%;
    justify-content: center;
    padding: unset;
    align-items: center;
    flex-direction: column;
  }

  .admin-dashboard-screen-container.container
    > .admin-dashboard-column-container:first-child {
    padding: unset;
  }

  .admin-dashboard-shared-container {
    width: 100%;
    overflow-y: auto;
    height: 100%;
    padding: unset;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .admin-dashboard-columns-container {
    flex-direction: column;
    align-items: center;
    max-width: unset;
    height: 100%;
  }

  .admin-dashboard-left-column-container {
    max-width: unset;
  }

  .admin-dashboard-right-column-container {
    max-width: unset;
    height: 100%;
  }
}

.notification-div {
  display: flex;
  cursor: pointer;
  height: fit-content;
  align-items: center;
  padding: 15px;
  position: relative;
  border-bottom: solid thin var(--light-grey);
}

.notification-date-time-stamp {
  margin-right: 8px;
  font-size: 12px;
  margin-bottom: 4px;
  color: #9b9b9b;
}

.notification-div .badge--img {
  width: 20px;
  height: 20px;
  /* overwrite default styling */
  position: relative;
  top: unset;
  transform: unset;
  margin-left: 20px;
}

.notification-text {
  margin-left: 14px;
  padding-right: 10px;
}

.read-key {
  min-height: 12px;
  min-width: 12px;
  border-radius: 12px;
  margin-left: auto;
  margin-right: 8px;
}

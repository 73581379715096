.checkout-content-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
}

.checkout-content-container > * {
  flex: 1;
  min-width: 300px;
}

@media screen and (max-width: 768px) {
  .checkout-content-container {
    flex-direction: unset;
    align-items: center;
  }
}

.order-summary-prev-button {
  height: 35px;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 1vh;
  justify-content: flex-start;
  position: relative;
  cursor: pointer;
}

.editable-text-area {
  border: none;
  overflow: auto;
  outline: none;
  background-color: var(--white);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none;
  font-family: Roobert, serif;
  transition: all linear 500ms;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: var(--stereo-black);
  display: flex;
  width: 100%;
}

.editable-text-area:enabled:not([type="submit"]) {
  border: solid thin var(--light-grey);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 12px;
}

.editable-text-area:enabled:not([type="submit"]):focus {
  border: solid 2px var(--deep-blue);
}

.editable-text-area-container {
  position: relative;
  width: 100%;
}

.character-count {
  position: absolute;
  right: 10px;
  bottom: 10px;
  color: var(--light-grey);
  animation: fadeIn 500ms ease-in-out;
}

.transactions-history-card {
  width: calc(100% - 30px);
  flex-direction: column;
  align-self: center;
  padding-left: 15px;
  padding-right: 15px;
}

.download-invoice-tooltip {
  display: inline-flex;
  margin-left: 8px;
}

.transactions-history-card-header {
  padding-top: 30px;
  padding-left: 30px;
  text-align: left;
  margin-right: auto;
  display: flex;
  align-items: center;
}

.transaction-table-container {
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

@media screen and (max-width: 768px) {
  .transactions-history-card {
    width: 90%;
    min-width: unset;
  }
  .transactions-history-card-header {
    justify-content: flex-start;
    padding-left: unset;
  }
}

.mobile-project-review .calendar-container {
  justify-content: center;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 576px) {
  .mobile-project-review .pagination-div {
    flex-direction: column;
  }

  .mobile-project-review .rescheduler th {
    width: unset !important;
  }
}

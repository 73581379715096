.navigation-row {
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .navigation-row-top-nav {
    display: none;
  }
}

.label-association-option {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 95%;
}

.label-association-option img {
  height: 20px;
}

.recording-booking-details-container {
  margin-bottom: 30px;
}
.recording-booking-details {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 14px;
}

.recording-booking-details-container > header {
  margin-bottom: 30px;
}

.session-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  border-bottom: 0.2px var(--medium-grey) solid;
}

.sessions-header > p {
  font-weight: 600;
  color: var(--medium-grey);
  font-size: 14px;
}

.add-session-button {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.recording-detail-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5px;
}

.recording-detail-item {
  cursor: pointer;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 30px;
  width: 100%;
}

.recording-detail-row-container {
  display: flex;
  flex-direction: column;
  width: 70%;
}

.recording-detail-aside {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 5px;
}

.selected-recording-detail {
  color: var(--boomy-orange);
}

.recording-detail-clickable-text {
  text-decoration: underline;
  text-transform: capitalize;
}

.recording-detail-clickable-text-clear-selections {
  text-decoration: underline;
  text-transform: capitalize;
  text-align: right;
  color: var(--dark-grey);
}

.recording-detail-column {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.text-dropdown {
  position: relative;
}

.booking-detail-drop-down-container {
  position: absolute;
  top: 30px;
  right: 0;
  width: 100%;
  min-width: 300px;
  max-height: 200px;
  background-color: var(--white);
  border: 1px solid var(--medium-grey);
  border-radius: 4px;
  overflow-y: scroll;
  z-index: 1;
}

.booking-detail-drop-down-container > p {
  padding: 10px;
  cursor: pointer;
  text-transform: capitalize;
}

.selected-recording-date-option {
  background-color: var(--timbre-tan) !important;
  text-transform: capitalize;
}

.booking-detail-drop-down-container:hover > .selected-recording-date-option {
  background-color: unset !important;
}

.booking-detail-drop-down-container:hover
  > .selected-recording-date-option:hover {
  background-color: var(--timbre-tan) !important;
}

.booking-detail-drop-down-container > p:hover {
  background-color: var(--timbre-tan) !important;
}

.selected-recording-date-option--valid {
  color: var(--text-primary-color);
}

.selected-recording-date-option--invalid {
  color: var(--notification-indicator-color);
}

.recording-detail-row {
  position: relative;
}

.recording-detail-tracking-engineer {
  position: relative;
}

.start-end-hour-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}

.start-end-hour-seperator {
  flex: 0;
  justify-content: center;
}

.recording-detail-tracking-engineer > p {
  text-transform: capitalize;
}

.complete-booking-button {
  margin-top: 30px;
}

.aside-session-details {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin-right: 20px;
}

.delete-session-confirmation-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.delete-session-modal-action-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.delete-session-modal-action-container > button:first-child {
  margin-right: 5px;
}

.delete-session-modal-action-container > button:not(:first-child) {
  margin-left: 5px;
}

@media only screen and (max-width: 768px) {
  .complete-booking-button {
    font-size: 14px;
  }
}

.add-session-checkout-text {
  margin-left: 6px;
}

.navbar-logo-left-container {
  z-index: 5;

  width: 1030px;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 15px 20px;

  background-color: transparent;
}

@media screen and (max-width: 767px) {
  .navbar-logo-left-container {
    max-width: 100%;
  }
}

.navbar-logo-left-container.shadow-three {
  position: static;
  left: 0px;
  top: 0%;
  right: 0px;
  bottom: auto;

  width: 100%;
  max-width: 1140px;
  margin-bottom: 0px;
  padding-top: 20px;
  padding-bottom: 20px;
  justify-content: flex-start;

  border-radius: 0px;

  background-color: hsla(0, 0.00%, 100.00%, 1.00);

  font-family: Roobert;
  text-align: center;
}

.navbar-logo-left-container.shadow-three {
  width: 100%;
  max-width: 1140px;
  margin-bottom: 0px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.navbar-logo-left {
  position: static;
  left: 0%;
  right: 0%;

  margin-right: auto;
  margin-left: auto;

  border-radius: 20px;

  background-color: hsla(0, 0.00%, 100.00%, 1.00);
}

.container-2 {
  display: block;
  overflow: hidden;
  width: auto;
  max-width: none;
  margin-right: 20px;
  margin-left: 20px;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
}

@media screen and (max-width: 991px) {
  .container-2 {
    max-width: 728px;
  }
}

@media screen and (max-width: 479px) {
  .container-2 {
    max-width: none;
  }
}

.navbar-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .navbar-brand {
    padding-left: 0px;
  }
}

@media screen and (max-width: 991px) {
  .nav-menu-wrapper {
    background-color: transparent;
  }
}

.nav-menu-two {
  display: flex;
  margin-bottom: 0px;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 991px) {
  .nav-menu-two {
    display: flex;
    margin-top: 10px;
    padding: 20px;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;

    border-radius: 50px;

    background-color: hsla(0, 0.00%, 100.00%, 1.00);

    box-shadow: 0 8px 50px 0 hsla(0, 0.00%, 0.00%, 0.05);
  }
}

@media screen and (max-width: 767px) {
  .nav-menu-two {
    padding-bottom: 30px;
    flex-direction: column;
    border-radius: 20px;
  }
}

@media screen and (max-width: 479px) {
  .nav-menu-two {
    flex-direction: column;
  }
}

.nav-link {
  margin-right: 5px;
  margin-left: 5px;
  padding: 5px 10px;

  color: #1a1b1f;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-decoration: none;
}

.nav-link:hover {
  color: rgba(26, 27, 31, 0.75);
}

.nav-link:focus-visible {
  border-radius: 4px;

  outline-color: hsla(214.6153846153846, 100.00%, 37.00%, 1.00);
  outline-offset: 0px;
  outline-style: solid;
  outline-width: 2px;

  color: hsla(214.6153846153846, 100.00%, 37.00%, 1.00);
}

@media screen and (max-width: 991px) {
  .nav-link {
    padding-right: 5px;
    padding-left: 5px;
  }
}

@media screen and (max-width: 767px) {
  .nav-link {
    display: inline-block;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.nav-dropdown {
  margin-right: 5px;
  margin-left: 5px;
}

@media screen and (max-width: 767px) {
  .nav-dropdown {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.nav-dropdown-toggle {
  padding: 5px 30px 5px 10px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
}

.nav-dropdown-toggle:hover {
  color: rgba(26, 27, 31, 0.75);
}

.nav-dropdown-toggle:focus-visible {
  border-radius: 5px;

  outline-color: hsla(214.6153846153846, 100.00%, 37.00%, 1.00);
  outline-offset: 0px;
  outline-style: solid;
  outline-width: 2px;

  color: hsla(214.6153846153846, 100.00%, 37.00%, 1.00);
}

@media screen and (max-width: 767px) {
  .nav-dropdown-toggle {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.nav-dropdown-icon {
  margin-right: 10px;
}

.nav-dropdown-list {
  border-radius: 12px;
  background-color: white;
}

.nav-dropdown-list:global(.w--open) {
  padding-top: 10px;
  padding-bottom: 10px;
}

@media screen and (max-width: 991px) {
  .nav-dropdown-list.shadow-three:global(.w--open) {
    position: absolute;
  }
}

@media screen and (max-width: 767px) {
  .nav-dropdown-list.shadow-three {
    box-shadow: 0 8px 50px 0 hsla(0, 0.00%, 0.00%, 0.05);
  }
}

@media screen and (max-width: 767px) {
  .nav-dropdown-list.shadow-three:global(.w--open) {
    position: relative;
  }
}

.nav-dropdown-list.shadow-three.mobile-shadow-hide {
  font-family: Roobert;
}

@media screen and (max-width: 767px) {
  .nav-dropdown-list.shadow-three.mobile-shadow-hide {
    box-shadow: none;
  }
}

@media screen and (max-width: 767px) {
  .nav-dropdown-list.shadow-three.mobile-shadow-hide {
    box-shadow: none;
  }
}

.nav-dropdown-link {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
}

.nav-dropdown-link:focus-visible {
  border-radius: 5px;

  outline-color: hsla(214.6153846153846, 100.00%, 37.00%, 1.00);
  outline-offset: 0px;
  outline-style: solid;
  outline-width: 2px;

  color: hsla(214.6153846153846, 100.00%, 37.00%, 1.00);
}

.nav-divider {
  width: 1px;
  height: 22px;
  margin-right: 15px;
  margin-left: 15px;
  background-color: hsla(212.00000000000006, 38.46%, 92.35%, 1.00);
}

@media screen and (max-width: 767px) {
  .nav-divider {
    width: 200px;
    height: 1px;
    max-width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.nav-link-accent {
  margin-right: 20px;
  margin-left: 5px;
  padding: 5px 10px;

  color: #1a1b1f;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  letter-spacing: 0.25px;
  text-decoration: none;
}

.nav-link-accent:hover {
  color: rgba(26, 27, 31, 0.75);
}

@media screen and (max-width: 767px) {
  .nav-link-accent {
    display: inline-block;
    margin-right: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 991px) {
  .menu-button {
    padding: 12px;
  }
}

@media screen and (max-width: 991px) {
  .menu-button:global(.w--open) {
    background-color: hsla(213.60000000000002, 16.34%, 70.00%, 1.00);
    color: hsla(0, 0.00%, 100.00%, 1.00);
  }
}
.percentage-slider-container {
  width: 100%;
  position: relative;
}

.percentage-slider {
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  width: 100%;
  height: 5px;
  background: var(--warm-light-grey);
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.percentage-slider:hover {
  opacity: 1;
}

.percentage-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: calc(25px / 2);
  background: var(--boomy-orange);
  cursor: pointer;
}

.percentage-slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: calc(25px / 2);
  background: var(--boomy-orange);
  cursor: pointer;
}

.percentage-label {
  position: absolute;
  right: 0;
}

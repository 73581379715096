.location-input:enabled:not([type="submit"]) {
  border: solid thin var(--light-grey);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 10px;
  width: 100%;
  background-color: var(--white);
  color: var(--black);
}

.location-input:focus {
  border: solid 2px var(--deep-blue) !important;
  outline: none;
}

/* Set the z-index for the dropdown of the autocomplete dropdown for address 
  Google provides this class for the dropdown of the autocomplete so we can align it with our tokenized z-indices
*/
.pac-container {
  z-index: var(--modal-index);
}

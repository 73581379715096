.progress-bar-label {
  color: var(--light-grey);
  margin-bottom: 0;
  margin-top: 0;
  padding-right: 5px;
}

.progress-bar-label.count-down-label {
  color: var(--boomy-orange);
}

.past-due-container {
  display: flex;
  gap: 0.5em;
  flex-direction: row;
  align-items: center;
  height: 20px;
}

.promo-code {
  display: flex;
}

.promo-code > .editable-text-field {
  text-transform: uppercase;
  margin-right: 20px;
}

.apply-promo-code-button > .v1-button {
  width: 80px;
  border-radius: 10px;
}

@media screen and (max-width: 768px) {
  .promo-code > .editable-text-field {
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  .promo-code > .editable-text-field {
    text-transform: uppercase;
    margin-right: 20px;
  }
}

.track-table-options-container {
  overflow: visible;
}

.track-table-options-container:hover {
  cursor: pointer;
}

.pop-over-relative-container {
  position: relative;
  overflow: visible;
}

.track-table-menu.card-container {
  position: absolute;
  bottom: 30px;
  right: 5px;
  z-index: 500;
  overflow: unset;
  flex-direction: column;
  border-radius: 5px;
  overflow: hidden;
  height: auto;
  transition:
    visibility 0.5s,
    opacity 0.5s linear;
}

.track-table-menu.card-container.hide {
  visibility: hidden;
  opacity: 0;
}

.track-table-menu.card-container.show {
  visibility: visible;
  opacity: 1;
}

.track-table-option {
  min-width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-bottom: solid thin var(--light-grey);
}

.track-table-option.artist-share-button {
  border: none;
  min-width: unset;
  display: flex;
  flex-direction: row;
}

.track-table-option.artist-share-button:hover {
  background-color: unset;
}

.track-table-option.artist-share-button > * {
  padding-left: calc(var(--project-screen-element-margin) / 2);
  padding-right: calc(var(--project-screen-element-margin) / 2);
  height: 40px;

  @media (max-width: 600px) {
    padding-left: calc(var(--project-screen-element-margin) / 3);
    padding-right: calc(var(--project-screen-element-margin) / 3);
  }
}

.track-table-option > p {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.track-table-option:last-child {
  border: none;
}

.track-table-option:hover {
  background-color: var(--light-grey);
}

.track-table-option > p {
  transition: all ease-in 0.3s;
}

.track-table-option > p:hover {
  transform: scale(0.9);
}

.track-table-option > * {
  padding-left: var(--project-screen-element-margin);
  padding-right: var(--project-screen-element-margin);
  height: 40px;
}

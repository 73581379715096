.action-item-table-element-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: calc(100% - 20px);
  height: 65px;
  padding: 10px;
  background-color: var(--white);
  border-top: 1px solid var(--warm-light-grey);
  border-bottom: 1px solid var(--warm-light-grey);
}

.action-item-row-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
}

.action-item-column-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.action-item-image-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: fit-content;
  color: var(--black);
}

.action-item-image {
  height: 21px;
  width: 21px;
  border-radius: 50%;
  border: 1px solid var(--warm-light-grey);
}

.action-item-image-container > .action-item-image:first-child {
  margin-right: 5px;
}

.action-item-element-button {
  max-height: 36px;
  font-size: 12px;
  display: flex;
  flex-direction: column;
}

.action-item-column-container > .action-item-image-container:first-child {
  margin-right: 5px;
}

@media screen and (min-width: 768px) {
  .action-item-table-element-container {
    flex-direction: row;
    justify-content: space-between;
  }

  .action-item-column-container {
    width: 100%;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    display: flex;
  }

  .action-item-image-container {
    flex-direction: row;
    align-items: center;
  }

  .action-item-row-container {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .action-item-checkbox {
    margin-right: 10px;
  }

  .action-item-element-button {
    max-height: 48px;
  }
}

.play-audio-container {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.play-audio-section {
  width: 100%;
  height: 100%;
  position: relative;
}

.play-audio-section-loader {
  pointer-events: none;
  background-color: inherit;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 500ms linear;
}

.play-audio-section-loader.loading {
  opacity: 1;
  pointer-events: auto;
}

.play-audio-top-section {
  width: 100%;
  height: 100%;
}

.play-audio-button {
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin-right: 12px;
}

.play-audio-button:hover {
  cursor: pointer;
}

.sound-wave-container {
  display: flex;
  width: 100%;
  height: 100%;
  align-self: center;
}

.play-audio-sound-wave {
  width: 100%;
  height: 16px;
  align-self: center;
}

.label-container {
  min-width: 40px;
  height: 50px;
  display: flex;
  margin-right: 10px;
  flex-direction: column;
  justify-content: center;
}

.label-container > p {
  text-align: left;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  display: flex;
  align-items: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
}

.disable-button {
  opacity: 0.5;
}

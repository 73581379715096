.filter-row {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.filter-row > label {
  margin-top: 50px;
  margin-bottom: 15px;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.advanced-filter-button {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  border: none;
  background-color: inherit;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.advanced-filter-button > p {
  color: var(--dark-grey);
}

.filter-row:first-child > label {
  margin-top: 30px;
}

.filter-row > .label2 {
  color: var(--dark-grey);
}

.filter-row-options {
  display: flex;
  flex-direction: row;
  max-width: 600px;
  flex-wrap: wrap;
  width: 100%;
}

.filter-button {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  border: solid thin var(--black);
  border-radius: 100px;
  padding: 10px 15px;
}

.filter-button.active {
  border: solid thin var(--mint-green);
  background-color: var(--mint-green);
}

.filter-button.active > p {
  color: black;
}

.filter-row-options > .filter-button {
  margin-right: 10px;
  margin-bottom: 15px;
}

.search-filters-modal-container {
  background-color: var(--white);
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.search-filters-scrollable-container {
  padding-bottom: 100px;
}
.search-filters-modal-container > .search-filters-scrollable-container {
  max-height: 500px;
  overflow-y: scroll;
}

.search-filter-container-header {
  display: flex;
  flex-direction: row;
  padding-bottom: 15px;
  align-items: baseline;
  justify-content: space-between;
  border-bottom: solid thin var(--warm-light-grey);
}

.filters-footer {
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  display: flex;
  position: absolute;
  flex-direction: row;
  align-items: center;
  width: calc(100% - 40px);
  background-color: var(--white);
  justify-content: space-between;
  border-top: solid thin var(--warm-light-grey);
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.15);
}

.search-filter-drawer {
  margin-bottom: 60px;
  background-color: var(--white) !important;
  z-index: var(--search-filter-drawer-z-index) !important;
}

.search-filter-drawer__backdrop {
  z-index: var(--search-filter-drawer-backdrop-z-index) !important;
}

.filter-row-advance-budget-container {
  display: flex;
  flex-direction: row;
}

.amenity-filter-container {
  max-width: unset;
  justify-content: flex-start;
  width: 100%;
}

.filter-row-advance-budget-number-field {
  padding: 12.5px 0px 12.5px 12px;
  border-radius: 8px;
  width: 246px;
  border: 0.75px solid var(--dark-grey);
  background: var(--white);
}

.filter-budget-text-field {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  flex-direction: column;
}

/* apply margin right to first child of filter-row-advacne-budget-container*/
.filter-row-advance-budget-container > .filter-budget-text-field:first-child {
  margin-right: 24px;
}

@media screen and (max-width: 768px) {
  .filter-row-advance-budget-number-field {
    width: 112px;
  }
}

.quickfilter-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  flex-direction: row;
  justify-content: center;
}

.quickfilter-button {
  margin-top: 10px;
  margin-right: 10px;
}

.quickfilter-button > .filter-button {
  background-color: var(--white);
  border: solid thin var(--black);
  justify-content: center;
  align-items: center;
}

.quickfilter-button > .filter-button > p {
  color: var(--black);
}

.quickfilter-button > .filter-button.active {
  background-color: var(--black);
}

.quickfilter-button > .filter-button.active > p {
  color: var(--white);
}

.advanced-settings-text {
  cursor: pointer;
}

.search-filter-close-icon {
  cursor: pointer;
}

.clear-all-button-text {
  cursor: pointer;
}

.add-subscription-flow-container {
  padding: 25px;
  border-radius: 13px;
  overflow: auto;
}

.subscription-step-header {
  max-width: 90vw;
  text-align: center;
}

.subscription-form {
  display: flex;
  justify-content: center;
  align-items: center;
}

.subscription-form > * {
  margin-right: 0.8em;
}

.subscription-form .textfield {
  width: 64px;
}

.subscription-step-view > * {
  margin-bottom: 1em;
}

.subscription-step-view .v1-button {
  width: 246px;
}

.subscription-step-view {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
}

.dollar-sign {
  margin-left: auto;
  margin-top: 25px;
  color: var(--dark-grey);
  font-size: 25px;
  font-weight: 500;
}

.more-button-container > .more-options-button{
  margin-top: 15px;
}

@media screen and (max-width: 568px) {
  .subscription-step-view {
    height: fit-content;
  }
  .subscription-step-view .v1-button {
    width: 100%;
  }
  .subscription-step-view > .more-options-button{
    margin-top: unset;
  }
}

.centered-continue-button {
  margin-left: auto;
  margin-right: auto;
}


.apply-discount-container {
  box-sizing: border-box;
  width: 100%;
  border-radius: var(--border-radius-md);
  background-color: var(--midground-color);
  padding: 16px;
  color: var(--text-primary-color);
  font-size: 16px;
}

.apply-discount-header {
  font-weight: 600;
  padding-bottom: 16px;
}

.apply-discount-discounts-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 0 8px;

  button {
    margin-top: 16px;
    flex-grow: 1;
  }

  input {
    border-radius: var(--border-radius-md);
    border: 1px solid var(--border-color);
    height: 42px;
    margin-top: 16px;
    flex: 2;
  }

  input:focus {
    outline: none;
    border: 1px solid var(--black);
  }
}

.apply-discount-custom-input {
  padding-left: 8px;
}
.banner {
  height: 214px;
  background-size: cover;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  display: flex;
  transition: height ease-in 500ms;
}

@media screen and (max-width: 1040px) {
  .banner {
    height: 138px;
  }
}

.over-lay-container {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: all ease-in 500ms;
}

.banner-edit-icon {
  margin-top: -2px;
  margin-left: 6px;
  filter: brightness(50%);
}

.circle-picker div span div {
  border-style: solid !important;
  border: 2px;
  border-color: var(--white);
}

.editmode:hover {
  cursor: pointer;
}

.account-info-modal.card-container {
  flex-direction: column;
  justify-content: space-evenly;
  padding: 30px;
  transition: height ease-in 0.2s 0.5s;
  position: relative;
  width: 540px;
}

@media screen and (max-width: 650px) {
  .account-info-modal.card-container {
    width: 80vw;
  }
}

.contact-info-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  margin-bottom: 15px;
}

.contact-info-form {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  gap: 10px;
}

.contact-info-text-fields {
  width: 100%;
}

.account-info-modal-input-container {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
}

.success-screen-overlay {
  background-color: var(--white);
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.success-screen-overlay,
.verify-number-overlay {
  position: absolute;
  width: inherit;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  transition: all linear 0.5s;
  top: 50px;
  left: 1em;
  right: 1em;
  bottom: 1em;
}

.success-screen-overlay {
  top: unset;
  justify-content: center;

}

.success-screen-overlay.hide,
.verify-number-overlay.hide {
  transform: translateX(200%);
}

.verify-number-overlay.show {
  animation: fadeIn 1s ease-in;
  transform: translateX(0%);
}

.success-screen-overlay.show {
  animation:
    slideInSuccessModal linear 500ms,
    fadeInSuccessModal ease-in 500ms;
  animation-fill-mode: forwards;
  animation-delay: 0ms, 500ms;
  transform: translateX(0%);
}

.success-lottie-container {
  animation: scaleInSuccessLottie linear 1s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
  text-align: center;
}

@keyframes scaleInSuccessLottie {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes fadeInSuccessModal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes slideInSuccessModal {
  0% {
    -webkit-transform: translateX(200%);
  }
  100% {
    -webkit-transform: translateX(0%);
  }
}

.account-info-modal-input-container.hide {
  transform: translateX(120%);
  animation: fadeOut 0.5s;
}

.account-info-modal-input-container > .v1-button {
  width: 100%;
  margin-top: 10px;
}

.contact-modal-input.verify-number-input {
  width: 95%;
  margin: 0;
}

.resend-verify-text-code-text {
  cursor: pointer;
}

.resend-verify-text-code-text:hover {
  transition: all linear 0.5s;
  transform: scale(1.1);
}

.resend-verify-text-code-text:active {
  transition: all linear 0.5s;
  filter: brightness(85%);
  transform: scale(1);
}
.resend-verify-text-code-text.show {
  transform: scale(1);
  cursor: inherit;
}

.resend-verify-text-code-text.show,
.account-info-modal-input-container.show {
  animation: fadeIn 0.5s;
  transform: translateX(0%);
}

.contact-modal-input.input-text.PhoneInput  {
  background: var(--white);
  padding: 10px;
  border: 0.75px solid var(--light-grey);
  border-radius: 8px;
  width: calc(100% - 20px);
}

.contact-modal-input.input-text.PhoneInput > .PhoneInputInput {
  border: none;
}

.contact-modal-input.input-text.PhoneInput > .PhoneInputInput:focus {
  outline: none;
}

.verify-number-text {
  animation: fadeOut 0.5s;
  display: none;
}

@keyframes fadeOutIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}


.pill-container {
  border-radius: 12.5px;
  min-width: 91px;
  height: 26px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: var(--black);
  margin: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

.pill-container > p {
  color: var(--white);
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
}
.engineears-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 120px;
  padding-top: 50px;
  padding-bottom: 50px;
  flex-direction: column;
  grid-column-gap: 54px;
  grid-row-gap: 54px;

  background-color: hsla(0, 0.00%, 0.00%, 1.00);

  font-family: Roobert;
  color: hsla(0, 0.00%, 100.00%, 1.00);
}

@media screen and (min-width: 1920px) {
  .engineears-footer {
    width: auto;
  }
}

@media screen and (min-width: 1440px) {
  .engineears-footer {
    width: auto;
  }
}

.btt-link {
  display: flex;
  max-height: 60px;
  min-height: 60px;
  min-width: 100%;
  justify-content: center;
  align-items: center;

  background-color: hsla(0, 0.00%, 94.90%, 1.00);

  color: hsla(0, 0.00%, 36.86%, 1.00);
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
}

.btt-link:hover {
  background-color: #ececec;
}

.waves-top-footer-2 {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  max-width: 1230px;
  padding-right: 24px;
  padding-left: 24px;
  justify-content: space-between;
  align-items: flex-start;
  grid-column-gap: 84px;
  grid-row-gap: 84px;

  color: hsla(0, 0.00%, 100.00%, 1.00);
}

@media screen and (max-width: 991px) {
  .waves-top-footer-2 {
    flex-direction: column;
    align-items: center;
    grid-row-gap: 64px;
  }
}

@media screen and (max-width: 767px) {
  .waves-top-footer-2 {
    display: flex;
    padding-right: 0px;
    padding-left: 0px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
}

@media screen and (max-width: 479px) {
  .waves-top-footer-2 {
    width: 97%;
  }
}

.waves-top-left-footer-2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  grid-column-gap: 84px;
  grid-row-gap: 84px;
  justify-content: center;
}

@media screen and (max-width: 991px) {
  .waves-top-left-footer-2 {
    width: 100%;
    max-width: 495px;
    align-items: center;
    text-align: center;
  }
}

@media screen and (max-width: 767px) {
  .waves-top-left-footer-2 {
    display: none;
    flex-direction: column;
    align-items: flex-start;
  }
}

@media screen and (max-width: 479px) {
  .waves-top-left-footer-2 {
    width: 95%;
  }
}

.waves-column-links-footer-2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-row-gap: 30px;
}

@media screen and (max-width: 767px) {
  .waves-column-links-footer-2 {
    align-items: flex-start;
  }
}

.footer-h3 {
  margin-top: 0px;
  margin-bottom: 0px;

  color: hsla(0, 0.00%, 100.00%, 1.00);
  font-size: 1.5rem;
  line-height: 130%;
  font-weight: 600;
}

.waves-links-wrap-footer-2 {
  display: flex;
  min-width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  grid-row-gap: 16px;
}

.waves-link-footer-2 {
  margin-left: 0px;

  transition: all 300ms ease-in;

  color: hsla(0, 0.00%, 100.00%, 1.00);
  font-size: 0.875rem;
  line-height: 18px;
  font-weight: 300;
  letter-spacing: 1.04px;
  text-decoration: none;
  text-transform: capitalize;
  white-space: nowrap;
}

.waves-link-footer-2:hover {
  text-decoration: underline;
}

.waves-link-footer-2.disabled {
  color: hsla(0, 0.00%, 29.60%, 1.00);
}

.waves-top-right-footer-2 {
  display: flex;
  width: 100%;
  max-width: 495px;
  min-width: 320px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
}

@media screen and (max-width: 767px) {
  .waves-top-right-footer-2 {
    flex-direction: column;
    align-items: center;
    grid-row-gap: 80px;
    text-align: center;
  }
}

.form-block-6 {
  display: flex;
  min-width: 100%;
  flex-direction: column;
  align-items: stretch;
}

.newsletter-signup {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
}

.waves---paragraph-big-8 {
  color: hsla(0, 0.00%, 100.00%, 1.00);
  font-size: 1.5rem;
  line-height: 28px;
  font-weight: 600;
}

@media screen and (max-width: 991px) {
  .waves---paragraph-big-8 {
    text-align: center;
  }
}

.flex-block-8 {
  flex-direction: row;
  justify-content: space-between;
  transition: color 200ms ease-in;
}

.flex-block-8:active {
  color: hsla(0, 0.00%, 0.00%, 1.00);
}

.flex-block-8:focus {
  color: hsla(0, 0.00%, 0.00%, 1.00);
}

.flex-block-8.newsletter-alt {
  display: none;
}

.text-field-8 {
  height: 50px;
  max-height: 50px;
  min-width: 100%;

  border-radius: 10px;

  font-family: Roobert;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.5px;
}

.text-field-8:focus {
  color: hsla(0, 0.00%, 0.00%, 1.00);
}

.text-field-8::placeholder {
  color: var(--dark-grey);
}

@media screen and (max-width: 479px) {
  .text-field-8 {
    width: 100%;
    min-width: 250px;
  }
}

.text-field-8.newsletter--alt::placeholder {
  color: var(--waves-library-black);
}

.submit-button-8 {
  height: 50px;
  max-height: 50px;
  margin-left: -150px;
  padding: 10px;

  background-color: hsla(0, 0.00%, 0.00%, 0.00);
  background-image: url("https://uploads-ssl.webflow.com/6356e5735110b93b46145859/65a59d887795e43a88220b64_Arrow%20Button_Filled.svg");
  background-position: 50% 50%;
  background-size: auto;
  background-repeat: no-repeat;

  color: hsla(0, 0.00%, 60.39%, 1.00);
  font-size: 32px;
  line-height: 10px;
}

.submit-button-8:focus {
  background-image: url("https://uploads-ssl.webflow.com/6356e5735110b93b46145859/65a5a3f676bdbd487fd89cff_Arrow%20Button_Filled-1.svg");
  color: hsla(0, 0.00%, 0.00%, 1.00);
}

.submit-button-8.newsletter-alt {
  display: none;
  background-image: url("https://uploads-ssl.webflow.com/6356e5735110b93b46145859/65a5a3f676bdbd487fd89cff_Arrow%20Button_Filled-1.svg");
}

.success-message {
  border-radius: 10px;
  background-color: var(--boomy-orange);
}

.error-message {
  border-radius: 10px;
  color: hsla(0, 100.00%, 61.33%, 1.00);
}

.flex-block-9 {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
}

@media screen and (max-width: 991px) {
  .flex-block-9 {
    flex-direction: column;
    justify-content: center;
  }
}

@media screen and (max-width: 767px) {
  .flex-block-9 {
    display: none;
  }
}

.flex-block-9.alt {
  display: none;
}

@media screen and (max-width: 767px) {
  .flex-block-9.alt {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.waves---paragraph-small-3 {
  font-size: 1.5rem;
  line-height: 28px;
  font-weight: 600;
}

@media screen and (max-width: 991px) {
  .waves---paragraph-small-3 {
    font-size: 1.75rem;
  }
}

.waves-bottom-right-footer-2 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-column-gap: 8px;
}

.waves-icon-24 {
  width: 24px;
  height: 24px;
}

.waves-expandable-master-faq-3 {
  display: none;
  max-width: 545px;
}

@media screen and (max-width: 767px) {
  .waves-expandable-master-faq-3 {
    display: block;
    width: 100%;
  }
}

@media screen and (max-width: 479px) {
  .waves-expandable-master-faq-3 {
    width: 99%;
    max-width: 100%;
  }
}

.waves---expandable-wrap-3 {
  display: flex;
  width: 100%;
  flex-direction: column;
}

@media screen and (max-width: 479px) {
  .waves---expandable-wrap-3 {
    width: 99%;
  }
}

.waves---expandable-item-3 {
  display: flex;
  height: auto;
  padding-top: 32px;
  padding-bottom: 32px;
  justify-content: space-between;
  align-items: flex-start;

  border-top: 1px solid var(--wavesLibraryBlack-d656d06e);

  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .waves---expandable-item-3 {
    width: 95%;
    margin-right: auto;
    margin-left: auto;
    padding-top: 20px;
    padding-bottom: 20px;
    justify-content: center;
    align-items: flex-start;
    grid-column-gap: 30px;
    grid-row-gap: 30px;

    border-top: 1.5px solid hsla(0, 0.00%, 36.86%, 1.00);
  }
}

@media screen and (max-width: 479px) {
  .waves---expandable-item-3 {
    width: 98%;
    margin-right: auto;
    margin-left: auto;
    padding-top: 20px;
  }
}

.waves---expandable-content-3 {
  width: 100%;
  height: auto;
  margin-right: 24px;
}

@media screen and (max-width: 479px) {
  .waves---expandable-content-3 {
    max-width: none;
    flex: 0 auto;
  }
}

.waves---expandable-open-3 {
  transform-origin: 50% 0%;
}

.waves---expand-space-3 {
  height: 24px;
}

.waves---expand-icon-wrap-3 {
  display: flex;
  width: 16px;
  height: 16px;
  margin-top: 2px;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .waves---expand-icon-wrap-3 {
    width: 28px;
    height: 28px;
    background-image: url("https://uploads-ssl.webflow.com/6356e5735110b93b46145859/65a5b847064407ea20c9e51d_angle-down%20f107%20regular.svg");
    background-position: 50% 50%;
    background-size: auto;
  }
}

@media screen and (max-width: 479px) {
  .waves---expand-icon-wrap-3 {
    flex: 0 0 auto;
  }
}

.waves-bottom-footer-2 {
  display: flex;
  width: 100%;
  max-width: 1230px;
  min-height: 35px;
  box-sizing: border-box;
  padding-right: 24px;
  padding-left: 24px;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 991px) {
  .waves-bottom-footer-2 {
    flex-direction: column;
    justify-content: flex-start;
    grid-row-gap: 32px;
    text-align: center;
  }
}

@media screen and (max-width: 767px) {
  .waves-bottom-footer-2 {
    padding-right: 0px;
    padding-left: 0px;
  }
}

@media screen and (max-width: 479px) {
  .waves-bottom-footer-2 {
    width: 97%;
  }
}

.flex-block-10 {
  display: flex;
  width: 100%;
  height: 35px;
  max-height: 35px;
  max-width: 495px;
  flex-direction: row;
  align-items: center;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
}

@media screen and (max-width: 991px) {
  .flex-block-10 {
    height: 100%;
    max-height: 150px;
    flex-direction: column;
    justify-content: center;
  }
}

@media screen and (max-width: 767px) {
  .flex-block-10 {
    width: 100%;
    min-width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.link-block-19 {
  margin-right: 0px;
  margin-left: 0px;
}

.image-75 {
  max-height: 100%;
  max-width: none;
}

.flex-block-11 {
  min-height: 100%;
  grid-column-gap: 2px;
  grid-row-gap: 2px;
  font-size: 12px;
  line-height: 16px;
}

.text-block-34 {
  font-size: 0.75rem;
  font-weight: 600;
}

.link-7 {
  color: hsla(0, 0.00%, 100.00%, 1.00);
  text-decoration: none;
}

.link-7:hover {
  text-decoration: underline;
}

@media screen and (max-width: 479px) {
  .link-7 {
    color: hsla(0, 0.00%, 100.00%, 1.00);
    text-decoration: none;
  }
}

@media screen and (max-width: 479px) {
  .link-7:hover {
    text-decoration: underline;
  }
}

.flex-block-12 {
  display: flex;
  min-width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
}

@media screen and (max-width: 991px) {
  .flex-block-12 {
    justify-content: center;
  }
}

.link-6 {
  margin-right: 0px;
  margin-left: 0px;

  color: hsla(0, 0.00%, 78.82%, 1.00);
  font-size: 0.75rem;
  font-weight: 300;
  text-decoration: none;
}

.link-6:hover {
  text-decoration: underline;
}

.text-block-33 {
  color: hsla(0, 0.00%, 78.82%, 1.00);
}
@keyframes slideUp {
  0% {
    bottom: -100%;
  }
  100% {
    bottom: 0;
  }
}

@keyframes slidingDown {
  0% {
    bottom: 0;
  }
  100% {
    bottom: -100%;
  }
}

.entity-photos-screen {
  position: fixed;
  bottom: -100%;
  left: 0;
  width: 100%;
  opacity: 0;
  background-color: var(--white);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  z-index: var(--entity-photo-screen-z-index) !important;
  transition:
    opacity 0.5s ease-out,
    bottom 0.5s ease-out;
}

.entity-photos-screen.sliding-up {
  opacity: 1;
  animation: slideUp 0.5s ease-out forwards;
}

.entity-photos-screen.sliding-down {
  opacity: 0;
  animation: slidingDown 0.5s forwards ease-out;
}

.entity-photos-screen__body {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: scroll;
  max-height: 100%;
  position: relative;
}

.entity-photos-screen__header__item {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.entity-photos-screen__header__item > p {
  margin-right: 4px;
}

.detail-image-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 8px;
}

.detail-image-row__item {
  flex: 1;
  margin: 4px;
  display: flex;
  position: relative;
}

.entity-photo-room-name {
  position: absolute;
  left: 12px;
  bottom: 12px;
}

.entity-photo-modification-buttons {
  position: absolute;
  right: 12px;
  bottom: 12px;
  display: flex;
  flex-direction: row;
  min-width: 25px;
  justify-content: center;
  gap: 10px;
  z-index: var(--entity-photo-screen-buttons-z-index);
}

.entity-photo-button {
  background-color: var(--white);
  border-radius: var(--border-radius-sm);
  padding: 10px;
  cursor: pointer;
  border: solid thin white;
}

.entity-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.entity-image-full-screen {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

@media screen and (max-width: 768px) {
  .entity-photo-room-name {
    position: absolute;
    left: 6px;
    bottom: 6px;
  }
  .entity-delete-photo-button {
    position: absolute;
    right: 6px;
    bottom: 6px;
  }

  .entity-photos-screen__header {
    justify-content: space-between;
  }

}

:root {
  --white-0: #ffffff;
  --black-0: #000000;

  --gray-25: #fcfcfc;
  --gray-50: #f2f2f2;
  --gray-100: #ececec;
  --gray-200: #d2d2d2;
  --gray-300: #a9a9a9;
  --gray-400: #7c7c7d;
  --gray-500: #5e5e5e;
  --gray-600: #484848;
  --gray-700: #393939;
  --gray-800: #313131;
  --gray-900: #2c2c2c;
  --gray-950: #1e1e1e;
  --gray-1000: #111111;

  --boomy-orange-50: #fff4f1;
  --boomy-orange-200: #ffd4c7;
  --boomy-orange-400: #ff8966;
  --boomy-orange-500: #f8663b;
  --boomy-orange-600: #e54b1d;
  --boomy-orange-700: #c13c14;
  --boomy-orange-800: #9e2e0d;
  --boomy-orange-950: #481607;

  --boxy-yellow-100: #fbedca;

  --red-300: #FF9595;
  --red-400: #ff5959;
  --red-500: #ff2626;
  --red-600: #fc0606;
  --red-700: #d40000;

  --mint-green-500: #e0efd1;
  --mint-green-600: #588230;

  --deep-blue-50: #f1f9fe;
  --deep-blue-200: #bee3f9;
  --deep-blue-300: #6ec3f2;
  --deep-blue-800: #0F537D;
  --deep-blue-950: #0C2C45;

  --green-300: #90e5a3;
  --green-600: #28a745;
  --deep-green: #c4d0a1;
  --deep-green-500: #80954D;
  --green-700: #1f7634;
}

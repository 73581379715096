.cashout-container {
  background: var(--white);
  width: calc(100% - 60px);
  height: calc(100% - 30px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 30px 30px 30px;
  box-shadow: 0px 0px 6px 0px rgba(109, 109, 109, 0.18);
}

.cashout-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 61px;
  align-items: center;
}

.cashout-title {
  color: #999999;
  font-size: 12px;
  text-transform: uppercase;
}

.icon-chevron-right {
  color: #999999;
  font-size: 12px;
}

.cashout-content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 4px;
  width: 100%;
}

.cashout-content-left {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.cashout-content-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.cashout-content-right > p {
  color: var(--black);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  text-decoration-line: underline;
  align-self: center;
  margin-top: 10px;
}

.cashout-amount {
  font-size: 24px;
  margin: 0;
}

.cashout-available {
  color: #999999;
  font-size: 12px;
}

.cashout-transactions {
  color: #000000;
  text-decoration: underline;
  font-size: 12px;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.cashout-actions {
  display: flex;
  flex-direction: column;
}

.cashout-total-balance {
  color: var(--black);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.48px;
  margin-bottom: 5px;
}

.cashout-available-to-pay-out {
  color: var(--medium-grey);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 5px;
}

.cashout-transaction-history {
  color: var(--black);
  background: var(--white);
  border: none;
  padding: 0;
  cursor: pointer;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  text-decoration-line: underline;
  cursor: pointer;
}

.withdraw-funds-button {
  font-size: 16px;
  font-family: "Roobert";
  font-weight: 600;
  line-height: 20px;
  word-wrap: break-word;
  width: 100%;
  color: var(--white-no-dark-theme);
}

@media screen and (max-width: 600px) {
  .cashout-container {
    padding: 15px 15px 30px 15px;
    height: calc(100% - 45px);
    width: calc(100% - 30px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .cashout-header {
    flex-direction: column;
    height: auto;
    align-items: flex-start;
  }

  .cashout-content {
    flex-direction: column;
    width: 100%;
  }

  .cashout-content-left {
    align-items: flex-start;
    justify-content: flex-start;
  }

  .cashout-content-right {
    width: 100%;
  }
  .cashout-button-group-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
  }
}

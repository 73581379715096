.payment-plan-checkbox {
  width: 289px;
  height: 311px;
  left: 505px;
  top: 808px;

  background: var(--smooth-ivory);
  border-radius: 15px;
}

.payment-plan-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  border: black;
}

.custom-checkmark {
  position: relative;
  top: 6px;
  visibility: hidden;
}

.checked {
  visibility: visible;
}

.payment-plan-checkbox .benefit-unordered-list {
  padding-bottom: unset;
  border-bottom: unset;
}

.secondary-payment-plan {
  background: var(--white);
  border: 1px solid #c9c9c9;
  box-sizing: border-box;
  border-radius: 15px;
}

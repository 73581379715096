@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.component-wrapper {
  position: relative;
}

.tool-tip-bubble.card-container {
  position: absolute;
  flex-direction: column;
  height: unset;
  padding: var(--project-screen-element-margin);
  animation-delay: 2s;
  animation:
    fadeIn 500ms ease-in forwards,
    bounce linear 2s;
  min-width: min(300px, 45vw);
  max-width: min(340px, 40vw);
  z-index: var(--tooltip-text-z-index);
  box-sizing: border-box;
}

.tool-tip-bubble.card-container > .header-container > .exit-icon {
  top: 0;
  right: 0;
}

.tool-tip-bubble.card-container > .header-container >.header-font {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--black);
  letter-spacing: -0.02em;
}

.tool-tip-bubble.card-container > .body-font {
  color: var(--black);
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 5px;
}

.studios---header-wrapper {
  display: flex;
  max-width: 1230px;
  flex-direction: column;
  align-items: flex-start;
  grid-column-gap: 15px;
  grid-row-gap: 15px;
}

.breadcrumb---wrapper {
  display: flex;
  height: 24px;
  align-items: center;
}

@media screen and (max-width: 991px) {
  .breadcrumb---wrapper {
    display: none;
  }
}

.breadcrumb {
  margin-right: 4px;

  font-family: Roobert;
  color: var(--darkGrey-96b86b19);
  font-size: 12px;
  line-height: 16px;
  font-weight: 300;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.brix---text-200 {
  font-family: Roobert;
  color: var(--boomyOrange-7c3cf0b7);
  font-size: 18px;
  line-height: 20px;
}

@media screen and (max-width: 767px) {
  .brix---text-200 {
    font-size: 16px;
    line-height: 18px;
  }
}

@media screen and (max-width: 479px) {
  .brix---text-200 {
    font-size: 15px;
    line-height: 16px;
  }
}

.div-block-60 {
  display: flex;
  grid-column-gap: 15px;
  grid-row-gap: 15px;
}

.studios {
  overflow: hidden;
  margin-top: 0px;
  margin-bottom: 0px;

  font-family: Roobert;
  font-size: 56px;
  line-height: 58px;
  font-weight: 600;
  white-space: nowrap;
}

@media screen and (max-width: 767px) {
  .studios {
    font-size: 30px;
    line-height: 34px;
  }
}

.studios.studio-name {
  font-weight: 400;
}

.sub-heading---details {
  align-items: center;
  grid-column-gap: 15px;
  grid-row-gap: 15px;
}

@media screen and (max-width: 767px) {
  .sub-heading---details {
    flex-direction: column;
    align-items: flex-start;
    grid-column-gap: 5px;
    grid-row-gap: 5px;
  }
}

.reviews---wrapper {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
}

.star-rating---wrapper {
  display: flex;
  align-items: center;
  grid-column-gap: 2px;
  grid-row-gap: 2px;
}

.bold-text {
  font-size: 16px;
  font-weight: 600;
}

@media screen and (max-width: 767px) {
  .bold-text {
    font-size: 12px;
    line-height: 14px;
  }
}

.faq-question-text {
  color: hsla(0, 0.00%, 20.00%, 1.00);
  font-weight: 400;
}

@media screen and (max-width: 767px) {
  .faq-question-text {
    font-size: 20px;
    line-height: 24px;
  }
}

@media screen and (max-width: 767px) {
  .text-block-24 {
    font-size: 12px;
    line-height: 14px;
  }
}

.link-4 {
  color: var(--offBlack-0d13a4ec);
  font-size: 16px;
  font-weight: 600;
}

@media screen and (max-width: 767px) {
  .link-4 {
    font-size: 12px;
    line-height: 14px;
  }
}

.studios---location-wrapper {
  display: flex;
  grid-column-gap: 3px;
  grid-row-gap: 3px;

  font-family: Roobert;
  color: var(--darkGrey-96b86b19);
  font-size: 16px;
  font-weight: 300;
}

.labels-w-icon {
  display: flex;
  align-items: center;
  grid-column-gap: 5px;
  grid-row-gap: 5px;
}

@media screen and (max-width: 767px) {
  .labels-w-icon {
    display: none;
  }
}

.icons {
  display: flex;
  width: 24px;
  height: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.atmos-enabled {
  font-size: 16px;
  font-weight: 600;
}

.div-block-58 {
  display: flex;
  max-width: 100%;
  justify-content: space-between;
  align-self: stretch;
}

@media screen and (max-width: 767px) {
  .div-block-58 {
    display: none;
  }
}

.div-block-62 {
  display: flex;
  align-items: center;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}

.studios---basic-label {
  font-size: 16px;
  font-weight: 400;
}

.div-block-61 {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}

.alt-text-labels {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1px;
}
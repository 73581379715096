.team-invite-container {
  display: flex;
  padding: 200px 40px;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.team-invite-button-container {
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .team-invite-button-container {
    flex-direction: row;
  }
}

.team-invite-button-container > button {
  margin: 10px;
}

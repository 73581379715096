.check-box-container {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.check-box-container--disabled {
  cursor: not-allowed;
}

/* Hide the browser's default checkbox */
.check-box-container input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.check-box-checkmark {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: var(--light-grey);
  margin-top: 3px;
}

.sign-up {
  transform: scale(0.7) translateY(-7px);
}

/* On mouse-over, add a grey background color */
.check-box-container:hover input ~ .check-box-checkmark {
  background-color: var(--light-grey);
}

.check-box-container input:checked ~ .check-box-checkmark {
  background-color: var(--boomy-orange);
}

.check-box-checkmark--disabled {
  opacity: 0.6;
}

/* Create the checkmark/indicator (hidden when not checked) */
.check-box-checkmark:after {
  content: "";
}

/* Show the checkmark when checked */
.check-box-container input:checked ~ .check-box-checkmark:after {
  display: flex;
  justify-content: center;
  align-self: center;
  width: 25px;
  height: 25px;
}

/* Style the checkmark/indicator */
.check-box-container .check-box-checkmark:after {
  background-image: url("../../assets/checkmark.svg");
  background-size: 15px 15px;
  height: 15px;
  width: 15px;
  background-repeat: no-repeat;
  background-position: center;
}

.checkbox-text-color {
  color: var(--black);
  flex: 1;
}

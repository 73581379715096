.add-alts-form {
  display: grid;
  width: 100%;
}

.alts-header {
  margin-left: 0;
  grid-row: 1;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
}

.alts-sub-header {
  display: flex;
  justify-content: space-between;
  grid-row: 2;
}

.alts-checkbox-container {
  display: grid;
  grid-template:
    "a b" auto
    "c d" auto;
  grid-row: 3;
}

.alt-checkbox > input {
  margin: revert;
}

.alt-checkbox > label {
  margin-left: 10px;
  cursor: pointer;
}

.generate-booking-dropdown_dropdown-container {
  flex: 1;
  border: 1px solid var(--border-color);
  background-color: var(--white);
}

.generate-booking-dropdown_dropdown-element-container {
  height: 44px !important;
}

.generate-booking-dropdown_dropdown-element-select-container {
  border: none;
  text-align: left;
}

.generate-booking-dropdown_dropdown-element-label-text {
  margin-left: 16px !important;
  font-size: var(--text-size-md) !important;
  width: 100%;
}

/* style for when the entire Booking Session Detail is selected */
.generate-booking-dropdown_dropdown-element-label-text--selected {
  margin-left: 16px !important;
  font-size: var(--text-size-md) !important;
  color: var(--nav-button-color--selected) !important;
  width: 100%;
}

/* style for when an individual Dropdown Selector has been selected */
.generate-booking-dropdown_dropdown-element-label-text--completed {
  margin-left: 16px !important;
  font-size: var(--text-size-md) !important;
  color: var(--text-primary-color) !important;
  width: 100%;
}

@media (max-width: 768px) {
  .generate-booking-dropdown_dropdown-element-label-text {
    font-size: var(--text-size-md) !important;
    white-space: unset !important;
  }

  .generate-booking-dropdown_dropdown-element-label-text--selected {
    font-size: var(--text-size-md) !important;
    white-space: unset !important;
  }
}

.generate-booking-dropdown_dropdown-element-arrow-container {
  cursor: pointer;
  border-left: none;
  position: unset;
  margin-left: auto;
}

.generate-booking-dropdown_dropdown-element-option {
  padding: 0 10px;
  text-align: left;
}

/* custom rounded borders */
.generate-booking-dropdown_round-border-top-left {
  border-top-left-radius: var(--border-radius-md);
}

.generate-booking-dropdown_round-border-top-right {
  border-top-right-radius: var(--border-radius-md);
}

.generate-booking-dropdown_round-border-bottom-left {
  border-bottom-left-radius: var(--border-radius-md);
}

.generate-booking-dropdown_round-border-bottom-right {
  border-bottom-right-radius: var(--border-radius-md);
}

/* custom no borders */
.generate-booking-dropdown_no-border-left {
  border-left: none;
}

.generate-booking-dropdown_no-border-right {
  border-right: none;
}

.generate-booking-dropdown_no-border-top {
  border-top: none;
}

.generate-booking-dropdown_no-border-bottom {
  border-bottom: none;
}

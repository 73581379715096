.studio-row {
  display: flex;
  flex-direction: row;
}

.studio-row.studio-rooms {
  row-gap: 30px;
  column-gap: 15px;
  justify-content: flex-start;
  flex-wrap: wrap;
}

@media screen and (max-width: 768px) {
  .studio-row.studio-rooms {
    justify-content: center;
    align-items: center;
  }
}


.studio-row .image-carousel-no-photos-container, .studio-row .primary-entity-photo {
  max-width: unset;
}

.studio-row.studio-rooms > .card-container.studio-room {
  margin-bottom: 30px;
}

.studio-room-add-icon {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 20px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: all 0.2s ease-in-out;
  z-index: 1000;
}

.studio-card-badge-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 15px;
  right: 15px;
}

.studio-card-review-container {
  position: absolute;
  top: 15px;
  left: 15px;
  background-color: white;
  border-radius: 4px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 2px 0;
  min-width: 75px;
}

.studio-card-link-wrapper {
  position: relative;
  text-decoration: unset;
  max-width: min(260px, 100vw);
}

.disable-studio-link {
  pointer-events: none;
  cursor: default;
}

.studio-card-link-wrapper > .studio-card-badge-container > .badge--medium {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 23px;
  height: 23px;
  border: 2px solid var(--white-no-dark-theme);
  border-radius: 50%;
  background-color: var(--white-no-dark-theme);
  position: relative;
}

.studio-card-link-wrapper
  > .studio-card-badge-container
  > .favorite-heart-container {
  margin-left: 5px;
}

.studio-card-link-wrapper > .studio-card-badge-container > .badge-component {
  margin-left: 5px;
}
.studio-card-link-wrapper
  > .studio-card-badge-container
  > .badge--medium
  > .badge--img {
  transform: none;
  top: unset;
  position: relative;
  width: 23px;
  height: 23px;
}

.studio-room-add-icon:hover {
  transform: scale(0.75);
}

.studio-room {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  cursor: pointer;
}

.studio-room-location-text {
  font-style: normal;
  color: var(--medium-grey);
  font-weight: normal;
}

.studio-room .studio-room-location-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.studio-room-share-icon {
  cursor: pointer;
}

.studio-room-calendar-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.studio-room-card-title {
  margin-top: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.studio-room-card-name {
  margin-right: 10px;
}

.studio-room-card-pricing {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  column-gap: 10px;
  margin-top: 10px;
}

.studio-room-card-minimum-time {
  color: var(--medium-grey);
}

.studio-screen-reviews-score-container {
  margin-right: 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
  color: var(--text-primary-color);
}

.studio-screen-reviews-score {
  color: var(--black);
  white-space: unset;
  margin-left: 2px;
}

.studio-screen-reviews-score-container__vertical-separator {
  width: 1px;
  align-self: stretch;
  background-color: var(--black);
  margin: 2px 5px;
}

@media screen and (min-width: 768px) {
  .studio-screen-reviews-score-container__vertical-separator {
    margin: 4px 5px;
    width: 2px;
  }
}

.studio-reviews-count {
  text-decoration: underline;
  color: var(--black);
  white-space: unset;
}

@media screen and (max-width: 768px) {
  .studio-screen-reviews-score-container {
    align-items: end;
  }
}

.studio-room-add-room-card {
  cursor: pointer;
}

.underlined-room-text {
  text-decoration: underline;
}

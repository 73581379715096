.session-event-bubble-container {
  position: absolute;
  height: inherit;
  display: flex;
  z-index: 100;
}

.render-as-block {
  display: block;
  position: relative;
}

.session-event-bubble {
  background-color: var(--deep-green);
  color: var(--black-0);
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
  padding-top: 5px;
  padding-bottom: 5px;
  height: 40px;
  width: 100%;
  cursor: pointer;
}

.session-event-bubble__tablet {
  height: 3.6875rem;
  margin-top: 4.6875rem;
}

.session-event-bubble.pending-acceptance {
  background-color: var(--boomy-orange-500);
}

.session-event-bubble.pending-acceptance .session_event_title,
.session-event-bubble.pending-acceptance .session_event_title__small {
  color: var(--white-0);
}

.render-as-block > .session-event-bubble {
  min-height: 3.625rem;
}

.bookable-bubble-container {
  position: absolute;
  display: flex;
  z-index: 100;
  height: 4.0625rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bookable-bubble-container__tablet {
  margin-top: 3.125rem;
}

.hide-bookable-bubble-container {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.hide-bookable-bubble-container:hover {
  opacity: 1;
}

.bookable-bubble {
  margin: 0;
  height: 3.125rem;
  width: 100%;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: visible;
  position: relative;
  border-radius: 10px;
  cursor: pointer;
}

.bookable-bubble__tablet {
  height: 69px;
}

.bookable-bubble-backdrop {
  background-color: var(--white);
  position: absolute;
  top: 1px;
  left: 1px;
  right: 1px;
  bottom: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: var(--text-primary-color) 1px solid;
}

.session_event_title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  color: var(--black-0);
  font-weight: var(--text-weight-semibold);
}

.session_event_title__small {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  font-size: 10px;
  text-align: center;
  color: var(--black-0);
  font-weight: var(--text-weight-semibold);
}

.session-users-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 16px;
  position: relative;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2px;
}

.session-users-row.short_event {
  width: 100%;
  justify-content: center;
}

.session-user-image {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: solid white 1px;
  position: absolute;
}

.artist-name-session-bubble {
  margin-left: 1.875rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: black !important;
}

.search-result-overlay {
  margin-bottom: 20px;
  position: relative;
}

.disable-search-icon {
    pointer-events: none;
    cursor: not-allowed;
}

.search-result-overlay-result-drop-down {
  position: absolute;
  width: 100%;
  max-height: 500px;
  height: auto;
  overflow: hidden;
  left: 0;
  background-color: var(--smooth-ivory);
  z-index: 100;
  transition: max-height 0.2s ease-in-out;
}

.search-result-overlay-result-drop-down.active {
  max-height: 100%;
  transition: max-height 0.3s ease-in-out;
}

.ee-company-name-search-input {
  width: 200px;
  font-weight: bold;
}

.search-overlay-search-bar {
  height: 54px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: var(--warm-light-grey);
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.overlay-search-input {
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  color: var(--black);
  background-color: transparent;
  width: 100%;
  height: 100%;
  padding: 0 0 0 16px;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.overlay-search-input::placeholder {
  font-weight: 400;
}
.search-result-overlay-filter-button {
  background-color: var(--white);
  color: var(--black);
  width: 100px;
  height: 44px;
  min-width: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin-right: 5px;
  cursor: pointer;
}

.search-result-overlay-button {
  background-color: var(--black);
  width: 44px;
  height: 44px;
  min-width: 44px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 5px;
}
/* hides native x icon from search input */
.overlay-search-input::-ms-clear {
  display: none;
}

.overlay-search-input::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
}

.auto-complete-selected-filter-row {
  display: flex;
  flex-direction: row;
}

.auto-complete-selected-filter-row > .autocomplete-filter {
  margin-right: 10px;
}

.autocomplete-filter {
  border: solid thin var(--mint-green);
  background-color: var(--mint-green);
  padding-left: 10px;
  padding-right: 10px;
  height: 50%;
  border-radius: 15px;
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
}

.autocomplete-filter > p {
  color: var(--black-no-dark-theme);
  text-align: center;
  white-space: nowrap;
  padding: 5px;
  border-radius: 15px;
}

.autocomplete-filter > p > span {
  font-style: italic;
  font-weight: bold;
}

.auto-complete-selected-filter-row {
  display: flex;
  flex-direction: row;
  position: absolute;
  right: 0;
}

@media screen and (max-width: 768px) {
  .autocomplete-filter {
    display: none;
  }
  .search-overlay-search-bar {
    width: calc(100% - 30px);
  }
  .ee-company-name-search-input {
    display: none;
  }
}

.search-results-overlay-filter-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search-results-search-view-container {
  margin-top: 15px;
  margin-right: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.search-results-view {
  color: var(--black);
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
  cursor: pointer;
  > img {
    margin-right: 2px;
  }
}

.active-search-results-view {
  font-weight: 600;
  cursor: not-allowed;
}
@import "~normalize.css";
@import "../node_modules/bootstrap/dist/css/bootstrap-grid.min.css";
@import "typography.css";
@import "./styles/colors.css";

@font-face {
  font-family: "Roobert Woff 2";
  src: url("fonts/Roobert-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Roobert Woff 2";
  src: url("fonts/Roobert-SemiBold.woff2") format("woff2");
  font-weight: bold;
}

@font-face {
  font-family: "Roobert";
  src: url("fonts/Roobert-Regular.woff") format("woff");
}

@font-face {
  font-family: "Roobert";
  src: url("fonts/Roobert-SemiBold.woff") format("woff");
  font-weight: bold;
}

:root {
  /* tokenized colors to keep styles consistent between light and dark mode */
  --background-color: var(--gray-25);
  --modal-backdrop-color: rgba(109, 109, 109, 0.75);
  --midground-color: var(--gray-50);
  --foreground-color: var(--white-0);

  --primary-color: var(--boomy-orange-400);

  --border-radius-xs: 4px;
  --border-radius-sm: 6px;
  --border-radius-md: 8px;
  --border-radius-lg: 16px;
  --border-color: var(--gray-200);

  /* tokenized text attributes */
  --text-primary-color: var(--gray-950);
  --text-secondary-color: var(--gray-500);
  --text-tertiary-color: var(--gray-300);
  --text-invert-color: var(--white-0);
  --text-error-color: var(--red-700);

  --text-size-xxs: 0.625rem;
  --text-size-xs: 0.75rem;
  --text-size-sm: 0.875rem;
  --text-size-md: 1rem;
  --text-size-lg: 1.5rem;
  --text-size-xlg: 2rem;
  --text-size-xxlg: 2.25rem;

  --text-weight-semibold: 600;
  --text-weight-bold: bold;

  --text-line-height-short: 0.875rem;
  --text-line-height-long: 1.25rem;

  /* tokenized button attributes */
  --button-primary-color: var(--primary-color);
  --button-primary-color--hover: var(--boomy-orange-500);
  --button-secondary-color: var(--background-color);
  --button-secondary-color--hover: var(--gray-50);
  --button-outlined-color: var(--background-color);
  --button-outlined-color--hover: var(--gray-100);
  --button-tertiary-color: var(--gray-50);
  --button-disabled-color: var(--gray-300);

  --playback-button-primary-color: var(--gray-950);
  --playback-button-secondary-color: var(--gray-500);

  --button-border-radius: var(--border-radius-md);
  --button-padding: 12px 16px;
  --button-transition-duration: 150ms;

  --horizontal-line-color: var(--gray-100);
  --checkbox-color--disabled: var(--gray-200);

  --order-breakdown-background-color: var(--deep-blue-50);
  --order-breakdown-footer-color: var(--deep-blue-200);
  --order-breakdown-border-color: var(--deep-blue-50);

  /* tokenized box shadow attributes */
  --box-shadow-color: #6d6d6d2e;
  --box-shadow-color-2: var(--gray-200);

  --box-shadow-size-and-color: 0 4px 4px 0 var(--box-shadow-color);
  --box-shadow-header-size-and-color: 0px 3px 4px 0px rgba(0, 0, 0, 0.04),
    0px 1px 2px 0px rgba(0, 0, 0, 0.04), 0px -1px 2px 0px rgba(0, 0, 0, 0.04);

  --nav-button-color--hover: var(--gray-200);
  --nav-button-color--selected: var(--boomy-orange-500);
  --nav-profile-dropdown-transition--down: max-height 0.5s ease-in-out,
    box-shadow 0.5s ease-in-out;
  --nav-profile-dropdown-transition--up: max-height 0.1s ease-in-out,
    box-shadow 0.1s ease-in-out;

  /* tokenized lengths */
  --bottom-nav-height: 73px;
  --main-header-height: 80px;
  --header-height: calc(var(--main-header-height) + 36px);
  --header-height-tablet: calc(var(--main-header-height) + 8px);
  --main-content-aside-max-width: 1200px;
  --max-screen-width: 83rem;
  --project-screen-element-margin: 1.5em;
  --playback-footer-height: 80px;
  --playback-footer-container-height: 54px;
  --playback-footer-max-height: 559px;

  --notification-indicator-color: var(--red-700);

  /* tokenized z-index */
  --nav-profile-drop-down-z-index: -1;
  --modal-exit-icon-z-index: 1;
  --nav-profile-menu-z-index: 1;
  --find-my-engineer-z-index: 1;
  --custom-date-picker-popper-z-index: 1;
  --search-filter-drawer-backdrop-z-index: 100;
  --search-filter-drawer-z-index: 101;
  --empty-messages-screen-z-index: 100;
  --card-steps-z-index: 100;
  --floating-chat-button-z-index: 500;
  --livestream-overlay-z-index: 501;
  --bottom-nav-z-index: 1000;
  --studio-screen-fixed-footer-button-z-index: 1000;
  --message-screen-container-z-index: 1001;
  --top-nav-z-index: 1001;
  --entity-photo-screen-z-index: 1002;
  --entity-photo-screen-buttons-z-index: 500;
  --side-panel-z-index: 1002;
  --total-page-overlay-z-index: 1003;
  --modal-index: 1004;
  --location-auto-complete-z-index: 1005;
  --project-chat-panel-z-index: 10000;
  --tooltip-text-z-index: 10001;
  --file-dropdown-z-index: 1002;
  --chat-z-index: 1005;
  --playback-row-comment-container-z-index: 1000;
  --footer-player-z-index: 999;

  --dashboard-number-card--completed-projects: var(--mint-green-600);
  --dashboard-number-card--pending-projects: var(--deep-blue-300);
  --dashboard-number-card--default: var(--primary-color);

  --tooltip-background-color: var(--gray-500);

  --success-color: var(--green-600);
  --failed-color: var(--red-700);
  --pending-color: var(--gray-100);

  overflow-x: hidden;
  --bs-gutter-x: 0;
  --bs-gutter-y: 0;
  --white: #ffffff;
  --black-rgb: 0, 0, 0;
  --white-no-dark-theme: #ffffff;
  --black-no-dark-theme: #000000;
  --light-grey-no-dark-theme: #c9c9c9;
  --black: #000000;
  --off-black-no-dark-theme: #121212;
  --gray: #999999;
  --gray-no-dark-theme: #999999;
  --smooth-ivory: #fcfcfc;
  --smooth-ivory-no-dark-theme: #fcfcfc;
  --background-no-dark-theme: #212121;
  --white-smoke: #f4f4f4;
  --warm-light-grey: #f5f4f4;
  --warm-light-grey-no-dark-theme: #f5f4f4;
  --boomy-orange: var(--boomy-orange-400);
  --disabled-boomy-orange: #ff8966ac;
  --deep-blue: #6ec3f2;
  --deep-pink: #ffcef4;
  --echo-orange: #f3ba99;
  --dark-grey: #6d6d6d;
  --medium-grey: #9a9a9a;
  --light-grey: #c9c9c9;
  --chat-border: #c9c9c9;
  --stereo-black: #0c0c0c;
  --muddy-brown: #d9bba5;
  --boxy-yellow: #fadc8f;
  --sky-light-blue: #ccedff;
  --header-text: #212429;
  --timbre-tan: #ecdfd3;
  --crush-orange: #ff6c3e;
  --heart-filled-red: #db6a57;
  --error-red: #ff483e;
  --transparent-white: #ffffffcc;
  --transparent-grey: #6d6d6d80;
  --icon-button-hover-grey: #110909;
  --text-field-label-black: #212429;
  --project-row-hover: #e6e6e6;
  --calendar-hover-tile-grey: #e6e6e6;
  --calendar-neighboring-month-grey: #757575;
  --calendar-disabled-grey-text: #1010104d;
  --mint-green: #e0efd1;
  --data-green: #067b00;
  --data-red: #580202;
  --light-grey-blue: #d7ecec;
  --twilight-blue: #f0faff;
  font-family: "Roobert Woff 2", "Roobert", source-code-pro, Menlo, Monaco,
    Consolas, "Courier New", monospace !important;
  --toastify-color-error: var(--boomy-orange);
  --toastify-color-progress-error: var(--boomy-orange);
  --toastify-color-success: var(--deep-green);
  --toastify-color-progress-success: var(--deep-green);
}

[data-theme="dark"] {
  --background-color: var(--gray-950);
  --midground-color: var(--gray-800);
  --foreground-color: var(--gray-800);

  /* TODO: update with correct color for dark mode */
  --border-color: var(--gray-200);

  --primary-color: var(--boomy-orange-400);

  --text-primary-color: var(--gray-25);
  --text-secondary-color: var(--gray-200);
  --text-tertiary-color: var(--gray-300);
  --text-invert-color: var(--gray-800);
  --text-error-color: var(--red-400);

  --button-primary-color: var(--primary-color);
  --button-primary-color--hover: var(--boomy-orange-500);
  --button-secondary-color: var(--background-color);
  --button-secondary-color--hover: var(--gray-700);
  --button-outlined-color: var(--background-color);
  --button-outlined-color--hover: var(--gray-700);
  --button-tertiary-color: var(--gray-800);

  --playback-button-primary-color: var(--gray-25);
  --playback-button-secondary-color: var(--gray-500);

  --notification-indicator-color: var(--red-400);

  --horizontal-line-color: var(--gray-700);
  --checkbox-color--disabled: var(--gray-600);

  --order-breakdown-background-color: var(--deep-blue-950);
  --order-breakdown-footer-color: var(--deep-blue-800);
  --order-breakdown-border-color: var(--deep-blue-300);

  --box-shadow-color: #b6b4b42e;
  --box-shadow-color-2: var(--gray-1000);
  --box-shadow-size-and-color: 0 4px 4px 0 var(--box-shadow-color-2);

  --nav-button-color--hover: var(--gray-600);
  --nav-button-color--selected: var(--boomy-orange-400);

  --dashboard-number-card--completed-projects: var(--mint-green-500);

  --tooltip-background-color: var(--gray-300);

  --overlay: #ffffff33;
  --overlay-dark: #ffffff99;
  --white: #151515;
  --black: #ffffff;
  --modal-overlay-color: rgba(255, 255, 255, 0.89);
  --black-rgb: 255, 255, 255;
  --gray: #aeaeae;
  --smooth-ivory: #212121;
  --warm-light-grey: #252525;
  --dark-grey: #b8b8b8;
  --medium-grey: #a1a1a1;
  --light-grey: #3f3f3f;
  --stereo-black: #f0f0f0;
  --header-text: #bac4d5;
  --transparent-white: #000000cc;
  --transparent-grey: #95949480;
  --icon-button-hover-grey: #e4dbdb;
  --text-field-label-black: #d6dae0;
  --project-row-hover: #f0efef4d;
  --calendar-neighboring-month-grey: #b8b6b6;
  --calendar-disabled-grey-text: black;
  --background: #212121;
  --chat-border: #252525;
}

#root {
  height: 100%;
}
#root > div:first-child {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  max-height: 100vh;
  overflow: auto;
}

p {
  margin: 0;
}

.app-bar-content-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  animation: fadeIn ease-in-out 500ms;
}
@media (max-width: 768px) {
  .app-bar-content-container {
    margin-top: 0;
  }
}

svg {
  display: inline-block;
  vertical-align: top;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container,
.container-fluid {
  --bs-gutter-x: unset;
  
}

body.no-scroll {
  position: fixed;
  width: 100%;
  overflow: hidden;
}

/* Hide arrows on number input on Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide arrows on number input on Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
html,
body {
  height: 100%;
}

.container.fill {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.text-center {
  text-align: center;
}

#root:has(.iframe-overlay), #root:has(#twitch-embed) {
  height: 100%;
  & > div {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}

/* This class has been used in multiple elements and is being lifted here for safety */
.centered-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.h-full {
  height: 100%;
}
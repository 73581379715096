.order-summary-table {
  text-align: left;
}

.order-details {
  max-width: 300px;
  text-align: left;
  padding-left: 0;
}

.order-details > .included-list {
  padding-left: 0;
}

.old-price {
  text-decoration-line: line-through;
}

.promo-code-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}


.order-summary-card-alt-breakdown > td {
  font-size: 14px;
}
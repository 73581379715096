.booking-calendar-container {
  display: flex;
  justify-content: center;
  width: 314px;
  height: 297px;
  padding-bottom: 15px;
}

.react-calendar {
  background-color: var(--white);
  max-width: 450px;
  width: 100%;
  font-family: unset;
  line-height: 1.125em;
  border: 0.934581px solid var(--light-grey);
  box-sizing: border-box;
  border-radius: 5px;
}

.react-calendar__navigation__next2-button,
.react-calendar__navigation__prev2-button {
  display: none;
}

.react-calendar__navigation__next-button,
.react-calendar__navigation__prev-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.selected-tiles-calendar {
  position: relative;
  z-index: 1;
}

.selected-tiles-calendar > abbr {
  color: var(--white) !important;
}

.selected-tiles-calendar::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--disabled-boomy-orange);
  clip-path: circle(28% at 50% 50%);
}

.selected-tiles {
  color: var(--white) !important;
}

.date-price {
  display: block;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: var(--dark-grey);
}

.booking-calendar-container .react-calendar abbr {
  text-decoration: none !important;
  font-weight: 600;
}

.react-calendar__month-view__weekdays {
  font-weight: normal;
  text-transform: unset;
  color: var(--dark-grey);
}

.react-calendar__navigation__label__labelText {
  font-weight: bold;
  color: var(--black);
}

.react-calendar__tile--now {
  color: unset !important;
  background-color: inherit !important;
}

.react-calendar__tile:disabled {
  background: var(--white) !important;
  color: var(--light-grey) !important;
  text-decoration: line-through;
}

.react-calendar__month-view__days__day--weekend {
  color: unset !important;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: var(--light-grey) !important;
  text-decoration: none !important;
  background-color: unset !important;
}

.deselect-tile-calendar {
  color: var(--black) !important;
}

.deselect-now-tile-calendar {
  background: var(--echo-orange) !important;
}

.react-calendar__tile:enabled:hover {
  background: var(--calendar-hover-tile-grey) !important;
  clip-path: circle(28% at 50% 50%);
  transition: 0.15s;
}

.react-calendar__tile--active {
  background: var(--white) !important;
  color: black !important;
}

.react-calendar__tile:enabled:focus {
  background: var(--white) !important;
}

.react-calendar button {
  color: var(--black) !important;
}

.booking-calendar-container
  > .react-calendar
  .react-calendar__month-view__weekdays {
  color: var(--black);
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  pointer-events: none;
}

.booking-calendar-container > .react-calendar > .react-calendar__navigation {
  border-bottom: 1px solid var(--black);
  margin-bottom: 30px;
}

.booking-calendar-container
  > .react-calendar
  > .react-calendar__navigation:hover {
  background-color: transparent;
}

.booking-calendar-container
  > .react-calendar
  > .react-calendar__navigation
  > .react-calendar__navigation__label {
  background-color: transparent;
}

.booking-calendar-container .react-calendar__navigation__label__labelText {
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: var(--black);
}

.booking-calendar-container .react-calendar__navigation__next-button {
  border-radius: var(--border-radius-md);
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 !important;
  transition: 0.15s;
}

.booking-calendar-container .react-calendar__navigation__prev-button {
  border-radius: var(--border-radius-md);
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 !important;
  transition: 0.15s;
}

.booking-calendar-container .react-calendar__navigation__prev-button:hover {
  background-color: transparent;
}

.booking-calendar-container .react-calendar__navigation__next-button:hover {
  background-color: transparent;
}

@media screen and (max-width: 768px) {
  .booking-calendar-container {
    width: 330px;
    height: 297px;
  }

  .booking-calendar-container .react-calendar__navigation__label__labelText {
    font-size: 20px;
  }

  .booking-calendar-container
    > .react-calendar
    .react-calendar__month-view__weekdays {
    font-size: 12px;
  }
}

.nav-list-item-container {
  display: flex;
  list-style-type: none;
}

.nav-list-item-link {
  background-color: var(--background-color);
  border: none;
  cursor: pointer;
  width: 100%;
  text-decoration: none;
  text-align: start;
  padding: 0.75rem;
  color: var(--text-primary-color);
  transition: 0.2s;

  &:hover {
    background-color: var(--button-secondary-color--hover);
  }
}
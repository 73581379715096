.editable-text-field {
  all: unset;
  transition: all ease-in 500ms;
  color: var(--black);
}

.editable-text-field:enabled:not([type="submit"]) {
  border: 1px solid var(--light-grey);
  box-sizing: border-box;
  border-radius: 10px;
  padding: 10px;
}

.editable-text-field:enabled:not([type="submit"]):focus {
  border: 1px solid var(--deep-blue);
}

.editable-text-field:focus {
  outline: none;
}

.max-width-text-field {
  width: 100%;
  height: 69px;
  margin-bottom: 20px;
}

.update-project-title-text-field {
  width: 100%;
}

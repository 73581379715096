details {
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  cursor: pointer;
}

details[open] summary ~ * {
  animation: sweep 0.5s ease-in-out;
}

@keyframes sweep {
  0% {
    opacity: 0;
    transform: translateX(-10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

details > summary {
  color: var(--dark-grey);
}

summary[open] {
  rotate: 90deg;
}

details > p {
  margin-top: 5px;
  padding-left: 10px;
  cursor: auto;
}

.b1.collapsable-component-hint-primary {
  color: var(--text-primary-color);
}

.b1.collapsable-component-hint-secondary {
  color: var(--text-secondary-color);
}

.b1.collabsable-component-hint-bold {
  font-weight: 600;
}

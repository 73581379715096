.full-width-header-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 30px;
}

.full-width-header-item {
  max-width: 950px;
  text-align: center;
}

.full-width-header-item > p,
h1 {
  margin-bottom: 20px;
}

@media screen and (max-width: 800px) {
  .full-width-header-container {
    margin-left: 15px;
    margin-right: 15px;
  }
  .full-width-header-item {
    margin-left: 5px;
    margin-right: 5px;
  }
}

.dashboard-card {
  border-radius: var(--border-radius-lg);
  background: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 30px;
}

.dashboard-card-content-only {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 30px;
}

.dashboard-card-content {
  overflow-y: auto;
  width: 100%;
}

.no-overflow-content {
  overflow-y: unset;
}

.dashboard-card-content-only.dashboard-flex, .dashboard-card-content.dashboard-flex {
  display: flex;
  flex-direction: column;
  overflow-y: unset;
  flex: 1;
}

@media (max-width: 480px) {
  .dashboard-card {
    padding: 24px;
    border-radius: unset;
  }

  .dashboard-card-content-only {
    padding: 24px;
  }
}

.review-component-container {
  font-size: 16px;
  line-height: 20px;
}

.review-component-rating {
  display: flex;
  align-items: center;
}

.review-component-rating > .star-rating {
  margin: 0;
  display: inline-block;
  width: 90px;
  padding-top: 6px;
}

.review-component-date {
  color: var(--dark-grey);
}

.account-details-container.card-container {
  display: flex;
  flex-direction: column;
  padding: 30px;
  height: auto;
  min-width: 300px;
}

@media screen and (max-width: 330px) {
  .account-details-container.card-container {
    min-width: unset;
  }
}

.account-details-input {
  width: 100%;
  margin-top: var(--project-screen-element-margin);
  margin-bottom: var(--project-screen-element-margin);
  outline: none;
  border: solid 0.75px;
  border-radius: 4px;
  border-color: var(--light-grey);
  height: 35px;
  padding-left: 10px;
  color: var(--gray);
  white-space: nowrap;
}

.studio-sub-button-row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  justify-content: center;
  margin-top: 20px;
}
.nav-button {
  align-items: center;
  background-color: var(--button-secondary-color);
  border: none;
  border-radius: var(--button-border-radius);
  color: var(--text-primary-color);
  cursor: pointer;
  display: flex;
  font-weight: 600;
  margin-right: 0.5rem;
  padding: var(--button-padding);
  text-decoration: none;
  transition-duration: var(--button-transition-duration);

  &:hover {
    background-color: var(--button-secondary-color--hover);
    transition-duration: var(--button-transition-duration);
  }
}

.nav-button-text {
  display: inline-block;
}

.notification-indicator:after {
  content: "";
  position: absolute;
  top: 0;
  right: -7px;
  transform: translateY(-4px);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: var(--notification-indicator-color);
  animation: presentUnreadCountIndicator 1s;
}

.hide-indicator {
  animation: hideUnreadCountIndicator 2s;
}

@keyframes presentUnreadCountIndicator {
  0% {
    opacity: 0;
    -webkit-transform: translateY(0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(-4px);
  }
}

@keyframes hideUnreadCountIndicator {
  0% {
    opacity: 1;
    -webkit-transform: translate(10%, -50%);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(30%);
  }
}

.card-container {
  border-radius: 17px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: var(--white);
  box-shadow: 0px 0px 6px 0px var(--box-shadow-color);
  overflow: hidden;
  height: 100%;
  padding: 0px;
}

@media (max-width: 768px) {
  .card-container {
    border-radius: 10px;
  }
}

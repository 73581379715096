.generate-booking-select-service-dropdown_container {
  width: 100%;
  display: flex;
  align-items: center;
}

.generate-booking-select-service-dropdown_quantity-container {
  display: flex;
  align-items: center;
  border: 1px solid var(--border-color);
  padding: 16px;
  border-top-left-radius: var(--border-radius-md);
  border-bottom-left-radius: var(--border-radius-md);
  line-height: 20px;
  background-color: var(--white);
  height: 20px;
}

.generate-booking-select-service-dropdown_quantity-container > p,
.generate-booking-select-service-dropdown_quantity-container > button {
  width: 24px;
}

.generate-booking-select-service-dropdown_quantity-selector {
  cursor: pointer;
  /* Override default button stylings */
  border: none;
  background-color: transparent;
  padding-block: 0;
  padding-inline: 0;
}

.generate-booking-select-service-dropdown_dropdown-container {
  flex: 1;
  border: 1px solid var(--border-color);
  border-left: none;
  border-top-right-radius: var(--border-radius-md);
  border-bottom-right-radius: var(--border-radius-md);
  background-color: var(--white);
}

.generate-booking-select-service-dropdown_dropdown-element-container {
  height: 52px !important;
}

.generate-booking-select-service-dropdown_dropdown-element-select-container {
  border: none;
  text-align: left;
}

.generate-booking-select-service-dropdown_dropdown-element-label-text {
  margin-left: 16px !important;
}

.generate-booking-select-service-dropdown_dropdown-element-arrow-container {
  cursor: pointer;
}

.generate-booking-select-service-dropdown_number-quantity-container {
  border: unset;
  color: var(--black-no-dark-theme);
  display: flex;
  font-size: var(--text-size-md);
  text-align: center;
}

.generate-booking-select-service-dropdown_number-quantity-container :focus {
  outline: var(--border-color) auto 1px;
}

.profile-nav-header {
  align-items: center;
  background-color: var(--background-color);
  border-radius: var(--border-radius-sm);
  border: none;
  cursor: pointer;
  display: flex;
  height: fit-content;
  justify-content: space-between;
  padding: 0.75rem;
  width: 100%;

  &:hover {
    background-color: var(--button-secondary-color--hover);
  }
}

.profile-nav-header.show {
  border-radius: var(--border-radius-sm) var(--border-radius-sm) 0 0;
}


@media (max-width: 480px) {
  .profile-nav-header.show {
    border-radius: 0;
  }
}

.profile-nav-header-left {
  display: flex;
  align-items: center;
}

.profile-nav-header-text-container {
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  width: 0px;
}

.profile-nav-header-text-container.show {
  margin-right: 5px;
  width: 110px;
}

.profile-nav-header-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: start;
  padding: 0.1rem 0;
}

.profile-nav-header-image {
  width: 42px;
  height: 42px;
  border: solid thin var(--gray-100);
  border-radius: 50%;
  margin-right: 0.75rem;
}
.profile-nav-header-image-mobile {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: solid thin var(--gray-100);
}

.profile-nav-header-icon {
  transition: all 0.2s ease-in-out;
}

.profile-nav-header-icon.expanded {
  transform: rotate(180deg);
}

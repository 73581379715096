.entity-photo-grid-container {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  height: 346px;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
}

.entity-photo-grid-container > .view-all-photos-button {
  right: 8px;
  bottom: 8px;
  left: unset;
}

.entity-photo-grid-container-section {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: flex-start;
  width: 50%;
}

.entity-photo-grid-container-section:first-child {
  margin-right: 8px;
}

.entity-photo-grid-container-section-row {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-start;
  flex: 1;
}

.entity-photo-grid-container-section-row:first-child {
  margin-bottom: 8px;
}

.entity-photo-item {
  background-color: var(--light-grey);
  flex: 1;
  cursor: pointer;
}

.entity-photo-item > img {
  height: 0;
  min-height: 100%;
  object-fit: cover;
  width: 100%;
  opacity: 0;
  transition: opacity 1s ease-in;
}

.entity-photo-item:hover {
  filter: brightness(0.8);
  transition: filter 0.5s smooth;
}

.no-entity-photo-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes pulse-animation {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

.entity-photo-item-loading {
  animation: pulse-animation 1s infinite;
}

.fade-in-entity-image > img {
  opacity: 1;
}

.fade-in-no-image-icon {
  animation: fade-in 1s ease-in;
}

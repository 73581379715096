.button-default {
  align-items: center;
  border: 0;
  border-radius: var(--border-radius-md);
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: var(--text-primary-color);
  cursor: pointer;
  display: flex;
  font-weight: 600;
  justify-content: center;
  line-height: 1;
  min-height: 44px;
  padding: var(--button-padding);
  text-align: center;
  text-decoration: none;
  text-transform: none;
  transition: all ease-in-out var(--button-transition-duration);
  width: fit-content;
}

.button-full-width {
  width: 100%;
}

.button-primary {
  background-color: var(--button-primary-color);
  color: var(--white-0);

  &:hover {
    background-color: var(--button-primary-color--hover);
  }
}

.button-primary--disabled {
  background-color: var(--button-disabled-color);
  cursor: not-allowed;
  color: var(--white-0) !important;

  &:hover {
    background-color: var(--button-disabled-color);
  }
}

.button-success {
  background-color: var(--green-600);
  color: var(--white-0);
  &:hover {
    background-color: var(--green-700);
  }
}
.button-success--disabled {
  background-color: var(--green-300);
  color: var(--white-0);
  cursor: not-allowed;
}

.button-error {
  background-color: var(--red-500);
  color: var(--white-0);
  &:hover {
    background-color: var(--red-700);
  }
}
.button-error--disabled {
  background-color: var(--red-300);
  color: var(--white-0);
  cursor: not-allowed;
}

.button-outlined {
  background-color: var(--button-outlined-color);
  border: solid 1px var(--text-primary-color);
  color: var(--text-primary-color);

  &:hover {
    background-color: var(--button-outlined-color--hover);
  }
}

.button-outlined--disabled {
  background-color: var(--button-outlined-color);
  border: solid 1px var(--button-disabled-color);
  color: var(--button-disabled-color);
  cursor: not-allowed;
}

.button-gradient {
  background: linear-gradient(
    88.08deg,
    var(--echo-orange) 0%,
    var(--boomy-orange) 100%
  );
  color: var(--black-0);

  &:hover {
    filter: brightness(85%);
  }
}

.button-gradient--disabled {
  background: linear-gradient(
    88.08deg,
    var(--echo-orange) 0%,
    var(--boomy-orange) 100%
  );
  color: var(--black-0) !important;
  cursor: not-allowed;
  opacity: 0.5;
}

.button-unstyled {
  border: none;
  background-color: transparent;
  height: unset;
  padding-block: 0;
  padding-inline: 0;

  &:hover {
    background-color: transparent;
  }
}

.button-unstyled--disabled {
  border: none;
  background-color: transparent;
  color: var(--text-tertiary-color);
  cursor: not-allowed;
  height: unset;
  padding-block: 0;
  padding-inline: 0;
}

.button-ghost {
  border: none;
  background-color: transparent;
  outline: none;
  color: var(--text-secondary-color);

  &:hover {
    background-color: var(--midground-color);
  }
}

.button-ghost--disabled {
  cursor: not-allowed;
  color: var(--button-disabled-color);
}

.button-tertiary {
  background-color: var(--button-tertiary-color);

  path {
    fill: var(--text-primary-color);
  }
}

.button-tertiary--disabled,
.button-active--disabled {
  background-color: var(--button-disabled-color);
  cursor: not-allowed;
  color: var(--white-no-dark-theme) !important;

  path {
    fill: var(--white-no-dark-theme);
  }
}

.button-active {
  background-color: var(--mint-green);
  color: var(--black-no-dark-theme);

  path {
    fill: var(--black-no-dark-theme);
    }

  &:hover {
    background-color: var(--mint-green);
  }
}

.button-text {
  background-color: transparent;
  color: var(--text-primary-color);
  padding: 0;
  font-weight: 400;
  text-decoration: underline;
  text-underline-offset: 2px;
  height: auto;

  &:hover {
    background-color: transparent;
    text-decoration: underline;
  }
}

.button-text--disabled {
  cursor: not-allowed;
  color: var(--text-tertiary-color);
  background-color: transparent;
  padding: 0;
  height: auto;

  svg {
    color: var(--text-tertiary-color);
  }
}

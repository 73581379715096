.recording-booking-calendar-container {
  height: unset;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
  border: solid 1px var(--border-color);
  border-radius: var(--border-radius-md);
  padding: 30px;
  width: calc(100% - 60px);
}

.recording-booking-calendar-container > .booking-calendar-container {
  width: 100%;
  height: fit-content;
}

.recording-booking-calendar-container
  > .booking-calendar-container
  > .react-calendar {
  max-width: unset;
  border: none;
  background-color: var(--white);
}

@media screen and (max-width: 768px) {
  .recording-booking-calendar-container > .booking-calendar-container {
    max-width: unset;
    height: 297px;
  }

  .recording-booking-calendar-container > .booking-calendar-container {
    max-width: unset;
    height: 297px;
  }
}

.completed-session-date {
  position: relative;
  z-index: 1;
}

.not-currently-selected-date {
  position: relative;
  z-index: 1;
}

.recording-booking-calendar--unselectable:enabled:hover {
  background: inherit !important;
  clip-path: circle(35% at 50% 50%);
  cursor: default !important;
}

.invalid-session-date {
  position: relative;
  z-index: 1;
}

.invalid-session-date > abbr {
  color: var(--notification-indicator-color);
}

.invalid-session-date--unselected {
  position: relative;
  z-index: 1;
}

.invalid-session-date--unselected > abbr {
  color: var(--notification-indicator-color);
}

.invalid-session-date--unselected::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--light-grey);
  clip-path: circle(28% at 50% 50%);
}

.completed-session-date::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--boomy-orange) !important;
  clip-path: circle(28% at 50% 50%);
}

.completed-session-date > abbr {
  color: black !important;
}

.not-currently-selected-date::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--light-grey);
  clip-path: circle(28% at 50% 50%);
}

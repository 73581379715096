.money {
  font-family: Roobert;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;

  display: flex;
  align-items: center;
  text-align: center;
}

.stereoblack {
  color: var(--stereo-black);
}

.lightgrey {
  color: var(--light-grey);
}

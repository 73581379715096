.action-items-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: calc(100% - 30px);
  padding: 15px;
  height: calc(100% - 30px);
  overflow: auto;
}

.action-items-scroll-container {
  width: 100%;
  overflow-y: scroll;
}

.action-items-header {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: calc(100% - 15px);
  padding-left: 15px;
}

.action-items-row-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;
  width: 100%;
}

.action-items-empty-list-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.empty-text {
  text-align: center;
  white-space: normal;
}

.action-items-side-panel-container {
  height: calc(100% - 120px);
}

.action-items-column-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
}

.action-items-column-container > .action-item-row-container {
  margin-bottom: 10px;
}

.action-items-header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
}

.dashboard-card-header__title.active {
  text-decoration: underline;
}

.action-items-header-container > .dashboard-card-header__title:first-child {
  margin-right: 10px;
}

@media screen and (min-width: 768px) {
  .action-items-container {
    padding: 15px 30px;
    border-radius: 15px;
    height: calc(100% - 30px);
    width: calc(100% - 60px);
  }

  .action-items-header {
    padding-top: unset;
    padding-bottom: 15px;
    justify-content: space-between;
  }

  .action-items-scroll-container {
    max-height: 600px;
  }

  .action-items-side-panel-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0px 30px;
    height: calc(100% - 60px);
    width: calc(100% - 60px);
  }

  .action-items-column-container {
    height: 100%;
    overflow: auto;
  }
}

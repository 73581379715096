.sticky-header-container {
  position: fixed;
  width: 100%;
  transition: 0.3s ease;
  box-shadow: var(--box-shadow-header-size-and-color);
  z-index: var(--top-nav-z-index);
}

.app-bar-main-content-container {
  background-color: var(--background-color);
  display: flex;
  justify-content: center;
}

.app-bar-main-content {
  position: relative;
  background-color: var(--background-color);
  display: flex;
  align-items: center;
  height: var(--main-header-height);
  margin: 0;
  max-width: var(--max-screen-width);
  padding: 0 var(--project-screen-element-margin);
  width: 100%;
}

.engineears-logo {
  margin-right: 16px;
}

.engineears-logo > img {
  height: 37.4px;
  width: 220px;
}

.shopping-cart {
  display: flex;
  padding: 0.75rem;
}

@media (max-width: 768px) {
  .engineears-logo {
    margin-right: 0px;
  }

  .engineears-logo > img {
    width: 180px;
    height: 30px;
  }

  .app-bar-main-content {
    justify-content: center;
    align-items: center;
  }
}

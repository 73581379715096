.screen-wrapper {
  position: relative;
  margin-top: 35px;
}

@keyframes fade-in-screen {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.screen-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  animation: fade-in-screen 0.3s ease-in-out;
}

.weekdays-row-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
  margin-right: 15px;
}

.weekday-pill {
  background-color: var(--timbre-tan);
  margin-right: 5px;
  margin-bottom: 8px;
}

.weekday-pill > p {
  color: var(--black);
}

.weekday-pill:hover {
  cursor: pointer;
}

.weekday-pill {
  transition: all ease-in 500ms;
  margin-bottom: 5px;
}

.selected {
  background-color: var(--black);
}

.selected > p {
  color: var(--white);
}

.dark-mode-weekday-picker .weekday-pill {
  background-color: var(--grey);
}

.dark-mode-weekday-picker .weekday-pill.selected {
  background-color: var(--black);
}

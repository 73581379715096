.brix---section-extra-small {
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding-top: 0px;
  padding-bottom: 32px;
}

@media screen and (max-width: 991px) {
  .brix---section-extra-small {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

@media screen and (max-width: 767px) {
  .brix---section-extra-small {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 479px) {
  .brix---section-extra-small {
    padding-top: 48px;
    padding-bottom: 96px;
  }
}

.text-block-8 {
  display: block;
  margin-top: 0px;
  align-self: center;

  font-family: Roobert;
  color: hsla(0, 0.00%, 100.00%, 1.00);
  font-size: 24px;
  font-weight: 300;
  text-align: center;
}

@media screen and (min-width: 1440px) {
  .text-block-8 {
    font-family: Roobert;
    font-size: 22px;
  }
}

@media screen and (max-width: 991px) {
  .text-block-8 {
    font-size: 10px;
  }
}

@media screen and (max-width: 479px) {
  .text-block-8 {
    font-size: 9px;
  }
}
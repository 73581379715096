.session-date-modal {
  background-color: var(--white);
  width: 95vw;
  max-width: 555px;
  border-radius: 10px;
  position: relative;
}

.session-date-modal > .studio-calendar-container > .booking-calendar-container,
.studio-calendar-header-container.recording-booking {
  height: fit-content;
  width: 90%;
}

.session-date-modal__close-button {
    font-size: 20px;
    color: var(--black);
    cursor: pointer;
    text-align: center;
    text-decoration: underline;
    text-transform: capitalize;
}
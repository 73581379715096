.image-carousel-container {
  position: relative;
  width: 100%;
  margin: auto;
}

.view-all-photos-button {
  width: 194px;
  height: 50px;
  background-color: var(--white);
  border-radius: 100px;
  position: absolute;
  bottom: 10%;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .view-all-photos-button {
    width: 160px;
    height: 40px;
  }
}

.view-all-photos-text {
  display: inline-block;
  vertical-align: middle;
  line-height: 49px;
}

@media (max-width: 768px) {
  .view-all-photos-text {
    line-height: 40px;
  }
}

.image-carousel-upload-button {
  display: flex;
  color: var(--black-no-dark-theme);
  background-color: var(--white-no-dark-theme);
  cursor: pointer;
  border-radius: 100px;
  font-size: 20px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  position: absolute;
  justify-content: space-between;
  align-items: center;
  top: 1em;
  right: 3em;
  width: 76px;
  height: 16px;
}

.image-carousel-upload-button > .h8-semi-bold {
  color: var(--black-no-dark-theme);
}

.image-carousel-delete-button {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 35px;
  height: 35px;
  border-radius: calc(35px / 2);
  position: absolute;
  color: var(--black-no-dark-theme);
  background: var(--white-no-dark-theme);
  top: 1em;
  margin-top: 4px;
  transition: all 0.2s ease-in-out;
  right: 1em;
}

.image-slide-container {
  position: relative;
}

.image-carousel-add-modal {
  padding: 15px;
  width: 350px;
  height: 150px;
  background-color: var(--warm-light-grey);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px black solid;
}

.image-carousel-dropzone {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  max-height: 426px;
  display: flex;
  border: 2px dashed var(--boomy-orange);
  border-radius: 15px;
  cursor: pointer;
}

.image-carousel-dropzone:hover {
  background-color: rgba(255, 137, 102, 0.1);
}

.image-carousel-empty-container {
  padding-left: 75px;
  padding-right: 75px;
  display: flex;
}
.image-carousel-placeholder-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.image-carousel-placeholder-image {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 64px;
  border-radius: 50%;
  height: 125px;
  width: 125px;
  background-color: var(--boomy-orange);
  margin-bottom: 15px;
}

.placeholder-text {
  text-align: center;
  margin-bottom: 15px;
}

@media screen and (max-width: 768px) {
  .image-carousel-empty-container {
    padding-left: 15px;
    padding-right: 15px;
  }

  .image-carousel-dropzone {
    max-height: 210px;
    max-width: 100%;
  }
  .image-carousel-placeholder-image {
    font-size: 44px;
    height: 88px;
    width: 88px;
  }
}

.image-carousel-file-input {
  border-radius: 10px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border: 1px dashed var(--black-no-dark-theme);
}

.go-back-button {
  cursor: pointer;
  padding: 10px;
  border-radius: 10px;
  font-size: 20px;
  position: absolute;
  color: var(--smooth-ivory);
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid var(--smooth-ivory);
  top: 1em;
  left: 1em;
}

@media screen and (max-width: 768px) {
  .go-back-button {
    font-size: 15px;
  }
}

.delete-photo-modal {
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  padding: 60px;
  width: 75vw;
  max-width: 690px;
  height: fit-content;
  border-radius: 10px;
}

.delete-photo-modal-text {
  padding-bottom: 15px;
  text-align: center;
  font-family: "Roobert Woff 2";
}

.delete-photo-modal-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.image-carousel-secondary-button {
  margin-right: 16px;
}

@media screen and (max-width: 768px) {
  .delete-photo-modal {
    padding: 30px;
  }

  .delete-photo-modal-buttons {
    justify-content: center;
    flex-direction: column-reverse;
  }
  .image-carousel-secondary-button {
    margin-right: 0px;
    margin-top: 16px;
  }
}

.image-index-container {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 3px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 1em;
  right: 1em;
  height: 13px;
  width: 44px;
  color: var(--white-no-dark-theme);
  background-color: rgba(0, 0, 0, 0.5);
}

.image-carousel-no-photos-container {
  height: 100vh;
  max-height: 210px;
  width: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--medium-grey);
  max-width: 399px;
}

.studio-room-image.image-carousel-no-photos-container {
  border: 2px dashed var(--medium-grey);
  background-color: rgba(0,0,0,0.1);
  height: 160px;
  width: 100%;
  max-width: min(260px, 100vw);
  max-height: unset !important;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.primary-entity-photo {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.studio-room-image > .primary-entity-photo {
  height: 160px;
  max-width: min(260px, 100vw);
  border-radius: 10px;
  object-fit: cover;
}

.image-carousel-dropzone-more-photos-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  max-height: 426px;
  border: 2px dashed var(--boomy-orange);
  border-radius: 15px;
  background-color: var(--transparent-white);
  cursor: pointer;
}

.image-carousel-modal-placeholder-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 268px;
  padding: unset;
  width: 340px;
}

.go-back-button-no-photos {
  color: var(--black);
  border: 1px solid var(--black);
  left: 5em;
}

@media screen and (max-width: 768px) {
  .go-back-button-no-photos {
    left: 2em;
  }
}

.room-name-container {
  padding: 10px;
  border-radius: 3px;
  text-align: center;
  position: absolute;
  bottom: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 50%;
  height: fit-content;
  width: 128px;
  color: var(--white-no-dark-theme);
  background-color: rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 768px) {
  .primary-entity-photo {
    max-height: 210px;
  }
  .room-name-container {
    width: fit-content;
    right: unset;
    left: 1em;
  }
}

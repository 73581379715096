.studio-teammates-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 30px;
}

.studio-teammates-label {
  color: var(--medium-grey);
  margin-bottom: 5px;
}

.studio-teammates-images {
  display: flex;
  width: 100%;
  flex-direction: row;
  padding-top: 20px;
  margin-right: 30px;
  flex-wrap: wrap;
  padding-bottom: 10px;
  gap: 2rem;
  align-items: center;
}

.studio-teammates-image {
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  width: 90px;
  padding-bottom: 10px;
  cursor: pointer;
}

.studio-teammates-image img {
  height: 85px;
  border-radius: 50%;
  border: 1px solid var(--light-grey);
}

.studio-teammates-view-all {
  color: var(--black);
  text-decoration: underline;
  cursor: pointer;
}

.studio-teammates-images-username {
  text-overflow: ellipsis;
  overflow: hidden;
}

.engineer-booking-link-setting {
  margin-top: 10px;
}

.arrow-button-container {
  max-width: 400px;
}

.left-arrow-button {
  cursor: pointer;
  gap: 10px;
  width: 150px;
  height: inherit;
  top: 35px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  position: absolute;
  left: var(--project-screen-element-margin);
  filter: grayscale(100%);
}

.left-arrow-button label {
  cursor: pointer;
}

@media (max-width: 768px) {
  .left-arrow-button {
    left: 0;
    top: 15px;
  }
}

.dark-color {
  filter: brightness(0) invert(1);
}

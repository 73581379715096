.direct-message-button {
  width: 100%;
}

.direct-message-card {
  flex-direction: column;
  min-width: 300px;
  max-width: 700px;
  padding: var(--project-screen-element-margin);
}

.submit-message-request-button {
  margin-top: var(--project-screen-element-margin);
  width: 100%;
}

.direct-message-card > p {
  align-self: self-start;
  margin-bottom: var(--project-screen-element-margin);
}

.social-media-buttons-list {
  display: flex;
  gap: 10px;
}

.add-on-menu {
  display: flex;
  flex-direction: column;
  width: 20vw;
  gap: 1vh;
}

.add-on-menu > .drop-down-selector-container .css-ljgy9j-control {
  border-radius: 8px;
}

.add-handle-field {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.add-handle-field > .textfield {
  width: 100%;
  margin-right: 5px;
}

.add-handle-field > button {
  border-radius: 8px;
}

.add-on-button-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

@media screen and (max-width: 736px) {
  .add-on-menu {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .social-media-buttons-list {
    flex-wrap: wrap;
  }
}

.trending-percentage-container {
  display: flex;
  align-items: center;
  justify-content: center;
  color: inherit;
}

.chart-header-button-icon {
  width: 13.5px;
  height: 12px;
  color: var(--data-green);
}

.chart-header-button-icon.negative-icon {
  width: 13.5px;
  height: 12px;
  color: var(--data-red);
}

.chart-header-button-percentage {
  padding-left: 5px;
  font-size: 12px;
  font-family: "Roobert";
  font-weight: 400;
  line-height: 16px;
  word-wrap: break-word;
  color: var(--data-green);
}

.chart-header-button-percentage.negative-percentage {
  padding-left: 5px;
  font-size: 12px;
  font-family: "Roobert";
  font-weight: 400;
  line-height: 16px;
  word-wrap: break-word;
  color: var(--data-red);
}

.chart-header-button-text {
  color: var(--medium-grey);
  font-size: 12px;
  font-family: "Roobert";
  font-weight: 600;
  line-height: 16px;
  padding-left: 5px;
  word-wrap: break-word;
}

.react-calendar.session-calendar {
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: unset;
  border: none;
  padding-top: 24px;
  background: inherit;
}

.react-calendar.session-calendar .react-calendar__tile--active {
  background: inherit;
  color: var(--text-primary-color);
}

.react-calendar.session-calendar .react-calendar__navigation__label__labelText {
  font-size: var(--text-size-xxlg);
  color: var(--text-primary-color);
}

.react-calendar.session-calendar .react-calendar__tile:enabled:hover {
  clip-path: unset;
  background: inherit !important;
}

.react-calendar.session-calendar abbr {
  display: none;
}

.react-calendar.session-calendar
  .react-calendar__month-view__weekdays__weekday
  abbr {
  display: unset;
}

.react-calendar.session-calendar > .react-calendar__navigation {
  margin-right: auto;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 24px;
  margin-top: 24px;
  margin-bottom: 24px;
}

@media (max-width: 768px) {
  .react-calendar.session-calendar > .react-calendar__navigation {
    margin-right: unset;
  }
}

.react-calendar.session-calendar
  > .react-calendar__navigation
  > button:disabled {
  background: unset;
}

.react-calendar.session-calendar > .react-calendar__viewContainer {
  width: 100%;
}

.react-calendar.session-calendar
  > .react-calendar__navigation
  > .react-calendar__navigation__next-button,
.react-calendar.session-calendar
  > .react-calendar__navigation
  > .react-calendar__navigation__prev-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid thin var(--black);
  border-radius: 50%;
  width: 32px;
  height: 32px;
  min-width: unset;
}

.react-calendar.session-calendar
  > .react-calendar__navigation
  > .react-calendar__navigation__label {
  color: var(--text-primary-color);
  font-size: var(--text-size-lg);
  font-style: normal;
  font-weight: var(--text-weight-semibold);
  line-height: var(--text-line-height-long);
  letter-spacing: -0.48px;
}

.user-profile-image {
  object-fit: cover;
  cursor: pointer;
  position: relative;
}
.user-profile-image > .save-profile-button {
  position: absolute;
  bottom: 0.5em;
  right: 0.5em;
}
.user-profile-circle {
  border-radius: 50%;
}

.over-lay-container {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all ease-in 500ms;
}

.square-radius {
  border-radius: 15px;
}

#top,
#bottom {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#top {
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: var(--transparent-grey);
}

.upload-text {
  text-align: center;
}

.scroll-overflow {
  overflow: auto;
}

.project-titles-table th {
  text-align: inherit;
}

.add-alts-form .textfield {
  width: 80%;
  margin-right: 10px;
}

.card-header-row {
  padding-bottom: var(--project-screen-element-margin);
  margin-top: var(--project-screen-element-margin);
  border-bottom: solid thin black;
}

.card-header-row .textfield {
  height: 20px;
  width: unset;
}

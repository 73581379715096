.announcement-header-container {
  background-color: var(--primary-color);
  display: flex;
  justify-content: center;
  min-height: 0.5rem;
  width: 100%;
}

.announcement-header-content {
  display: flex;
  justify-content: space-between;
  max-width: var(--max-screen-width);
  padding: 0.69rem var(--project-screen-element-margin);
  width: 100%;
}

.announcement-header-links-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.announcement-header-link {
  color: var(--text-invert-color);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

@media only screen and (max-width: 768px) {
  .announcement-header-content {
    display: none;
  }
}

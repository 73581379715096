.notification-content {
  background-color: var(--foreground-color);
  border-radius: 15px;
  border: solid var(--background-color);
  overflow-y: scroll;
  position: absolute;
  display: none;
  width: 400px;
  max-height: 400px;
  height: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  top: 40px;
  left: -200px;
  padding: 0px 0px;
  z-index: 999;
}

.notification-content.show {
  display: block;
}

.dropdown-container.hide {
  display: none;
}

.manage-payments-settings-container {
  display: flex;
  flex-direction: column;
}

.manage-payments-settings-header {
  width: 100%;
  display: flex;
  align-items: center;
}

.manage-payments-settings-content {
  padding: 20px 0;
}

.team-member-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 12px;
  width: calc(100% - 26px);
  border: 1px solid var(--warm-light-grey);
  background-color: var(--white);
  color: var(--black);
  border-radius: 5px;
  height: 24px;
  flex-direction: row;
  position: relative;
}

.team-member-info-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 240px;
}

.team-member-message-icon {
  cursor: pointer;
}

.member-online-icon {
  width: 9px;
  height: 9px;
  border-radius: 50%;
}

.team-member-icons-container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  width: 44px;
}

.team-member-info-container > .member-online-icon {
  margin-right: 15px;
}

.team-member-image {
  border-radius: 50%;
}

.team-member-info-container > .team-member-image {
  margin-right: 10px;
}

.team-member-info-container > .b2-semi-bold,
.team-member-info-container > .b2 {
  max-width: 110px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.team-member-info-container > .b2-semi-bold {
  margin-right: 11px;
}

.team-member-container > .b2-semi-bold {
  margin-left: auto;
  margin-right: auto;
  white-space: nowrap;
}

.team-member-menu-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: fit-content;
  border: 1px solid var(--warm-light-grey);
  border-radius: 5px;
  position: absolute;
  right: 25px;
  z-index: 1;
  background-color: var(--white);
}

.team-member-icon {
  cursor: pointer;
}

.team-member-menu-item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: calc(100% - 10px);
  padding: 5px;
  cursor: pointer;
}

.team-member-menu-item:hover {
  background-color: var(--warm-light-grey);
}

@media screen and (max-width: 1024px) {
  .team-member-info-container {
    width: fit-content;
  }
}

@media screen and (max-width: 768px) {
  .team-member-icons-container {
    width: fit-content;
  }
}

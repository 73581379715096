.asset-review-drop-down-container {
    margin-bottom: 20px;
    width: 80%;
}

.asset-review-modal-input {
    box-sizing: border-box;
    width: 100%;
    outline: none;
    border: solid 0.75px;
    border-radius: 4px;
    border-color: var(--dark-grey);
    height: 40px;
    padding-left: 10px;
    color: var(--dark-gray);
    white-space: nowrap;
}

.sign-in-modal {
  background-color: var(--white);
  padding: 15px;
  border-radius: 10px;
}

.sign-in-modal > .redirect-button {
  margin-bottom: 20px;
  text-decoration: none;
  text-align: right;
}

.sign-in-modal > .redirect-button > .b2-semi-bold {
  text-decoration: none;
}

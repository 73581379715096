.chat-room-header {
  height: auto;
  width: 100%;
  border-bottom: solid thin var(--warm-light-grey);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1em;
}

.chat-room-member-row {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  width: 100%;
}

.chat-room-member-row > .flex-column {
  margin-right: 1em;
}

.message-details-container {
  width: 100%;
}

.chat-partner-text {
  text-align: center;
}

.chat-room-body {
  display: flex;
  flex-direction: column;
  max-height: 622px;
  overflow-y: scroll;
  width: 100%;
}

.chat-room-footer {
  min-height: 53px;
  width: 100%;
  border-top: solid thin var(--warm-light-grey);
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat-room-text-field {
  all: unset;
  transition: all ease-in 500ms;
  width: 100%;
  padding: 5px;
}

.chat-room-text-field:enabled:not([type="submit"]) {
  box-sizing: border-box;
  border-radius: 10px;
  padding: 10px;
}

.chat-room-text-field:enabled:not([type="submit"]):focus {
}

.chat-room-text-field:focus {
  outline: none;
}

.footer-icon {
  padding: 10px;
}
.str-chat__date-separator-date {
  color: var(--text-primary-color);
}
.str-chat-channel.messaging .str-chat__main-panel {
  padding: 0;
}

.chat-room-container > .str-chat__thread {
  padding: 0;
  margin: 0;
  border-left: solid thin var(--warm-light-grey);
}

.chat-room-container > .messaging.str-chat .str-chat__thread {
  padding: 0;
  margin: 0;
  background-color: var(--white);
}

.messaging.str-chat .str-chat__thread-header {
  border-radius: 15px;
}

@media screen and (max-width: 480px) {
  .str-chat.messaging,
  .str-chat__container,
  .str-chat__list,
  .str-chat__main-panel {
    min-width: 100vw;
    background-color: var(--smooth-ivory);
  }
}

.chat-room-container > .str-chat.messaging {
  width: 100%;
  max-width: 860px;
}

.str-chat__message-text-inner,
.str-chat__message-simple-text-inner {
  background-color: var(--sky-light-blue);
}

.str-chat__message--me .str-chat__message-text-inner,
.str-chat__message-simple--me .str-chat__message-text-inner {
  background-color: var(--timbre-tan);
}

.chat-room-container > .str-chat-channel .str-chat__container {
  border: solid thin var(--chat-border);
  /*make new color for chat border*/
  border-radius: 15px !important;
  width: 100%;
  overflow: hidden;
}

@media screen and (max-width: 1392px) {
  .chat-room-container > .str-chat-channel .str-chat__container {
    border-radius: 0 !important;
  }
}

@media screen and (max-width: 960px) {
  .chat-room-container .str-chat-channel .str-chat__container {
    width: 100%;
    max-height: 100%;
    border-radius: 15px !important;
  }


  .chat-room-container .str-chat__main-panel > .str-chat__list {
    height: 100%;
    max-height: 100% !important;
  }
  .str-chat-channel-list.messaging, .str-chat-channel-list.team, .str-chat-channel-list.commerce {
    left: -100vw;
  }
  .str-chat-channel-list--open.messaging, .str-chat-channel-list--open.team, .str-chat-channel-list--open.commerce {
    left: 0
  }
}

@media screen and (max-width: 576.2px) {
  .chat-room-container > .str-chat-channel .str-chat__container {
    border-radius: 0 !important;
  }
}

.str-chat__message-text-inner p {
  color: black !important;
}

.str-chat__input-flat .str-chat__textarea > textarea {
  color: black !important;
}

.str-chat__input-flat-emojiselect svg {
  fill: black !important;
}

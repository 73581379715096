.add-promo-code-button {
  margin-top: auto;
}

.campaign-card-header {
  margin-top: 30px;
  margin-left: 30px;
  text-align: left;
  margin-right: auto;
}

.campaign-card-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.generate-booking-session-detail_date-time-row {
  display: flex;
  flex-direction: row;
}

.generate-booking-session-detail_date-container {
  flex-basis: 34%;
}

.generate-booking-session-detail_time-duration-container {
  display: flex;
  flex-direction: row;
  flex-basis: 66%;
  flex-grow: 1;
}

.generate-booking-session-detail_time {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .generate-booking-session-detail_date-time-row {
    flex-direction: column;
  }
}

.purchase-order-main-page {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

.purchase-order-page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 100px;
}
.purchase-order-page-container td {
  padding: 15px;
  text-align: start;
}
.paginator-container {
  display: flex;
  justify-content: flex-start;
}

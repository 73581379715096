.submit-purchase-order .v1-button {
  height: inherit;
  width: inherit;
}

.submit-purchase-order input {
  border: 1px solid;
  border-color: var(--light-grey);
  border-radius: 5px;
  height: 25px;
  margin: 5px;
}

.transaction-status {
  padding-left: 10px;
}

.transactions-table-row {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  height: 68px;
  border: 1px solid var(--warm-light-grey);
  border-radius: 5px;
  background-color: var(--smooth-ivory);
}

.transactions-table-row th {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.transactions-table-row td > p {
  text-align: center;
  vertical-align: middle;
  padding: 4px;
  padding-top: 14px;
}

.odd-transaction-row {
  border: none;
  background-color: var(--white);
}

.transactions-table-row td {
  text-align: center;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 120px;
}

.transaction-user-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  overflow: visible;
  width: 100%;
}

.transaction-user-info-column > p {
  text-overflow: ellipsis;
  text-align: center;
  padding-left: 10px;
  width: 110px;
  white-space: nowrap;
  overflow: hidden;
  transition: width 0.5s;
}

@media screen and (max-width: 1500px) {
  .transaction-user-info-column > p {
    width: 55px;
  }
}

.transaction-user-info-column {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 1024px) {
  .transactions-table-row td {
    min-width: unset;
  }
}

.add-engineer-service-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
  width: 100%;
  height: fit-content;
}

.add-engineer-service-form-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0px 0px 25px 0px;
}

.add-engineer-service-form-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}

.engineering-service-create-service-button-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: auto;
  margin-left: auto;
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
}

.engineering-service-button {
  min-width: 124px;
}

.engineering-service-create-service-button-container
  > .engineering-service-button:last-child {
  margin-left: var(--project-screen-element-margin);
}

@media screen and (max-width: 768px) {
  .engineering-service-create-service-button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .engineering-service-button {
    margin-left: unset;
    margin-right: 5px;
  }
}

.alt-percentage-slider {
  width: 100%;
}

.add-engineer-service-form-row-input > .location-input {
  flex: 2;
}

.add-engineer-service-form-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0px 0px 25px 0px;
}
.add-engineer-service-form-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0px 0px 25px 0px;
}

.add-engineer-service-form-row-label {
  margin: 10px 0px 10px 0px;
  max-width: 120px;
}

.add-engineer-service-form-row-input {
  width: 75%;
  display: flex;
  justify-content: flex-end;
}

.add-engineer-service-form-row:not(
    :has(.add-engineer-service-form-row-input.alt-percentage-slider)
  )
  > .add-engineer-service-form-row-label {
  max-width: unset;
  width: 100%;
}

.revision-selector {
  width: 80px;
}

.collapsable-remove-calender-integration-container {
  width: 100%;
  margin-bottom: var(--project-screen-element-margin);
  display: flex;
  justify-content: flex-start;
  text-align: left;
}

.calendar-setup-button {
  margin-left: auto;
  margin-right: auto;
}

.add-engineer-service-form-row-label.b3-semi-bold.row-header-options {
  max-width: unset;
}

.row-header-options {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.view-more-options {
  color: var(--dark-grey);
  margin-left: 10px;
  margin-right: 5px;
  cursor: pointer;
}

.discount-options-individual-discount-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
  margin: 0px 0px 25px 0px;
  width: 100%;
}

.discount-options-container {
  display: flex;
  flex-direction: column;
}

.discount-options-individual-discount-container
  > .drop-down-selector-container {
  width: inherit;
}

.add-discount-rates-for-recording-service-container {
  width: 100%;
}

.discount-options-individual-discount-container
  > .add-engineer-service-form-column {
  margin-right: 15px;
}

.discount-options-fixed-range-number-field {
  border-radius: 3px;
  border: 0.75px solid var(--light-grey, #c9c9c9);
  background: var(--foreground-color, #fff);
  color: var(--text-primary-color);
  height: 38px;
  width: 100%;
  padding-left: 15px;
}

.discount-options-prices-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.discount-options-prices-container > .b3-semi-bold {
  margin-right: 15px;
  white-space: pre;
}

.discount-options-delete-button {
  color: var(--dark-grey);
  text-decoration-line: underline;
  cursor: pointer;
}

.discount-options-prices-container > .drop-down-selector-container {
  width: 50px;
}

@media screen and (max-width: 768px) {
  .discount-options-individual-discount-container {
    flex-direction: column;
  }
  .discount-options-prices-container {
    margin-top: 10px;
  }
}

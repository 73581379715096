.video-list {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: scroll;
}

.video-list-item {
  display: flex;
  flex-direction: column;
  padding: 1em;
  background-color: var(--smooth-ivory);
  border-bottom: solid thin var(--light-grey);
}

.video-list-item:hover {
  background-color: var(--light-grey);
  cursor: pointer;
}

.detail-video-view {
  top: 0;
  left: 500px;
  bottom: 0;
  position: absolute;
  background-color: var(--black);
  display: flex;
  z-index: 100;
  transition: left 0.5s ease-in-out;
  width: inherit;
  overflow: hidden;
}

.detail-video-view.show-video {
  left: 0;
}

.close-video-button-detail-video {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background-color: unset;
}

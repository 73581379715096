.unauthenticated-modal {
  transition: all ease 1s;
  flex-direction: column;
  width: 100%;
}

.unauthenticated-modal.card-container.hide-login {
  height: 130px;
}

.unauthenticated-modal.card-container.show-login {
  height: fit-content;
}
.unauthenticated-modal.card-container.show-login.sign-up-form {
  height: fit-content;
}

.unauthenticated-modal.card-container.show-login > .storybook-sign-in-form {
  animation: slideDown 1s;
}

.unauthenticated-modal-header {
  margin-bottom: 20px;
  width: 97%;
}

.unauthenticated-modal-button-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  transition: all ease-in 1s;
}

.unauthenticated-modal-button-container > :nth-child(1) {
  margin-right: 1em;
}

.unauthenticated-modal-button-container.show {
  animation: fadeIn 1s;
  transform: translateX(0%);
}
.unauthenticated-modal-button-container.hide {
  transform: translateX(100%);
  animation: fadeOut 1s;
}

@keyframes slideDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0%);
  }
}

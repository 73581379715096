.calendar-container {
  display: flex;
  justify-content: center;
  max-width: 450px;
  width: 100%;
  margin-bottom: 10px;
}

.react-calendar__navigation__next2-button,
.react-calendar__navigation__prev2-button {
  display: none;
}

.react-calendar__navigation__next-button,
.react-calendar__navigation__prev-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.selected-tiles {
  position: relative;
  z-index: 0;
  background: var(--white) !important;
  color: var(--white) !important;
}

.selected-tiles::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--boomy-orange) !important;
  clip-path: circle(20% at 50% 35%);
}

.date-price {
  display: block;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: var(--dark-grey);
}

.react-calendar {
  max-width: 450px;
  width: 100%;
  font-family: unset;
  line-height: 1.125em;
  border: 0.934581px solid var(--light-grey);
  box-sizing: border-box;
  border-radius: 5px;
}

.calendar-container .react-calendar abbr {
  text-decoration: none !important;
  font-weight: normal;
}

.react-calendar__month-view__weekdays {
  font-weight: normal;
  text-transform: unset;
  color: var(--dark-grey);
}

.react-calendar__navigation__label__labelText {
  font-weight: bold;
}

.react-calendar__tile--now {
  color: var(--black);
}

.react-calendar__tile:disabled {
  color: var(--calendar-disabled-grey-text) !important;
}

.react-calendar__month-view__days__day--weekend {
  color: unset;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: var(--calendar-neighboring-month-grey);
}

.deselect-tile {
  background: var(--white) !important;
  color: unset;
}

.deselect-now-tile {
  background: var(--echo-orange) !important;
}

.react-calendar__tile:enabled:hover {
  background: var(--calendar-hover-tile-grey) !important;
  clip-path: circle(35% at 50% 50%);
}

.react-calendar__tile--active {
  background: unset;
  color: unset;
}

.react-calendar__tile:enabled:focus {
  background: unset;
}

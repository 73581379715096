.subscription-card-container {
  width: 308px;
  height: 100%;
  max-height: 600px;
  box-shadow: 0 0 6px rgba(109, 109, 109, 0.18);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.subscription-card-container.recommended {
  border: 2px solid var(--boomy-orange);
}

.subscription-card-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 22px;
  border-bottom: 0.5px solid var(--dark-grey);
}

.subscription-name-header {
  font-size: 24px;
}

.what-included-subscription-container {
  padding-top: 22px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.benefit-unordered-list {
  display: flex;
  flex-direction: column;
}
.sub-price-wrapper {
  position: relative;
}
.benefit-list-item {
  list-style: outside url("../../assets/check_checklist.svg");
  padding: 10px;
  line-height: 20px;
}
.subscription-price-container {
  display: flex;
  justify-content: center;
  margin-top: auto;
  padding: 22px;
  border-top: 1px solid var(--dark-grey);
}

.subscription-price-section {
  color: var(--dark-grey);
}

.subscription-price-section:before {
  content: "$";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: var(--light-grey);
  line-height: 20px;
  margin-bottom: 20px;
  position: absolute;
  top: 0;
  left: -10px;
  align-self: center;
}

.subscription-price-section:after {
  content: "Per\A month";
  font-style: normal;
  font-weight: normal;
  margin-left: 4px;
  font-size: 14px;
  color: var(--light-grey);
  line-height: 18px;
  white-space: pre;
  position: absolute;
}

.subscribe-button {
  background-color: var(--boomy-orange);
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 49px;
  text-transform: uppercase;
  cursor: pointer;
}

.subscribe-button:hover {
  filter: brightness(75%);
}

.disabled-subscribe-button {
  filter: brightness(65%);
}

.hide-price {
  height: 408px;
}

.hide-price .benefit-unordered-list {
  border-bottom: unset;
}

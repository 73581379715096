.transaction-booking-screen_container {
  padding: 24px;
  box-sizing: border-box;
  max-width: var(--max-screen-width);
  width: 100%;
}

.transaction-booking-screen_header {
  display: flex;
  align-items: center;
  margin-bottom: 48px;
}

.transaction-booking-screen_header-disabled {
  pointer-events: none;
}

.transaction-booking-screen_header-text {
  flex: 1;
  text-align: center;
}

.transaction-booking-screen_header-x {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  background-color: var(--midground-color);
  cursor: pointer;
}

.transaction-booking-screen_content-container {
  display: flex;
  gap: 32px;
}

.transaction-booking-screen_content-container-disabled {
  pointer-events: none;
}

.transaction-booking-screen_content-container-column {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.transaction-booking-screen_booking-details-container {
  margin-bottom: 16px;
}

.transaction-booking-screen_booking-details-header {
  margin-bottom: 16px;
}

.transaction-booking-screen_booking-details-input {
  margin-bottom: 16px;
}

.transaction-booking-screen_select-service-container {
  margin-bottom: 16px;
}

.transaction-booking-screen_select-service-header {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.transaction-booking-screen_select-services-subtext {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--border-color);
}

.transaction-booking-screen_item-container {
  margin-bottom: 32px;
}

/* this width is a little arbitrary, but the components overflow at screens smaller than 970px */
@media screen and (max-width: 970px) {
  .transaction-booking-screen_content-container {
    flex-direction: column;
  }
}

.drop-down-selector-container {
  height: 42px !important;
}

.select-container {
  border-radius: 10px !important;
}

.performance-chart-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
}

.performance-chart-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 16px;
}

.performance-chart-chart-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 84px;
  align-items: flex-start;
  padding-top: 15px;
}

.chart-header-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  max-width: fit-content;
  max-height: 26px;
  height: fit-content;
  align-items: center;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.chart-header-buttons::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.chart-header-buttons-selected.no-data {
  width: 100%;
  height: 100%;
  max-height: 26px;
  padding: 5px;
  border-radius: 5px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  white-space: nowrap;
  background: var(--black);
  cursor: pointer;
  color: var(--white);
}

.chart-header-buttons-selected.no-data
  > .trending-percentage-container
  > .chart-header-button-text {
  color: var(--white);
}

.chart-header-buttons-selected.chart-header-buttons-negative {
  width: 100%;
  height: 100%;
  max-height: 26px;
  padding: 5px;
  border-radius: 5px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  white-space: nowrap;
  background: rgb(231, 188, 188);
  cursor: pointer;
}

.chart-header-buttons-selected {
  width: 100%;
  height: 100%;
  max-height: 26px;
  padding: 5px;
  border-radius: 5px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 3px;
  white-space: nowrap;
  background: #e0efd1;
  cursor: pointer;
}

.chart-header-buttons-unselected {
  width: 100%;
  height: 100%;
  max-height: 26px;
  padding: 5px;
  border-radius: 5px;
  border: 1px var(--medium-grey) solid;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 3px;
  white-space: nowrap;
  background: var(--white);
  cursor: pointer;
}

.profile-visits-coming-soon-tooltip {
  display: inline-flex;
  margin-left: 8px;
}

.chart-header-money {
  height: 28px;
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
}

.current-total {
  color: var(--black);
  font-size: 24px;
  font-family: "Roobert";
  font-weight: 600;
  line-height: 28px;
  word-wrap: break-word;
}

.previous-total {
  color: var(--medium-grey);
  font-size: 12px;
  font-family: "Roobert";
  font-weight: 400;
  line-height: 16px;
  word-wrap: break-word;
  padding-top: 10px;
  padding-left: 5px;
}

.performance-chart-chart {
  width: 100%;
  height: 360px;
}

.chart-navigation-bar {
  width: 100%;
  height: 44px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}



.date-range-dropdown-select-content {
  border: none;
}

.date-range-dropdown-unselected {
  cursor: pointer;
}

.date-range-dropdown-selected {
  outline: var(--black) solid medium;

  @media (max-width: 480px) {
    margin-left: 5px;
  }
}

.chart-navigation-bar-button-selected {
  background: var(--black);
  border-radius: 10px;
  border-color: var(--black);
  width: auto;
  height: 100%;
  max-height: 44px;
  padding: 14px 15px 14px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  color: var(--white);
  font-size: 18px;
  font-family: "Roobert";
  font-weight: 600;
  line-height: 22px;
  word-wrap: break-word;
  cursor: pointer;
}

.chart-navigation-bar-button-unselected {
  background: var(--white);
  border: none;
  width: auto;
  height: 100%;
  max-height: 44px;
  padding: 14px 15px 14px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  color: var(--medium-grey);
  font-size: 18px;
  font-family: "Roobert";
  font-weight: 600;
  line-height: 22px;
  word-wrap: break-word;
  cursor: pointer;
}

.chart-navigation-bar-date-picker.selected {
  background: var(--black);
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 10px;
  border-color: var(--black);
  padding: 7px;
  justify-content: space-between;
}

.chart-navigation-bar-date-picker {
  background: var(--white);
  border: none;
  padding: 7px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  margin-left: 15px;
}

.custom-date-picker.selected {
  width: 60px;
  color: var(--white);
  font-size: 18px;
  font-family: "Roobert";
  font-weight: 600;
  line-height: 22px;
  word-wrap: break-word;
  background: var(--black);
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 5px;
}

.custom-date-picker {
  width: 60px;
  color: var(--medium-grey);
  font-size: 18px;
  font-family: "Roobert";
  font-weight: 600;
  line-height: 22px;
  word-wrap: break-word;
  background: var(--white);
  border: none;
  margin-left: 5px;
}

.custom-date-picker-popper {
  z-index: var(--custom-date-picker-popper-z-index) !important;
}

.chart-visual-data {
  width: 100%;
  height: 100%;
  max-height: 213px;
  margin-top: 5px;
}

.chart-visual-data-title {
  width: 100%;
  height: 100%;
  max-height: 53.5px;
  display: flex;
  flex-direction: row;
}

.chart-visual-data-title > h3 {
  color: var(--black);
  font-size: 14px;
  font-family: "Roobert";
  font-weight: 600;
  line-height: 18px;
  word-wrap: break-word;
  margin-left: 15px;
  margin-top: 15px;
}

.chart-visual-data-line-graph-container {
  width: 100%;
  height: 100%;
  max-height: 213px;
}

.chart-visual-data-footer {
  width: 100%;
  height: 100%;
  max-height: 68px;
}

.chart-visual-data-footer-chart-information {
  width: 100%;
  height: 100%;
  max-height: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.chart-visual-data-footer-chart-information > span {
  color: var(--medium-grey);
  font-size: 12px;
  font-family: "Roobert";
  font-weight: 400;
  line-height: 16px;
  word-wrap: break-word;
}

.chart-visual-data-footer-selections {
  margin-top: 14px;
  height: 100%;
  max-height: 40px;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  align-items: center;
  justify-content: flex-start;
}

.footer-button-selected {
  background: var(--white);
  max-height: 40px;
  border: none;
  padding: 14px 10px 14px 10px;
  display: flex;
  align-items: center;
  margin-right: 5px;
  text-align: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: underline;
}

.footer-button-unselected {
  background: var(--white);
  border: none;
  max-height: 40px;
  padding: 14px 10px 14px 10px;
  display: flex;
  align-items: center;
  margin-right: 5px;
  cursor: pointer;
}

.footer-button-text-selected {
  color: var(--black);
  font-size: 15px;
  font-family: "Roobert";
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
}

.footer-button-text-unselected {
  color: var(--medium-grey);
  font-size: 15px;
  font-family: "Roobert";
  font-weight: 600;
  line-height: 16px;
  padding-left: 5px;
  cursor: pointer;
}

.chart-footer-selection-circle {
  width: 15px;
  height: 15px;
  padding-right: 5px;
}

.performance-chart-add-studio-container {
  background: var(--midground-color);
  max-width: 540px;
  padding: 24px 33px 25px 27px;
  margin-top: 24px;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.performance-chart-add-studio-button {
  font-weight: 600;
  text-decoration-line: underline;
  border: none;
  cursor: pointer;
  margin-bottom: 0px !important;
}

.view-transactions-container {
  background: var(--midground-color);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 12px;
  margin-top: 15px;
  max-height: 82px;
}

.view-transactions-text {
  align-items: center;
  font-weight: 600;
  text-decoration: underline;
  margin-right: 50px;
  cursor: pointer;
  border: none;
}

@media (max-width: 768px) {
  .chart-navigation-bar {
    width: 100%;
    height: 44px;
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .chart-header-buttons {
    width: 100%;
    max-width: none;
    height: 44px;
    display: flex;
    flex-direction: row;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .chart-header-buttons::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
}

@media (max-width: 1400px) {
  .chart-navigation-bar-date-picker {
    padding: 0px;
  }

  .chart-header-buttons-selected,
  .chart-header-buttons-unselected {
    padding: 5px;
  }

  .footer-button-text-selected,
  .footer-button-text-unselected {
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .chart-navigation-bar {
    overflow-x: unset;
    margin-top: 5px;
    justify-content: space-between;
  }

  .chart-navigation-bar > .drop-down-selector-container {
    height: 42px !important;
  }
  .chart-navigation-bar::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  .chart-navigation-bar-date-picker {
    padding: 0px;
  }

  .performance-chart-chart-header {
    height: fit-content;
    margin-bottom: 10px;
  }

  .chart-header-buttons {
    width: 100%;
    max-height: unset;
    height: 50px;
    overflow: unset;
  }

  .chart-header-buttons-selected,
  .chart-header-buttons-unselected {
    padding: 5px;
  }

  .chart-visual-data-footer-selections {
    width: 100%;
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    white-space: nowrap;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .chart-visual-data-footer-selections::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  .footer-button-text-selected,
  .footer-button-text-unselected {
    font-size: 12px;
  }

  .chart-header-buttons > .drop-down-selector-container {
    max-width: 100%;
    height: 42px !important;
  }

  .view-transactions-container {
    min-height: 72px;
  }
}

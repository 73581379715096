.badge {
  padding: 5rem;
}
.badge--img {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: 25px;
  height: 25px;
}

.full-width-badge-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 200px;
  margin-right: var(--project-screen-element-margin);
}

.full-width-badge {
  width: 90%;
  height: 40px;
}

.dashboard-container {
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dashboard-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 64px;
  padding-bottom: 25px;
}

.dashboard-single-column-container {
  width: 100%;
  max-width: 650px;
}

@media (max-width: 576px) {
  .dashboard-single-column-container {
    max-width: 100%;
  }

  .dashboard-header {
    max-width: calc(100vw - 20px);
  }
  .dashboard-cards-mobile-container {
    border-radius: unset;
  }
}

.dashboard-single-column-container > .card-container {
  margin-bottom: 10px;
}

.dashboard-columns-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.dashboard-left-column {
  width: 650px;
}

.dashboard-left-column > .card-container {
  margin-bottom: 25px;
}

.dashboard-right-column {
  width: 650px;
}

.dashboard-right-column > .card-container {
  margin-bottom: 25px;
}

@media (max-width: 1400px) {
  .dashboard-left-column {
    width: 560px;
  }

  .dashboard-right-column {
    width: 560px;
  }
}

.dashboard-upcoming-sessions {
  padding: 0.5rem;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;
}

.dashboard-upcoming-projects {
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
}

.dashboard-reviews-list {
  width: 100%;
}

.dashboard-view-profile-button {
  min-width: 140px;
  margin-left: auto;
}

.dashboard-start-a-project-button {
  margin-left: 10px;
  transition: all ease-in-out 500ms;
  animation: fadeIn 500ms ease-in-out;
}

@media screen and (max-width: 1024px) {
  .dashboard-header {
    max-width: 650px;
  }
}

@media screen and (max-width: 768px) {
  .dashboard-header {
    flex-direction: column;
    height: fit-content;
    align-items: center;
    justify-content: center;
    max-width: calc(100vw - 20px);
  }

  .dashboard-view-profile-button {
    min-width: unset;
    display: none;
  }

  .dashboard-upcoming-projects {
    margin-bottom: 8px;
    display: flex;
    flex-direction: row;
    align-self: center;
    justify-content: center;
    width: calc(100% - 16px);
    padding: 0 8px;
  }
}

.dashboard-start-a-project-button-fixed {
  background-color: var(--background-color);
  -moz-box-shadow: 0 0 6px var(--box-shadow-color);
  -webkit-box-shadow: 0 0 6px var(--box-shadow-color);
  box-shadow: 0 0 6px var(--box-shadow-color);
  padding: 12px 16px;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  left: 0;
  right: 0;
  transition: 0.3s ease;
}

.team-panel-container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.team-panel-scroll-container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.team-panel-info-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 240px;
}

.team-panel-body-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.team-panel-empty-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.team-panel-body-container > .team-member-container:not(:last-child) {
  margin-bottom: 10px;
}

.team-panel-body-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  color: var(--medium-grey);
  margin-bottom: 10px;
  text-transform: uppercase;
}

.team-panel-invite-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  width: 345px;
  height: 100%;
  background-color: var(--white);
  z-index: var(--side-panel-z-index);
}

.team-panel-invite-exit {
  margin-left: auto;
  margin-right: 0;
}

.team-panel-invite-header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.team-panel-invite-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.team-panel-invite-item {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
}

.team-panel-invite-body > .team-panel-invite-item:not(:last-child) {
  margin-bottom: 25px;
}

.team-side-panel-container {
  height: 100%;
  box-sizing: border-box;
  overflow-y: hidden;
  border: unset;
  background: unset;
  max-height: unset;
  padding-top: 15px;
}

.team-side-panel-header-col {
  color: var(--medium-grey);
  white-space: nowrap;
}

.team-panel-body-header > .b3-semi-bold.team-side-panel-header-col {
  margin-right: auto;
  margin-left: auto;
  padding-right: 56px;
}

.team-panel-info-container
  > .b3-semi-bold.team-side-panel-header-col:first-child {
  margin-right: 10px;
}

.team-invite-phone-input {
  background: var(--white);
  padding: 10px;
  border: 0.75px solid var(--light-grey);
  height: 25px;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
}

.team-invite-phone-input.PhoneInput > .PhoneInputInput {
  border: none;
  height: inherit;
}

@media (max-width: 768px) {
  .team-panel-container {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border-radius: unset;
    background: transparent;
    border: unset;
    max-height: unset;
  }
  .team-panel-header {
    margin-bottom: 15px;
  }
  .team-side-panel-container {
    height: 100%;
    box-sizing: border-box;
  }
}

.team-panel-invite-button-footer {
  margin-top: 15px;
}

.pending-team-spacer {
  height: 15px;
}

.nav-profile-content {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.nav-profile-content > hr {
  border: solid 1px var(--horizontal-line-color);
  margin: 0 0.75rem;
}

.profile-row-li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--background-color);
  width: 100%;
  padding: 0.75rem;
  max-width: calc(100% - 1.5rem);
  transition: var(--nav-profile-dropdown-transition--up);
}

.profile-row-li.drop-down-enabled {
  max-height: 100px;
  transition: var(--nav-profile-dropdown-transition--down);
}

.profile-row-li:hover {
  background-color: var(--button-secondary-color--hover) !important;
}

.profile-row-li:hover > * {
  background-color: var(--button-secondary-color--hover) !important;
}

.included-checkmark {
  margin: 15px;
  content: url("../../assets/checkmark_accepted.svg");
}

.included {
  margin: 15px;
}

.plan-feature-card {
  background-color: var(--warm-light-grey);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 200px;
  padding: 10px;
  margin: 10px;
  border-radius: 15px;
}

.subscription-plan-item {
  white-space: pre-line;
}

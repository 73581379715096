.verified-user-container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  cursor: default;
}

.verified-icon-image {
  content: "";
  display: inline-block;
  height: 18px;
  width: 18px;
}

@media screen and (max-width: 600px) {
  .verified-icon-image {
    height: 13px;
    width: 13px;
  }
}

.feedback-form-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 13px;
  background: var(--smooth-ivory);
  max-width: 500px;
  width: 95vw;
  overflow: hidden;
}

.umg-feed-back-form
  > .react-tabs
  > .react-tabs__tab-list
  > .react-tabs__tab--selected:focus:after {
  background: transparent;
  height: 0;
  content: none;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.umg-feed-back-form
  > .react-tabs
  > .react-tabs__tab-list
  > .react-tabs__tab--selected {
  border-bottom: solid 2px var(--black);
}

.umg-feed-back-form {
  height: 100%;
  width: 100%;
}

.umg-feed-back-form > .react-tabs > .react-tabs__tab-list {
  margin-top: 0;
  margin-bottom: 0;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.umg-feed-back-form > .react-tabs .react-tabs__tab-panel--selected {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  margin-bottom: 20px;
  height: 100vh;
  max-height: 390px;
  overflow: hidden;
  color: var(--black);
}

@media screen and (max-width: 768px) {
  .umg-feed-back-form > .react-tabs .react-tabs__tab-panel--selected {
    max-height: 430px;
  }
}

.feedback-form .textfield {
  width: 100%;
}

.faq-link {
  text-decoration: none;
}

.non-umg-form-container {
  padding: 20px;
}

.video-list-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.feedback-form > .textfield {
  margin-bottom: 10px;
}

.feedback-form > .drop-down-selector-container {
  margin-bottom: 10px;
}

.feedback-form > .check-box-container.b2.mt-2 {
  margin-bottom: 10px;
}

.feedback-form > .contact-modal-input.input-text.fade-in-phone-number {
  margin-bottom: 10px;
}

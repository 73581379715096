.engineer-promo-list-item {
  height: 50px;
  min-height: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: solid thin var(--light-grey);
}

.download-promo-assets-link {
  color: var(--black);
  text-decoration: none;
  border-bottom: 1px solid var(--black);
}

.engineer-promo-list-item-column {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: calc(600px / 4);
}

@media screen and (max-width: 1200px) {
  .engineer-promo-list-item-column {
    width: 25%;
    min-width: unset;
  }
}

.engineer-promo-list-item-column > p {
  overflow-x: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

.engineer-promo-list-container {
  width: 100%;
  height: fit-content;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
}
.engineer-promo-list-container.scrollable {
  overflow-y: auto;
  height: 200px;
}

.add-promo-code-section {
  background-color: var(--white);
  width: 100%;
  z-index: 1000;
}

.add-promo-code-section
  .engineer-promo-list-item
  .engineer-promo-list-item-column {
  justify-content: flex-start;
}
.add-promo-code-section .engineer-promo-list-item-column {
  padding-left: var(--project-screen-element-margin);
}

.add-promo-code-section.slide-down {
  bottom: 300px;
}

.no-promo-code-view {
  display: flex;
  width: 100%;
  height: inherit;
  justify-content: center;
  align-items: center;
}

.promo-check-box-column {
  display: flex;
  justify-content: center;
  align-items: center;
}
.promo-check-box-column > .check-box-container {
  margin-left: 0;
  margin-top: 0 !important;
}

.promo-code-discount.textfield {
  border: none;
  color: var(--black);
}
.promo-code-text-field {
  border: none !important;
}

.create-promo-code-button {
  width: 100%;
  height: 50px;
  border-radius: 0;
  border: none;
}

.delete-promocode-button {
  cursor: pointer;
}

.delete-promocode-button > button {
  background-color: inherit;
  border: none;
}

.promo-code-name-container {
  width: 30px;
}

.promo-code-link-icon {
  cursor: pointer;
}

h1,
.h1 {
  font-weight: 600;
  font-size: 74px;
  line-height: 78px;
  /* identical to box height, or 105% */
  letter-spacing: -0.02em;
  margin: 0px;
}
@media (max-width: 768px) {
  h1,
  .h1 {
    font-size: 40px;
    line-height: 44px;
  }
}

h2,
.h2 {
  font-weight: 600;
  font-size: 56px;
  line-height: 58px;
  /* identical to box height, or 104% */

  letter-spacing: -0.02em;
  margin: 0px;
}
@media (max-width: 768px) {
  h2,
  .h2 {
    font-weight: normal;
    font-size: 30px;
    line-height: 34px;
  }
  .h2-semi-bold {
    font-weight: 600;
    font-size: 36px;
    line-height: 34px;
  }
}

h3,
.h3 {
  font-weight: 600;
  font-size: 36px;
  line-height: 40px;
  /* identical to box height, or 111% */

  letter-spacing: -0.02em;
  margin: 0px;
}
@media (max-width: 768px) {
  h3,
  .h3 {
    font-size: 24px;
    line-height: 28px;
  }
}

h4,
.h4 {
  font-weight: 600;
  font-size: 30px;
  line-height: 34px;
  /* identical to box height, or 113% */

  letter-spacing: -0.02em;
  margin: 0px;
}
@media (max-width: 768px) {
  h4,
  .h4 {
    font-size: 18px;
    line-height: 22px;
  }
}

h5,
.h5 {
  font-weight: normal;
  font-size: 30px;
  line-height: 34px;
  margin: 0px;
}
@media (max-width: 768px) {
  h5,
  .h5 {
    font-size: 24px;
    line-height: 28px;
  }
}

.h6-semi-bold {
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
}

h6,
.h6 {
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  /* identical to box height, or 117% */

  letter-spacing: -0.02em;
  margin: 0px;
}
@media (max-width: 768px) {
  h6,
  .h6 {
    font-size: 18px;
    line-height: 22px;
  }
}

.h7-semi-bold {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height, or 122% */

  letter-spacing: -0.02em;
  margin: 0px;
}

.h7 {
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height, or 122% */

  letter-spacing: -0.02em;
  margin: 0px;
}
@media (max-width: 768px) {
  .h7 {
    font-size: 16px;
    line-height: 20px;
  }
}

.h8-semi-bold {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.02em;
  margin: 0px;
}

p {
  margin: 0;
}

/* Body text */
.b1 {
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
}
@media (max-width: 768px) {
  .b1 {
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
  }
}

.b1-semi-bold {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */

  letter-spacing: -0.02em;
}
@media (max-width: 768px) {
  .b1-semi-bold {
    font-size: 12px;
    line-height: 15px;
  }
}

.b2 {
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
}
@media (max-width: 768px) {
  .b2 {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
  }
}

.b2-semi-bold {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
}
@media (max-width: 768px) {
  .b2-semi-bold {
    font-size: 14px;
    line-height: 18px;
  }
}

.b3 {
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
}
@media (max-width: 768px) {
  .b3 {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
  }
}

.b3-semi-bold {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}
@media (max-width: 768px) {
  .b3-semi-bold {
    font-size: 12px;
    line-height: 16px;
  }
}

.b4 {
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
}
@media (max-width: 768px) {
  .b4 {
    font-weight: normal;
    font-size: 10px;
    line-height: 14px;
  }
}
/* Labels */

.label {
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .label {
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
  }
}

.label-semi-bold {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height, or 129% */

  letter-spacing: 0.04em;
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .label-semi-bold {
    font-size: 14px;
    line-height: 18px;
  }
}

.label2 {
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  letter-spacing: 0.04em;
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .label2 {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
  }
}

.label2-semi-bold {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  letter-spacing: 0.04em;
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .label2-semi-bold {
    font-size: 12px;
    line-height: 16px;
  }
}

.label-button {
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height, or 129% */

  letter-spacing: 0.04em;
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .label-button {
    font-size: 12px;
    line-height: 18px;
  }
}

/* captions / hyperlinks */

.caption {
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  /* identical to box height, or 140% */

  letter-spacing: 0.04em;
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .caption {
    font-size: 10px;
    line-height: 14px;
  }
}

.caption-semi-bold {
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  /* identical to box height, or 140% */

  letter-spacing: 0.04em;
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .caption-semi-bold {
    font-size: 10px;
    line-height: 14px;
  }
}

.semi-bold-ul {
  font-weight: 600;
  font-size: 60px;
  line-height: 64px;
  letter-spacing: -0.02em;
}

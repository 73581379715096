.user-profile-container {
  display: flex;
  color: var(--black);
  align-items: center;
  line-height: 20px;
}

.user-profile-data {
  margin-left: 12px;
}

.user-profile-display-name {
  font-size: 16px;
  font-weight: 600;
}

.user-profile-time-on-platform {
  font-size: 14px;
} 
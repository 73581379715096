.share-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 4px;
  border-radius: var(--border-radius-md);
  background-color: var(--smooth-ivory);
  max-height: 36px;
  max-width: 91px;
  height: 36px;
  position: relative;
  color: var(--black);
  width: 100%;
  padding: 8px 12px;
  cursor: pointer;
  border: none;
  outline: none;
}

.fa-icon {
  color: var(--black);
}

@media screen and (max-width: 768px) {
  .share-button-container {
    z-index: 1;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    padding: unset;
  }
  .share-button-container > p {
    margin-right: 0px;
  }
}

.select-time-drop-down-container {
  display: flex;
  flex-direction: row;
}

.select-time-drop-down-container > .drop-down-selector-container:nth-child(1) {
  margin-right: 10px;
  flex: 7;
}

.select-time-drop-down-container > .drop-down-selector-container:nth-child(2) {
  margin-right: 10px;
  flex: 3;
}

.submit-available-hours-button {
  width: 100%;
}

.select-working-hours-component-container {
  display: flex;
  flex-direction: column;
  width: 80%;
  align-self: center;
  margin-left: auto;
  margin-right: auto;
  padding-top: var(--header-height);
}

.back-arrow {
  display: flex;
  flex-direction: row;
  position: relative;
  padding: auto;
}

.back-arrow > .left-arrow-button {
  left: 0;
  top: 0px;
  width: 20%;
}

.purchase-order-request-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}
.budget-manager-purchase-order-request-view > .purchase-order-request-view {
  padding: 0;
}

.purchase-order-request-view.has-back-drop {
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0.93;
}

.purchase-order-form-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.purchase-order-form-container .v1-button {
  width: 100%;
}

.purchase-order-form-container > .editable-text-field {
  width: 100%;
}

.purchase-order-admin-email-field.textfield,
.update-admin-emails-button {
  width: 100%;
}

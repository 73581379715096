.rc-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px !important;
}

.rc-pagination-item {
  border: none !important;
  border-radius: var(--border-radius-xs) !important;
  font-family: "Roobert";
  font-size: 16px;
  font-weight: 600;
  padding: 0;
  margin: 0;
}

.rc-pagination-item-active {
  text-decoration: none !important;
  color: var(--white-0) !important;
  background-color: var(--primary-color) !important;
}

.rc-pagination-item a {
  color: var(--text-tertiary-color) !important;

  &:hover {
    color: var(--text-primary-color) !important;
  }
}

.rc-pagination-item-active a {
  color: var(--white-0) !important;

  &:hover {
    color: var(--white-0) !important;
  }
}

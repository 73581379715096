.more-options-button {
  cursor: pointer;
  color: grey;
  background: none;
  border: none;
}

.subscription-selection-section {
  display: flex;
  width: 100%;
  justify-content: center;
}

.subscription-selection-section > * {
  margin-right: var(--project-screen-element-margin);
  margin-left: var(--project-screen-element-margin);
}

.select-basic-modal {
  display: flex;
  padding: 10px;
  align-items: center;
  flex-direction: column;
  width: 500px;
  height: 250px;
  background: var(--white);
  border-radius: 15px;
}
.subscription-selection-section > .row {
  margin-top: var(--project-screen-element-margin);
}

.select-basic-modal-footer {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.select-basic-modal-footer > * {
  margin-right: 1em;
}
.subscription-selection-section > .subscription-card-container {
  margin-top: var(--project-screen-element-margin);
  margin-bottom: var(--project-screen-element-margin);
}

@media screen and (max-width: 768px) {
  .subscription-selection-section {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .subscription-selection-section > * {
    margin-bottom: 1em;
  }
}

.studio-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100vw !important;
  margin-right: 30px;
  margin-left: 30px;
}

.studio-header-metadata {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 30px;
  margin-top: 5px;
}

.studio-header-metadata-child {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.studio-header-metadata-action-btns {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 8px;
}

.studio-hero-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-height: 460px;
  position: relative;
  overflow: hidden;
}

.studio-hero-section .image-carousel-container {
  max-height: 460px;
  width: 100vw;
}

.studio-map-section {
  margin-bottom: 60px;
}

.studio-hero-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.studio-row.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  text-align: center;
  margin-top: 60px;
  margin-bottom: 30px;
}
@media (max-width: 768px) {
  .studio-row.header {
    margin-top: 30px;
  }
}

.studio-split-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}
.studio-split-row-column {
  flex: 1;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 540px;
}
.studio-split-row-column:first-child {
  margin-right: 1em;
}

@media screen and (max-width: 992px) {
  .studio-split-row-column {
    max-width: 100%;
  }
  .studio-split-row-column:first-child {
    margin-right: 0;
  }
}

.studio-split-row-column-row {
  flex: 1;
  display: flex;
  margin: 10px;
  flex-direction: column;
  justify-content: flex-start;
}

.studio-room-screen {
  margin-top: 60px;
}

.studio-divider,
.studio-screen-divider {
  height: 1px;
  width: 100%;
  background-color: var(--calendar-hover-tile-grey);
}
@media screen and (max-width: 768px) {
  .studio-divider {
    display: none;
  }

  .studio-room-screen {
    margin-top: 30px;
  }

  .studio-header-metadata {
    margin-left: 8px;
    margin-right: 8px;
  }
}

@media screen and (max-width: 992px) {
  .studio-split-row {
    flex-direction: column;
  }
  .studio-split-row-column:first-child
    > .studio-split-row-column-row:nth-child(2) {
    display: none;
  }
  .studio-split-row-column:nth-child(2)
    > .studio-split-row-column-row:last-child {
    display: flex;
  }
}

.studio-name-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 15px;
}

.studio-room-recording-service-button {
  width: 100%;
  text-align: center;
}

.studio-screen-subheader-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 6px;
  position: relative;
}
@media (max-width: 768px) {
  .studio-screen-subheader-row {
    flex-direction: column;
    align-items: flex-start;
  }
}

.studio-screen-subheader-row-left {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  margin-bottom: 15px;
}

@media (max-width: 768px) {
  .studio-screen-subheader-row-left {
    flex-direction: column;
  }

  .studio-screen-subheader-row-left > .trophy-list-item {
    margin-left: 0;
  }
}

.studio-screen-subheader-row-left > .trophy-list-item {
  width: 200px;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  margin-left: 0;
  animation: fadeIn 0.5s ease-in-out;
}

@media (max-width: 768px) {
  .studio-screen-subheader-row > .trophy-list-item {
    margin-top: 10px !important;
    margin-bottom: 0px;
    margin-left: 0px;
  }
}

.studio-screen-subheader-row > .trophy-list-item > .trophy-list-text {
  margin-left: 5px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.02em;
}

.studio-screen-subheader-row-left > .trophy-list-item > .trophy-list-text {
  margin-left: 5px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.02em;
}
.studio-screen-subheader-row-left
  > .trophy-list-item
  > .badge--medium
  > .badge--img {
  width: 20px;
  height: 20px;
}

.edit-studio-button {
  cursor: pointer;
  color: var(--black);
}

.studio-section-header {
  margin-bottom: 5px;
  color: var(--medium-grey);
  text-transform: uppercase;
}

.studio-facilities-section {
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  .studio-facilities-section {
    margin-bottom: 15px;
  }
}

.studio-location-text {
  margin-right: 8px !important;
}

.studio-row-header-subtitle {
  margin-top: 10px;
}

.benefit-item:last-of-type {
  margin-bottom: 0px !important;
}

.studio-map-section {
  margin-bottom: 60px;
}

.bread-crumb-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
  margin-bottom: 10px;
  margin-left: 6px;
}

.bread-crumb-container > .back-button {
  margin-right: 10px;
  margin-left: 10px;
}

.bread-crumb-link {
  color: var(--medium-grey);
  text-decoration: none;
}
.bread-crumb-link:visited {
  color: var(--medium-grey);
}
.bread-crumb-link.selected-link {
  text-decoration: underline;
}

.map-display-container {
  border-radius: 8px;
  display: flex;
  margin-bottom: 30px;
  height: 275px;
}

.studio-name-header-left {
  flex: 1;
  display: flex;
  align-items: center;

  .edit-studio-button {
    margin-left: 8px;
  }
}

.studio-screen-fixed-footer-button {
  align-self: center;
  border-radius: 0;
}

.studio-screen-button-container-fixed > button {
  @media (max-width: 768px) {
    flex: 1;
  }
}

.studio-screen-book-now-button {
  font-weight: 600;
  font-size: 14px;
  margin-left: 8px;
}

.studio-screen-fixed-footer-button-styles {
  @media (max-width: 768px) {
    margin-left: 0;
    width: 100vw;
    border-radius: 0 !important;
    height: 50px;
  }
}

.studio-screen-location {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.studio-screen-reviews-container {
  margin-bottom: 60px;
}

@media (max-width: 768px) {
  .studio-screen {
    margin-right: 8px;
    margin-left: 8px;
  }

  .main-screen-share-button {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

  .main-screen-share-button > :last-child {
    margin-left: 10px;
    margin-right: 10px;
  }
  .map-display-container {
    width: 95%;
    align-self: center;
    height: 195px;
  }
  .studio-screen-location {
    padding-right: 15px;
    padding-left: 15px;
  }
  .studio-screen-reviews-container {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.studio-screen-message-button {
  font-size: 14px;
}
.studio-screen-message-button.sticky {
  width: 100%;
}

.studio-screen-location > .b1 {
  color: var(--medium-grey);
}

.studio-subheader-badges-container {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  margin-top: 8px;
}

.studio-subheader-badges-container .badge--img {
  width: 16px;
  height: 16px;
}

.studio-subheader-badges-container__vertical-separator {
  width: 1px;
  align-self: stretch;
  background-color: var(--light-grey);
  margin: 2px 8px;
}

.studio-subheader-badges-container .trophy-texts,
.studio-split-row-column-row .trophy-texts {
  align-self: center;
}

.studio-subheader-badges-container .trophy-list-item {
  width: unset;
  margin: unset;
}

.studio-subheader-badges-container .trophy-list-text {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 0;
}

.studio-split-row-column-row .trophy-list-item {
  margin-left: 0;
  animation: fadeIn 0.5s ease-in-out;
}

.studio-split-row-column-row .trophy-list-text {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.02em;
  padding-left: 0;
}

.studio-screen-trophy-divider {
  border-right: 1px solid var(--light-grey);
  display: flex;
  height: 20px;
  margin-right: 10px;
  margin-bottom: 3px;
}

.studio-screen-subheader-subrow-left {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  height: 30px;
  margin-bottom: 30px;
}

.studio-review-img {
  transform: translateY(1px);
}

@media (max-width: 768px) {
  .studio-review-img {
    transform: unset;
  }
}


.studio-split-row-column-row .direct-message-button {
  width: auto;
  padding-left: 33px;
  padding-right: 33px;
}

.studio-reviews-card {
  max-width: var(--max-screen-width);
}

.studio-room-reviews-card {
  max-width: var(--max-screen-width);
  margin-bottom: 30px;
}

.drop-down-selector-container {
  outline: none;
  box-shadow: none;
  min-height: 40px !important;
  height: 40px !important;
  border-radius: 5px;
  width: 100%;
  display: flex;
  min-width: 100px;
}

.drop-down-select-content {
  position: relative;
  width: 100%;
}
.select-container {
  margin: 0;
  color: var(--dark-grey);
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  border-radius: 5px;
  height: 100%;
  outline: none;
  border: solid thin var(--light-grey);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.select-arrow-container {
  position: absolute;
  right: 0;
  width: 40px;
  height: 60%;
  border-left: solid thin var(--light-grey);
  display: flex;
  justify-content: center;
  align-items: center;
}

.select-container > p {
  margin-left: 10px;
  color: var(--dark-grey);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 80%;
}
.select-container:hover {
  border-color: var(--light-grey);
}

.select-container-active {
  border-color: var(--sky-light-blue);
}

.drop-down-select-content > .select-container:focus {
  outline: none;
}

.select-dropdown-menu {
  border: solid thin var(--light-grey);
  position: relative;
  z-index: 1000;
  border-radius: 5px;
  background-color: var(--white);
  overflow: hidden;
  overflow-y: auto;
  max-height: 180px;
}

.select-dropdown-menu.show-select-menu {
  display: block;
  margin-top: 10px;
}

.select-dropdown-menu.hide-select-menu {
  display: none;
}

.drop-down-option {
  min-height: 40px;
  background-color: var(--white);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 20px;
}

.drop-down-slector-loading-indicator {
  position: absolute;
  right: 5px;
  bottom: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.drop-down-selected-clear-search {
  position: absolute;
  right: 40px;
  width: 25px;
  height: 60%;
  display: flex;
  opacity: 0.5;
  justify-content: center;
  align-items: center;
}

.drop-down-option-active {
  background-color: var(--timbre-tan);
}

/* Style for currently active option, while the cursor NOT hovering over the dropdown */
.select-dropdown-menu:not(:hover) {
  .drop-down-option-active:not(:hover) > p {
    color: var(--black-no-dark-theme);
  }
}

.select-dropdown-menu:hover > .drop-down-option-active {
  background-color: unset;
}

.drop-down-option:hover {
  cursor: pointer;
  background-color: var(--timbre-tan) !important;
}

.drop-down-option:hover > p {
  color: black !important;
}

.select-container-search-input {
  outline: none;
  border: none;
  margin-left: 10px;
  background-color: transparent;
  color: var(--black);
  width: calc(100% - 80px);
}

.select-container-search-input:placeholder-shown {
  text-overflow: ellipsis;
}

.search-selector-pill {
  background-color: var(--mint-green);
  display: inline-block;
  border-radius: 14px;
  height: 20px;
  padding: 4px 14px;
  color: var(--black);
}

.single-value-pill-container {
  position: absolute;
  left: 10px;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  right: 100px;
  background-color: inherit;
  pointer-events: none;
}

.hide-select-container-search-input {
  opacity: 0;
}

.drop-down-no-options-message > p {
  color: var(--medium-grey);
}

.textfield {
  width: 100%;
  height: 45px;
  background: var(--white);
  padding: 10px;
  border: 0.75px solid var(--light-grey);
  box-sizing: border-box;
  border-radius: 8px;
}

.custom-textfield {
  border: 1px solid black;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 2px 10px 2px 10px;
  outline: none;
  width: 100%;
}

.custom-textfield-container {
  display: flex;
  flex-direction: column;

}

.custom-textfield-label {
  font-size: 12px;
  font-weight: 600;
  color: var(--text-field-label-black);
}

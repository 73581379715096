.star-rating {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: var(--light-grey);
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  margin-left: 25px;
  transform: translateX(-20%);
}

.star-rating.mobile {
  flex-direction: row;
  margin-top: 5px;
  margin-left: auto;
  margin-right: auto;
  transform: translateX(0%);
}

.star-on {
  color: var(--boomy-orange);
  width: 24.28px;
  height: 24.28px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.star-rating-text {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin-left: 100px;
  color: var(--light-grey);
}

.star-rating-text.mobile {
  font-size: 10px;
  margin-bottom: 5px;
  margin-left: 0;
}

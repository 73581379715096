.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.toggle-switch-small {
  width: 26px;
  height: 14px;
  min-width: unset !important;
}

.toggle-switch-medium {
  width: 36px;
  height: 16px;
  min-width: unset !important;
}

/* Hide default HTML checkbox */
.switch > input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--light-grey);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider.disabled {
  cursor: default;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: var(--white);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.toggle-slider-small:before {
  left: 2px;
  height: 10px;
  width: 10px;
  bottom: 2px;
}

.toggle-slider-medium:before {
  left: 2px;
  height: 12px;
  width: 12px;
  bottom: 2px;
}

.switch > input:checked + .slider {
  background-color: var(--boomy-orange);
}

.switch > input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

.switch > input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.toggle-switch-small > input:checked + .toggle-slider-small:before {
  -webkit-transform: translateX(12px);
  -ms-transform: translateX(12px);
  transform: translateX(12px);
}

.toggle-switch-medium > input:checked + .toggle-slider-medium:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.switch.toggle-switch-background-color-disabled > input:checked + .slider {
  background-color: var(--light-grey);
}

.home-view-link {
  display: flex;
  text-decoration: none;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  cursor: pointer;
}

.home-view-link h4 {
  font-weight: 400;
}

.home-view-list-item {
  list-style-type: none;
}

.customer-portal-item-icon {
  height: 36px;
  width: 36px;
}

.customer-portal-title {
  margin-left: 10px;
  text-decoration: none;
  color: var(--black);
  cursor: pointer;
}

.recording-detail-mobile-menu {
    height: fit-content !important;
    min-height: 40%;    
    pointer-events: auto;
    background-color: var(--smooth-ivory) !important;
}

.recording-detail-mobile-menu-container > div {
    padding: 12px;
    width: 100%;
}

.recording-detail-mobile-menu-container > div:hover {
    background-color: var(--timbre-tan);
}

.selected-option-text {
    color: var(--boomy-orange);
}

.recording-detail-mobile-menu-container > header {
    position: relative;
    text-align: center;
    width: 100%;
    margin-bottom: 30px;
}

.recording-detail-mobile-menu-container > header > :not(:first-child) {
    position: absolute;
    right: 10px;
    top: 0;
}

.recording-detail-mobile-menu .drop-down-option {
    padding: 0 !important;
    text-transform: capitalize;
}

.engineer-selected.drop-down-option  .select-tracking-engineer-option span {
    color: var(--boomy-orange);
    text-transform: capitalize;
}
.v1-button {
  border: solid 1px var(--text-primary-color);
  border-radius: var(--border-radius-md);
  color: var(--text-primary-color);
  cursor: pointer;
  display: inline-block;
  font-weight: 400;
  line-height: 1;
  padding: var(--button-padding);
  width: 157px;
  height: 44px;
  text-decoration: none;
  text-transform: uppercase;
  transition: all ease-in-out var(--button-transition-duration);
}

.v1-button:disabled {
  opacity: 0.8;
  cursor: not-allowed;
}

.v1-button:hover:enabled {
  filter: brightness(85%);
}

.v1-button-primary {
  color: var(--black-0);
  background-color: var(--button-primary-color);
  border: none;
  cursor: pointer;
}
.v1-button-secondary {
  background-color: var(--button-secondary-color);
  cursor: pointer;
}
.v1-button--small {
  font-size: 0.75rem;
  padding: 10px 16px;
  border-radius: 6px;
}
.v1-button--medium {
  font-size: 0.7rem;
}
.v1-button--large {
  width: 250px;
  font-size: 1rem;
  padding: 12px 24px;
}

.v1-button-gradient {
  background: linear-gradient(
    88.08deg,
    var(--echo-orange) 0%,
    var(--boomy-orange) 100%
  );
  color: var(--black-no-dark-theme);
}

.v1-button-disabled-text-container {
  position: absolute;
  height: fit-content;
  width: 10%;
}

.v1-button-disabled-text {
  background-color: var(--stereo-black);
  color: var(--warm-light-grey);
  border-radius: 10px;
  padding: 10px;


}
@media screen and (max-width: 900px) {
  .version-one-button {
    width: 80px;
  }
  .version-one-button--medium {
    font-size: 0.7rem;
    padding: 7px 15px;
  }
  .v1-button-disabled-text-container {
    width: 25%;
  }
}

.country-selector-div {
  max-width: 300px;
  min-width: 165px;
  width: 100%;
  flex-direction: column;
}

.setup-stepper-container {
  width: 50%;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
}

.stripe-setup-redirect-container {
  padding: 1rem;
  box-sizing: border-box;
}
.dashboard-number-card {
  min-height: 224px;
  height: fit-content;
  padding: 0 16px;
  flex: 1;
  min-width: 100px;
}

.dashboard-number-card > div {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  width: 100%;
}
@media (max-width: 768px) {
  .dashboard-number-card {
    width: 85px;
    height: 95px;
    min-width: unset;
    min-height: unset;
  }
}

.dashboard-number {
  color: var(--boomy-orange);
  margin-top: 24px;
  margin-bottom: 0;
}

/* .dashboard-number.animating-fade-in-number {
  animation:
    pulse 1s,
    expand 500ms ease-in-out;
} */

@media (max-width: 768px) {
  .dashboard-number {
    margin-top: 16px;
  }
}

.dashboard-number-card > .card-container {
  flex-direction: column;
}

.view-projects-button {
  font-size: var(--text-size-sm);
  margin-top: 24px;
  height: 30px;
}
@media (max-width: 768px) {
  .view-projects-button {
    display: none;
  }
}

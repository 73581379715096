.nav-profile-menu {
  background-color: var(--background-color);
  border-radius: var(--border-radius-sm) var(--border-radius-sm) 0 0;
  transition: all 0.5s;
  cursor: pointer;
  z-index: var(--nav-profile-menu-z-index);
}

.nav-profile-menu.drop-down-enabled {
  -moz-box-shadow: 0 4px 4px 0 var(--box-shadow-color);
  -webkit-box-shadow: 0 4px 4px 0 var(--box-shadow-color);
  box-shadow: 0 4px 4px 0 var(--box-shadow-color);
}
.profile-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: var(--background-color);
  width: 100%;
  cursor: pointer;
}

.profile-row:hover {
  background-color: var(--button-secondary-color--hover);
}

.profile-row-right {
  display: flex;
  align-items: center;
}

.profile-row__image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-row__image > img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: solid thin var(--gray-100);
  margin-right: 0.5rem;
}

.profile-row__info__name {
  display: block;
  height: 100%;
  width: 100%;
  max-width: 115px;
}

.profile-row-checkbox {
  margin-top: 0 !important;
}

@media (max-width: 480px) {
  .profile-row__info__name {
    max-width: 140px;
  }
}

.profile-row__info__name > p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: start;
  text-decoration: none;
}

.profile-row__info__selected {
  margin-left: 0.5rem;
}

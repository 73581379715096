.cart-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: center;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: var(--project-screen-element-margin);
  max-width: 450px;
  width: 100%;
}

.reschedule-button {
  width: 100%;
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
}

.reschedule-button-container {
  width: 100%;
  max-width: 450px;
  display: flex;
  margin-left: auto;
  flex-direction: row;
  padding-right: var(--project-screen-element-margin);
  justify-content: space-evenly;
}

.reschedule-button-confirm-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-evenly;
}

@media screen and (max-width: 768px) {
  .reschedule-button-container {
    width: 100%;
    padding-left: unset;
    padding-right: unset;
    max-width: unset;
    justify-content: space-evenly;
  }
  
}
.nav-login-container {
  display: flex;
  width: 100%;
}

.nav-login-container--column {
  flex-direction: column;
  padding: 0 8px;
  width: calc(100% - 16px);
}

.nav-login-button {
  min-width: 108px;
}

.nav-link-secondary {
  margin: 0 8px;
}

.nav-link-secondary--column {
  margin-bottom: 12px;
}

.mp4-column {
  width: 90%;
  align-self: center;
  margin-top: var(--project-screen-element-margin);
}
.mp4-playback-row {
  padding: var(--project-screen-element-margin);
  padding-left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 350px;
}

.mp4-download-button {
  margin-right: var(--project-screen-element-margin);
}

.audio-playback-mp4 {
  width: 90%;
  height: 44px;
}

.error-lottie-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 210px;
  align-items: center;
  margin-top: 1em;
  margin-bottom: 1em;
}
.dolby-atmos-title {
  color: var(--stereo-black);
  display: flex;
  justify-content: center;
  font-size: 30px;
}

.error-message-device-not-supported {
  background-color: var(--light-grey);
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .mp4-playback-row {
    margin-top: 300px;
  }
}

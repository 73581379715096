.remove-icon-animation {
  animation: fadeIn 1s;
  width: unset;
}

.delete-icon {
  height: 0;
  background-color: var(--white);
  position: relative;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: calc(25px / 2);
  transition: all ease-in-out 300ms;
}

.delete-icon:hover {
  cursor: pointer;
  transform: scale(1.5);
}

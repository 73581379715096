.transaction-booking-service-type-dropdown_select-container {
  border: 1px solid var(--text-primary-color);
  color: var(--text-primary-color);
}

.transaction-booking-service-type-dropdown_select-container:hover {
  border-color: var(--text-primary-color);
}

.transaction-booking-service-type-dropdown_label-text {
  color: var(--text-primary-color) !important;
}

.transaction-booking-service-type-dropdown_arrow-container {
  border-left: none !important;
}

.transaction-booking-service-type-dropdown_container {
  max-width: 250px;
}

.studio-room-hosted-by-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.studio-room-hosted-by-name:hover {
  cursor: pointer;
  text-decoration: underline;
}

.studio-room-hosted-by-description {
  color: var(--dark-grey);
}

.studio-room-hosted-by-studio-img {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  object-fit: cover;
}

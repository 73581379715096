.muso-card-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 281px;
  max-width: 243px;
  width: 100%;
  border-radius: 12px;
  background-color: var(--smooth-ivory);
}

.muso-card-button {
  width: 100%;
  max-width: 200px;
  border: 1px solid;
}

.muso-card-icon {
  padding-bottom: 20px;
}

.muso-card-button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
}

@media screen and (max-width: 480px) {
  .muso-card-container {
    height: 200px;
    max-width: unset;
  }
}

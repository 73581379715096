.trending-profile-card-container {
  width: 285px;
  min-height: 269px;
  max-height: 302px;
  height: fit-content;
  position: relative;
  cursor: pointer;
}

.trending-profile-image-container {
  width: inherit;
  height: 160px;
  border-radius: 10px;
  object-fit: cover;
  margin-bottom: 5px;
}

.trending-profile-location-text {
  color: var(--medium-grey);
}

.trending-profile-badge-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-height: 32px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.trending-profile-badge-container > .trophy-list-item {
  width: fit-content;
  margin-bottom: unset;
  margin-left: unset;
  margin-right: 5px;
}

.trending-profile-name {
  color: var(--black);
}
.trending-profile-info-container {
  width: 285px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.trending-discipline-text-container {
  width: fit-content;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  color: var(--dark-grey);
  background-color: var(--warm-light-grey);
  border-radius: 100px;
  padding: 2px 10px;
}

.trending-profile-badge-container > .trophy-list-item > .b3.trophy-list-text {
  margin-left: 5px;
  width: fit-content;
  flex-wrap: wrap;
}

.trending-profile-badge-container > .badge--small > .badge--img {
  transform: unset;
}

.trending-profile-credits {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: var(--black);
}

.trending-profile-image-icon-container {
  width: calc(100% - 30px);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-top: 15px;
  padding-left: 15px;
  border-radius: 10px;
  position: absolute;
}

.trending-dollar-sign-container {
  min-height: 13px;
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 5px 5px 5px;
  background-color: var(--white-no-dark-theme);
  border-radius: 5px;
  flex-shrink: 0;
}

.trending-profile-link-container {
  text-decoration: unset;
}

.trending-profile-image-icon-container > .trending-dollar-sign-container {
  margin-right: 10px;
}

.trending-dollar-sign-container > .money {
  font-size: 12px;
  line-height: 12px;
}

.trending-dollar-sign-container > .money > .stereoblack {
  color: var(--black-no-dark-theme);
}

.trending-dollar-sign-container > .money > .lightgrey {
  color: var(--light-grey-no-dark-theme);
}

.trending-profile-rate-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
}

.trending-dollar-sign-container > .money {
  font-weight: 600;
}

.trending-profile-badge-container
  > .trophy-list-item
  > .badge--medium.badge-component
  > .badge--img {
  height: 20px;
  width: 20px;
}
.trending-profile-image-icon-container > .review-aggregate-container {
  margin-right: 10px;
}

.trending-favorite-container {
  justify-self: flex-end;
  margin-left: auto;
  right: 0;
}

.trending-profile-image-icon-container
  > .badge--medium.badge-component
  > .badge--img {
  height: 20px;
  width: 20px;
}

@media screen and (max-width: 768px) {
  .trending-profile-card-container {
    width: 345px;
    min-height: 302px;
  }
  .trending-profile-image-container {
    height: 194px;
  }
}

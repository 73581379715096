.order-breakdown-container {
  width: 100%;
}

.order-breakdown-table-body-loading {
  opacity: 0.4;
}

.order-breakdown-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.order-breakdown-header-container {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  justify-content: space-between;
}

.order-breakdown-header-switches {
  display: flex;

  .tooltip:first-of-type {
    margin-right: 15px;
  }
}

@media (max-width: 768px) {
  .order-breakdown-header-container {
    align-items: flex-start;
    flex-direction: column;
  }

  .order-breakdown-header-switches {
    margin-top: 10px;
  }
}

.order-breakdown-table {
  background-color: var(--order-breakdown-background-color);
  border-collapse: collapse;
  min-height: 60px;
  width: 100%;
  outline: solid 1px var(--order-breakdown-border-color);
  border-radius: 8px;
  color: var(--text-primary-color);
  position: relative;
}

.order-breakdown-table th,
.order-breakdown-table td {
  padding: 8px 1px;
  color: var(--text-primary-color);
}

.order-breakdown-table hr {
  width: 100%;
  color: var(--text-primary-color);
}

.order-breakdown-table th {
  padding-top: 12px;
}

/* header first column */
.order-breakdown-table th:first-of-type {
  padding-left: 16px;
  text-align: left;
  width: 45%;
}

/* header second column */
.order-breakdown-table th:nth-of-type(2) {
  text-align: center;
}

/* header third column */
.order-breakdown-table th:nth-of-type(3) {
  text-align: left;
}
/* header last column */
.order-breakdown-table th:last-of-type {
  padding-right: 16px;
  text-align: right;
}

/* row first column */
.order-breakdown-table tr td:first-of-type {
  padding-left: 16px;
}

/* row second column */
.order-breakdown-table tr td:nth-of-type(2) {
  text-align: center;
}

/* row third column */
.order-breakdown-table tr td:nth-of-type(3) {
  text-align: left;
}

/* row last column */
.order-breakdown-table tr td:last-of-type {
  padding-right: 16px;
  text-align: right;
}

/* last row */
.order-breakdown-table tr:last-of-type td {
  padding-top: 10px;
  padding-bottom: 12px;
}

.order-breakdown-table-footer {
  background-color: var(--order-breakdown-footer-color);
}

.order-breakdown-table-footer td:first-of-type {
  border-bottom-left-radius: 8px;
}

.order-breakdown-table-footer td:last-of-type {
  border-bottom-right-radius: 8px;
}

.order-breakdown-spacer td {
  position: relative;
  top: 1px;
}

.order-breakdown-spacer td:after {
  content: "";
  position: absolute;
  top: -1px;
  left: 0;
  height: 1px;
  width: 100%;
  background-color: var(--text-secondary-color);
}

.order-breakdown-spacer td:first-child:after,
.order-breakdown-spacer td:last-child:after {
  width: calc(100% - 16px);
}

.order-breakdown-spacer td:first-child:after {
  right: 0;
  left: auto;
}

.order-breakdown-bold-row td {
  font-weight: 600;
}

.tooltip-row .tooltip {
  display: inline-flex;
}

.order-breakdown-recording-eng {
  padding-left: 32px !important;
}

@media (max-width: 768px) {
  .order-breakdown-table th:first-of-type {
    width: 50%;
  }

  .order-breakdown-table th:nth-of-type(3) {
    display: none;
  }

  .order-breakdown-table tr td:nth-of-type(3) {
    display: none;
  }
}

.entity-search-result-list-item > img {
  margin-right: 10px;
  border-radius: 5px;
}

.entity-search-result-list-item-description {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.entity-search-result-list-item {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .entity-search-result-list-item {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.entity-search-result-list {
  margin-top: 20px;
  margin-bottom: 20px;
  height: 260px;
  overflow-y: scroll;
}

.entity-search-result-list.loading-autocomplete-results {
  display: flex;
  justify-content: center;
  align-items: center;
}

.entity-search-result-list-item-description > .b2-semi-bold {
  color: var(--black);
}

.entity-search-result-list-item-description > .b3 {
  color: var(--medium-grey);
}

.entity-search-result-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  border-radius: 5px;
  margin-right: 10px;
}
.subscription-feature {
  margin: 15px;
}

.feature-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px;
  margin: 10px;
  max-width: 400px;
}

.feature-row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
}
.feature-row > .feature-list, .feature-row > .plan-feature-card {
  flex-shrink: 0;
  box-sizing: border-box;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
}
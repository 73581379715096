.footer-subscribe {
  position: relative;
  padding: 50px 30px 40px;
  border-bottom: 1px solid hsla(212.00000000000006, 38.46%, 92.35%, 1.00);
}

@media screen and (max-width: 767px) {
  .footer-subscribe {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.container-2 {
  display: block;
  overflow: hidden;
  width: auto;
  max-width: none;
  margin-right: 20px;
  margin-left: 20px;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
}

@media screen and (max-width: 991px) {
  .container-2 {
    max-width: 728px;
  }
}

@media screen and (max-width: 479px) {
  .container-2 {
    max-width: none;
  }
}

.footer-form-two {
  margin-bottom: 40px;
  padding: 64px;
  background-color: var(--whiteSmoke-11a12020);
}

@media screen and (max-width: 991px) {
  .footer-form-two {
    padding: 40px;
  }
}

@media screen and (max-width: 479px) {
  .footer-form-two {
    padding: 20px;
  }
}

.footer-form-container-two {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .footer-form-container-two {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.footer-form-title {
  font-size: 24px;
  line-height: 32px;
}

@media screen and (max-width: 991px) {
  .footer-form-title {
    margin-right: 15px;
  }
}

@media screen and (max-width: 767px) {
  .footer-form-title {
    margin-right: 0px;
  }
}

.footer-form-block-two {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .footer-form-block-two {
    width: 100%;
    margin-top: 20px;
  }
}

@media screen and (max-width: 479px) {
  .footer-form-block-two {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.footer-form-input {
  width: 460px;
  height: 48px;
  margin-bottom: 0px;
  padding: 12px 180px 12px 20px;
  border: 1px none black;
}

.footer-form-input::placeholder {
  color: hsla(228, 8.77%, 11.18%, 0.80);
}

@media screen and (max-width: 991px) {
  .footer-form-input {
    width: 400px;
    padding-right: 180px;
  }
}

@media screen and (max-width: 767px) {
  .footer-form-input {
    width: 100%;
  }
}

@media screen and (max-width: 479px) {
  .footer-form-input {
    margin-bottom: 10px;
    padding-right: 20px;
  }
}

.button-primary {
  padding: 12px 25px;

  background-color: #1a1b1f;

  transition: all 200ms ease;

  font-family: Roobert;
  color: hsla(0, 0.00%, 100.00%, 1.00);
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.button-primary:hover {
  background-color: #32343a;
  color: hsla(0, 0.00%, 100.00%, 1.00);
}

.button-primary:active {
  background-color: #43464d;
}

.button-primary.footer-form-button {
  position: absolute;
  right: 8px;
  padding: 8px 18px;
}

@media screen and (max-width: 479px) {
  .button-primary.footer-form-button {
    position: relative;
    right: 0px;
  }
}

.footer-wrapper-three {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 991px) {
  .footer-wrapper-three {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.footer-block-three {
  display: flex;
  margin-right: -20px;
  margin-left: -20px;
  justify-content: flex-start;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .footer-block-three {
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: flex-start;
  }
}

.footer-link-three {
  margin-right: 20px;
  margin-left: 20px;
  color: #1a1b1f;
  text-decoration: none;
}

.footer-link-three:hover {
  color: rgba(26, 27, 31, 0.75);
}

@media screen and (max-width: 767px) {
  .footer-link-three {
    margin-bottom: 10px;
  }
}

.footer-social-block-three {
  display: flex;
  margin-left: -32px;
  justify-content: flex-start;
  align-items: center;
}

@media screen and (max-width: 991px) {
  .footer-social-block-three {
    margin-top: 40px;
  }
}

@media screen and (max-width: 767px) {
  .footer-social-block-three {
    margin-top: 10px;
  }
}

.footer-social-link-three {
  margin-left: 32px;
}

.footer-divider-two {
  width: 100%;
  height: 1px;
  margin-top: 40px;
  margin-bottom: 40px;
  background-color: hsla(212.00000000000006, 38.46%, 92.35%, 1.00);
}

.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .footer-bottom {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media screen and (max-width: 479px) {
  .footer-bottom {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.footer-copyright {
  color: hsla(214.6153846153846, 18.31%, 27.84%, 1.00);
}

@media screen and (max-width: 767px) {
  .footer-copyright {
    font-size: 14px;
    line-height: 20px;
  }
}

.footer-legal-block {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .footer-legal-block {
    margin-top: 15px;
    margin-left: -24px;
  }
}

.footer-legal-link {
  margin-left: 24px;
  color: hsla(214.6153846153846, 18.31%, 27.84%, 1.00);
}

.footer-legal-link:hover {
  color: hsla(214.6153846153846, 18.31%, 27.84%, 1.00);
}

@media screen and (max-width: 767px) {
  .footer-legal-link {
    font-size: 14px;
    line-height: 20px;
  }
}
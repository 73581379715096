.side-panel-overlay--closed {
  transition: background-color 0.4s ease-in-out;
  background-color: rgba(0, 0, 0, 0);
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 0;
  z-index: var(--side-panel-z-index) !important;
}

.side-panel-overlay--open {
  transition: background-color 0.4s ease-in-out;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: var(--side-panel-z-index) !important;
}

.side-panel-background {
  transition: all 0.6s ease-in-out;
  background-color: transparent;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: var(--side-panel-z-index) !important;
  overflow-x: hidden;
}

.side-panel-container {
  position: absolute;
  top: 0;
  height: 100%;
  width: 60%;
  overflow-x: hidden;
  padding: 12px;
  background-color: var(--background-color);
  box-sizing: border-box;
}

.side-panel-closed-left {
  transform: translateX(-100%);
  left: 0;
  transition: transform 0.6s ease-in-out;
}

.side-panel-closed-right {
  transform: translateX(100%);
  right: 0;
  transition: transform 0.6s ease-in-out;
}

.side-panel-background.open {
  transition: all 0.6s ease-in-out;
  transform: translateX(0%);
}

.side-panel-open {
  transform: translateX(0%);
  transition: transform 0.6s ease-in-out;
  overflow-y: auto;
}

.side-panel-header {
  display: flex;
  justify-content: flex-end;
}

.side-panel--no-padding {
  padding: 0;
}

.side-panel--rounded-border {
  border-radius: var(--border-radius-lg) 0 0 var(--border-radius-lg);
}

#side-panel-bottom-drawer-container {
  .side-panel-bottom-drawer,
  .side-panel-bottom-drawer__backdrop {
    z-index: var(--side-panel-z-index);
  }

  .side-panel-bottom-drawer__content {
    padding: 24px 12px;
  }

  .side-panel-bottom-drawer {
    background-color: var(--background-color);
    color: var(--text-primary-color);
  }
}

.favorite-heart-container {
  width: 23px;
  height: 20px;
  background-repeat: no-repeat;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  background-position: center;
  background-size: 100% 100%;
}

.save-profile-button > p {
  margin-right: 0.5em;
}

.save-profile-button {
  border: none;
  outline: none;
  cursor: pointer;
  background-color: var(--warm-light-grey);
  height: 36px;
  border-radius: var(--border-radius-md);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
}

@media screen and (max-width: 768px) {
  .save-profile-button {
    z-index: 1;
    width: 36px;
    height: 36px;
    justify-content: center;
    border-radius: 50%;
  }
  .save-profile-button > p {
    display: none;
  }
}

@keyframes heartBeat {
  0% {
    transform: scale(1);
  }
  14% {
    transform: scale(1.3);
  }
  28% {
    transform: scale(1);
  }
  42% {
    transform: scale(1.3);
  }
  70% {
    transform: scale(1);
  }
}

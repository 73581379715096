.comment-instructions-text {
  font-size: 12px !important;
  margin-left: 30px;
}

.dolby-logo {
  position: absolute;
  left: -15px;
}

.dolby-mp4-player-container {
  width: 700px;
  height: 490px;
  border-radius: 15px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: center;
  background-color: var(--white);
}

.dolby-mp4-player-button .v1-button {
  width: 250px;
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .dolby-logo {
    display: none;
  }
  .dolby-mp4-player-container {
    width: 300px;
  }
}

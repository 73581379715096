.more-options-button {
  cursor: pointer;
  color: grey;
  background: none;
  border: none;
}

.subscription-selection-section {
  display: flex;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .subscription-selection-section {
    height: unset;
    max-width: 100%;
  }
}

.more-button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
}

.more-button-container > .more-options-button {
  margin-bottom: 10px;
}

.subscription-selection-section > * {
  margin-right: var(--project-screen-element-margin);
  margin-left: var(--project-screen-element-margin);
}

.select-basic-modal {
  display: flex;
  padding: 10px;
  align-items: center;
  flex-direction: column;
  width: 500px;
  height: 250px;
  background: var(--white);
  border-radius: 15px;
}

.select-basic-modal-footer {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.select-basic-modal-footer > * {
  margin-right: 1em;
}
.subscription-selection-section > .subscription-card-container {
  height: unset;
  margin-top: var(--project-screen-element-margin);
  margin-bottom: var(--project-screen-element-margin);
}

@media screen and (max-width: 768px) {
  .subscription-selection-section {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .more-button-container {
    width: 100%;
    margin-top: unset;
  }
}

.amenity-card {
  width: 80px;
  height: 80px;
  border-radius: 5px;
  border: 2px solid var(--warm-light-grey);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.5s ease;
  background-color: var(--white);
  flex-direction: column;
  margin: 5px;
}
@media (max-width: 768px) {
  .amenity-card {
    width: 74px;
    height: 74px;
    margin: 3px;
  }
}
.amenity-label {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: var(--black);
}

.amenity-list-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
}

.amenity-icon {
  color: var(--black);
  margin-bottom: 4px;
}

.amenity-card.selected {
  background-color: var(--mint-green);
  color: var(--black-no-dark-theme);
}

.amenity-card.selected > .amenity-icon {
  color: var(--black-no-dark-theme);
}

.amenity-card.selected > p {
  color: var(--black-no-dark-theme);
}

.amenity-card.edit-mode {
  cursor: pointer;
}

.amenity-card.no-card {
  background: transparent;
  border: none;
}

.muso-credits-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.small-padding {
  padding-left: 5px;
  padding-right: 1px;
  margin: 0px;
}

.muso-credit-table {
  border: 1px var(--light-grey);
  border-radius: 8px;
  padding: 10px;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0px 0px 6px rgb(109 109 109 / 18%);
}

.muso-credit-table th {
  width: inherit;
  text-align: start;
  margin: 10px;
  padding: 10px 20px;
}

.muso-credit-table td {
  text-align: start;
  margin: 10px;
  padding: 10px 20px;
}

.muso-credit-table-row {
  border-top: 1.5px thin var(--light-grey);
  min-height: 80px;
}

.submit-selected-muso-credits-button {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin: 20px;
}

.credit-count {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  padding: 15px 20px;
}

.selected-credits {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 15px 20px;
}

.paginate-button {
  width: 35px;
  height: 35px;
  font-size: 20px;
  text-align: center;
  background-color: var(--white);
  border: 1px solid var(--light-grey);
  border-radius: 4px;
  outline: none;
  transition: all 0.3s;
  cursor: pointer;
  margin-right: 4px;
}

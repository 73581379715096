.checkbox-container-primary {
  align-items: center;
  background-color: var(--checkbox-color--disabled);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  padding: 2px;
  height: 12px;
  min-width: 12px;
  width: 12px;
  cursor: pointer;
}

.checkbox-container-primary.checked {
  background-color: var(--primary-color);
}

.checkbox-container-primary.disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.checkbox-container-outlined {
  align-items: center;
  border: 1px solid var(--text-secondary-color);
  border-radius: var(--border-radius-xs);
  display: flex;
  justify-content: center;
  height: 1rem;
  width: 1rem;
  cursor: pointer;
}

.checkbox-container-outlined.disabled {
  opacity: 0.7;
  border: 1px solid var(--text-tertiary-color);
  cursor: not-allowed;
}

.checkbox-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 0;
}

.checkbox-label {
  align-items: center;
  display: flex;
  gap: 10px;

  &:hover {
    cursor: pointer;
  }
}

.transactions-table {
  width: 100%;
  table-layout: fixed;
}

.table-headers {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: var(--background-color);
  max-height: 40px;
  margin-bottom: 15px;
}

.filter-header {
  flex: content;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: transparent;
  color: var(--text-primary-color);
}

.table-headers td > b2-semi-bold {
  text-align: center;
  vertical-align: middle;
  padding: 4px;
}

.table-headers-sort-options {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: var(--smooth-ivory);
  max-height: 30px;
  overflow: hidden;
  border: 1px solid var(--warm-light-grey);
  border-radius: 5px;
  padding-left: 15px;
  padding-right: 25px;
}

.table-headers-sort-options > th {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: var(--smooth-ivory);
  max-height: 30px;
  overflow: hidden;
  color: var(--text-primary-color);
}

@media (max-width: 768px) {
  .table-headers-sort-options {
    justify-content: space-evenly;
    padding: 0px;
  }

  .table-headers-sort-options > th {
    justify-content: space-evenly;
    flex: 1;
    white-space: nowrap;
  }
}

.date-picker {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 10px;
  color: var(--dark-grey);
  border: 1px solid var(--light-grey);
  font-family: inherit;
  height: 32px;
  padding-left: 5px;
  transition: all ease-in 500ms;
  position: relative;
  cursor: pointer;
}

.search-field {
  width: fit-content;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon {
  border-color: var(--black-no-dark-theme);
  border-style: solid;
  border-width: 3px 3px 0 0;
  content: "";
  display: block;
  height: 9px;
  position: absolute;
  top: 6px;
  width: 9px;
}
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  margin-left: -4px;
  position: absolute;
  width: 0;
}
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after {
  box-sizing: content-box;
  position: absolute;
  border: 8px solid transparent;
  height: 0;
  width: 1px;
  content: "";
  z-index: -1;
  border-width: 8px;
  left: -8px;
}
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  border-bottom-color: var(--dark-grey);
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  top: 0;
  margin-top: -8px;
}
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after {
  border-top: none;
  border-bottom-color: var(--light-grey);
}
.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100%;
}

.react-datepicker {
  font-family: "Roobert Woff 2";
  font-size: 0.8rem;
  color: var(--black-no-dark-theme);
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.react-datepicker__header {
  text-align: center;
  background-color: var(--twilight-blue);
  border-bottom: 1px solid #aeaeae;
  padding: 8px 0;
  position: relative;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: var(--black-no-dark-theme);
  font-weight: bold;
  font-size: 0.944rem;
}

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.react-datepicker__navigation {
  align-items: center;
  background: none;
  display: flex;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 2px;
  padding: 0;
  border: none;
  z-index: 1;
  height: 32px;
  width: 32px;
  text-indent: -999em;
  overflow: hidden;
}
.react-datepicker__navigation--previous {
  left: 2px;
}
.react-datepicker__navigation--next {
  right: 2px;
}
.react-datepicker__navigation--next--with-time:not(
    .react-datepicker__navigation--next--with-today-button
  ) {
  right: 85px;
}
.react-datepicker__navigation--years {
  position: relative;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.react-datepicker__navigation--years-previous {
  top: 4px;
}
.react-datepicker__navigation--years-upcoming {
  top: -4px;
}
.react-datepicker__navigation:hover * {
  border-color: var(--light-grey);
}

.react-datepicker__navigation-icon {
  position: relative;
  top: -1px;
  font-size: 20px;
  width: 0;
}
.react-datepicker__navigation-icon--next {
  left: -2px;
}
.react-datepicker__navigation-icon--next {
  transform: rotate(45deg);
  left: -7px;
}
.react-datepicker__navigation-icon--previous {
  right: -2px;
}
.react-datepicker__navigation-icon--previous {
  transform: rotate(225deg);
  right: -7px;
}

.react-datepicker__month-container {
  background-color: var(--white);
  float: left;
  border-radius: 16px;
}

.react-datepicker__year {
  margin: 0.4rem;
  text-align: center;
}
.react-datepicker__year-wrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 180px;
}
.react-datepicker__year .react-datepicker__year-text {
  display: inline-block;
  width: 4rem;
  margin: 2px;
}

.react-datepicker__month {
  margin: 0.4rem;
  text-align: center;
}
.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
  display: inline-block;
  width: 4rem;
  margin: 2px;
}

.react-datepicker__day-names,
.react-datepicker__week {
  white-space: nowrap;
}

.react-datepicker__day-names {
  margin-bottom: -8px;
}

.react-datepicker__day {
  color: var(--black);
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}

.react-datepicker__month--disabled:hover,
.react-datepicker__quarter--disabled:hover {
  cursor: default;
  background-color: transparent;
}

.react-datepicker__day {
  cursor: pointer;
}
.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 50%;
  background-color: var(--light-grey);
}
.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
  font-weight: bold;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 50%;
  background-color: var(--boomy-orange);
  color: var(--white-no-dark-theme);
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  cursor: default;
  color: var(--light-grey);
}
.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled:hover,
.react-datepicker__quarter-text--disabled:hover,
.react-datepicker__year-text--disabled:hover {
  cursor: not-allowed;
  background-color: transparent;
}

.react-datepicker__year-read-view:hover
  .react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover
  .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover
  .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view:hover
  .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover
  .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover
  .react-datepicker__month-read-view--down-arrow {
  border-top-color: var(--light-grey);
}
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  transform: rotate(135deg);
  right: 12px;
  top: 0;
}

.react-datepicker__portal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  z-index: 2147483647;
}
.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
  width: 3rem;
  line-height: 3rem;
}
@media (max-width: 400px), (max-height: 550px) {
  .react-datepicker__portal .react-datepicker__day-name,
  .react-datepicker__portal .react-datepicker__day,
  .react-datepicker__portal .react-datepicker__time-name {
    width: 2rem;
    line-height: 2rem;
  }
}
.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
  font-size: 1.44rem;
  font-family: inherit;
  padding: 10px;
}

.react-datepicker__aria-live {
  position: absolute;
  clip-path: circle(0);
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  width: 1px;
  white-space: nowrap;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 15px;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: var(--twilight-blue);
  color: var(--black);
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: 0.3rem;
  border: 1px solid var(--black-no-dark-theme);
}
.react-datepicker__year-dropdown:hover,
.react-datepicker__month-dropdown:hover,
.react-datepicker__month-year-dropdown:hover {
  cursor: pointer;
}
.react-datepicker__year-dropdown--scrollable,
.react-datepicker__month-dropdown--scrollable,
.react-datepicker__month-year-dropdown--scrollable {
  height: 150px;
  overflow-y: scroll;
}

.download-csv-text {
  margin-top: -54px;
  margin-right: 20px;
  float: right;
  cursor: pointer;
  border-radius: 10px;
  border: 1px solid var(--dark-grey);
  background-color: var(--white);
  padding: 12px 30px;
}

.csv-download-icon {
  margin-right: 10px;
}

.rc-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rc-pagination-item {
  border: none !important;
  font-family: "Roobert";
  font-size: 16px;
  font-weight: 600;
  padding: 0;
  margin: 0px;
  background-color: transparent;
}

.rc-pagination-item-active {
  text-decoration: underline;
  color: var(--black);
}

.rc-pagination-item a {
  color: var(--medium-grey);
}

.rc-pagination-item-active a {
  color: var(--black);
}

.rc-pagination-jump-prev button::after,
.rc-pagination-jump-next button::after {
  color: var(--medium-grey);
}

.rc-pagination-prev,
.rc-pagination-next {
  margin-right: 0px;
}

.rc-pagination-prev button,
.rc-pagination-next button {
  border: none !important;
  background: transparent !important;
}

.rc-pagination-prev button::after,
.rc-pagination-next button::after {
  font-size: 28px;
  position: relative;
  top: -5px;
  color: var(--black);
  background-color: transparent;
}

@media screen and (max-width: 768px) {
  .download-csv-text {
    margin-left: auto;
    float: none;
    margin-bottom: 20px;
    max-width: 100px;
  }
}

.centered-empty-state-message {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  height: 100px;
}

.paginator-container-transactions {
  display: flex;
  justify-content: center;
}

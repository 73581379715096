.order-arrows-container {
  cursor: pointer;
  height: 18px;
  position: relative;
  width: 18px;
}

.order-arrow {
  left: 6;
  position: absolute;
}

.project-alts-cart-table th {
  text-align: left;
}

.song-line-item {
  cursor: pointer;
  height: 30px;
}

.song-line-item:hover {
  background-color: var(--echo-orange);
  max-height: 30px;
}

.selected-song {
  background-color: var(--boomy-orange);
  color: var(--white);
  max-height: 30px;
}

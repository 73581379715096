.platform-activity-notification-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-radius: 5px;
  border: 1px solid var(--warm-light-grey);
  background: var(--white);
  padding: 13px 15px 13px 15px;
  position: relative;
  overflow: clip;
}

.platform-activity-notification-row__user-image {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 10px;
}

.platform-activity-notification-row-bolded-text:first-child {
  text-transform: capitalize;
}
.platform-activity-notification-row-bolded-text {
  color: var(--stereo-black);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  margin-right: 5px;
}

.platform-activity-notification-row__user-display-name:last-child {
  text-transform: none;
}

.platform-activity-notification-row
  > .platform-activity-notification-row__user-username {
  color: var(--medium-grey);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-transform: capitalize;
  margin-right: 5px;
}

.platform-activity-notification-row__text-container__created {
  color: var(--medium-grey);
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.platform-activity-notification-row__text-container > p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.platform-activity-notification-row__text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  position: relative;
  margin-left: auto;
}

.left-gradient-edge {
  position: absolute;
  top: 0;
  left: -35px;
  width: 35px;
  bottom: 0;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1)
  );
}

.left-gradient-edge.dark {
  background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
}

.platform-activity-table {
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  max-width: 600px;
  max-height: 576px;
  overflow-y: hidden;
  width: 100%;
  box-sizing: border-box;
  gap: 10px;
  height: 100%;
}

.platform-activity-table > .platform-activity-notification-row {
  margin-bottom: 10px;
}

.platform-activity-table > .platform-activity-notification-row:last-child {
  margin-bottom: 0;
}

.platform-activity-side-panel-container {
  max-height: unset;
  max-width: unset;
  background-color: unset;
  border: unset;
  width: 100%;
  flex: 1;
}

.platform-activity-empty-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .platform-activity-table {
    background-color: unset;
    border: unset;
    max-width: unset;
    padding: unset;
    width: 100%;
    box-sizing: border-box;
  }

  .platform-activity-side-panel-container {
    overflow-y: hidden;
  }
}

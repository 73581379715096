.nav-search-bar-container {
  margin: 0 16px;
  position: relative;
  width: 100%;
}

.nav-search-bar {
  border: none;
  background-color: var(--midground-color);
  display: flex;
  outline: none;
  border-radius: var(--border-radius-md);
  width: 100%;
}

.nav-search-bar.show {
  border-radius: var(--border-radius-md) var(--border-radius-md) 0 0;
  -moz-box-shadow: var(--box-shadow-size-and-color);
  -webkit-box-shadow: var(--box-shadow-size-and-color);
  box-shadow: var(--box-shadow-size-and-color);
}

.nav-input {
  border: none;
  border-radius: var(--border-radius-md);
  outline: none;
  padding: 0.75rem 0rem 0.75rem 0.75rem;
  width: 100%;
  height: inherit;
  color: var(--text-primary-color);
  background-color: transparent;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.nav-search-button {
  display: flex;
  align-items: center;
  border: none;
  cursor: pointer;
  border-radius: 0 var(--border-radius-md) var(--border-radius-md) 0;
  outline: none;
  padding: 0.75rem 1rem;
  color: var(--text-primary-color);
  background-color: transparent;
  transition: 150ms all;
  font-weight: 600;

  &:hover {
    background-color: var(--nav-button-color--hover);
  }
}

.nav-search-button.show {
  border-radius: 0 var(--border-radius-md) 0 0;
}

.nav-filter-button {
  border-radius: 0;
}

.nav-search-results-container--attached {
  top: 42px;
}

/* At this breakpoint, the filter button interferes with the NavProfileMenu */
@media only screen and (max-width: 950px) {
  .nav-filter-button {
    display: none !important;
  }
}


.add-a-studio-container.card-container {
  display: flex;
  flex-direction: column;
  padding: 30px;
  height: auto;
  min-width: 300px;
}

@media screen and (max-width: 330px) {
  .add-a-studio-container.card-container {
    min-width: unset;
  }
}

.add-a-studio-input {
  width: 100%;
  margin-top: var(--project-screen-element-margin);
  margin-bottom: var(--project-screen-element-margin);
  outline: none;
  border: solid 0.75px;
  border-radius: 4px;
  border-color: var(--light-grey);
  height: 35px;
  padding-left: 10px;
  color: var(--gray);
  white-space: nowrap;
}

.add-studio-button {
  margin-bottom: 15px;
}

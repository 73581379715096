.payment-plans-view {
  display: flex;
}

.payment-plans-container {
  display: grid;
  grid-template-columns: auto auto;
  width: 500px;
  margin-left: 4em;
  margin-right: 4em;
}

.payment-plans-container > * {
  margin-right: 1em;
}

.payment-plans-footer {
  display: flex;
}

.payment-plans-footer > * {
  margin-right: 1em;
}

@media screen and (max-width: 576px) {
  .payment-plans-view {
    flex-direction: column-reverse;
    align-items: center;
  }

  .payment-plans-container {
    grid-template-columns: unset;
    gap: 1em;
    width: unset;
  }

  .payment-plans-footer {
    flex-direction: column;
    width: 100%;
  }
}

.share-booking-link-container {
  color: var(--text-primary-color);
}

.share-booking-link-header {
  padding-bottom: 16px;
}

.share-booking-link-multi-email {
  box-sizing: border-box;
  max-width: 100%;
  padding: 16px;
  outline: 0;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
  background: var(--white-no-dark-theme);
  border: 1px solid var(--gray-200);
  color: var(--dark-grey);
  border-radius: 8px;
  font-size: 16px;
}

.share-booking-link-multi-email > span[data-placeholder] {
  display: none;
  position: absolute;
}

.share-booking-link-multi-email.empty > span[data-placeholder] {
  display: inline;
  position: relative;
  background-color: var(--white-no-dark-theme);
  color: var(--dark-grey);
}

.share-booking-link-multi-email.focused > span[data-placeholder] {
  display: none;
}

.share-booking-link-multi-email > input {
  outline: none !important;
  border: 0 none !important;
  display: inline-block !important;
  vertical-align: baseline !important;
  background-color: var(--white-no-dark-theme);
  width: 100%;
}

.share-booking-link-multi-email [data-tag] {
  padding: 8px;
  border: 1px solid var(--gray-200);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  margin-right: 8px;
}

.share-booking-link-multi-email [data-tag-handle] {
  display: flex;
  cursor: pointer;

  img {
    margin-left: 8px;
    align-self: center;
    height: 12px;
    width: 12px;
  }
}

.share-booking-link-buttons {
  display: flex;
  padding-top: 16px;
  width: 100%;
  justify-content: flex-end;

  button {
    margin-left: 8px;
  }

  a {
    margin-left: 8px;
  }

  svg {
    margin-right: 8px;
  }
}

@media (max-width: 768px) {
  .share-booking-link-buttons {
    padding-top: 0px;
  }
}

.image-carousel-overlay {
  position: absolute;
  bottom: -100%;
  left: 0;
  width: 100%;
  z-index: 1000;
  background-color: white;
  display: flex;
  flex-direction: column;
  opacity: 1;
  pointer-events: none;
  transition:
    background-color 1s ease-out,
    opacity 1s ease-out;
}

.image-carousel-overlay.sliding-up {
  background-color: black;
  opacity: 1;
  animation: slideUp 0.5s forwards ease-out;
  pointer-events: all;
}

.image-carousel-overlay.sliding-down {
  background-color: white;
  opacity: 1;
  animation: slidingDown 0.5s forwards ease-out;
  pointer-events: none;
}

.image-carousel-overlay > .entity-photos-screen__header {
  justify-content: space-between;
}

.image-carousel-overlay > .entity-photos-screen__header > :nth-child(2) > p {
  color: white;
}

.image-carousel-overlay
  > .entity-photos-screen__header
  > .entity-photos-screen__header__item
  > p {
  text-align: center;
  width: 100%;
}

.image-carousel-overlay-body {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.detail-image-carousel-container {
  flex: 8;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.image-carousel-arrow {
  cursor: pointer;
}

.detail-image-carousel-container > :first-child {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.image-carousel-overlay-carousel-navigator {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.detail-image-carousel-image-wrapper {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.detail-entity-photo {
  max-width: 100%;
  height: 100%;
  display: inline-block;
  position: relative;
}

@media screen and (max-width: 768px) {
  .image-carousel-overlay-body {
    flex-direction: column;
    flex: 1;
    padding-bottom: 60px;
  }
  .image-carousel-overlay-carousel-navigator {
    display: none;
  }
  .image-carousel-overlay > .entity-photos-screen__header {
    height: 50px;
  }

  .detail-entity-photo {
    width: max-content;
    height: max-content;
    display: inline-block;
    position: relative;
  }
}

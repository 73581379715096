.nav-tab-items-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  background: var(--background-color);
  border-top: 1px solid var(--smooth-ivory);
  box-shadow: 0 0 6px var(--box-shadow-color);
  position: sticky;
  padding: 12px 8px;
  transition: 0.3s ease;
  box-sizing: border-box;
  width: 100%;
  z-index: var(--bottom-nav-z-index) !important;
}

.overlay-above-bottom-nav {
  position: relative;
  height: fit-content;
  display: flex;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  transition: 0.5s ease;
  & > div {
    width: 100%;
  }
}

.overlay-children-view.no-padding {
  padding: 0;
}

.overlay-children-view {
  padding: 1rem;
  width: 100%;
  background: var(--background-color);
  border-top: 1px solid var(--smooth-ivory);
  box-shadow: 0 0 6px var(--box-shadow-color);
}

.overlay-children-view:has(div.drawer) {
  padding: 0;
  & .footer-actions {
    display: none;
  }
}

.focused-overlay {
  position: absolute;
  z-index: var(--modal-index);
  bottom: 0;
}

.project-workflow-sticky-footer-content {
  /* Offset the padding of side panel */
  margin: -24px;
  width: 100%;
  padding: 24px;
  margin-top: 0;
  background-color: var(--background-color);
  border-top: 1px solid var(--gray-200);
}
.reviews-list-container {
  height: 100%;
  width: 100%;
  padding: 40px;
  overflow-y: auto;
}

.reviews-list-header {
  display: flex;
  align-items: center;
  font-size: 24px;
  line-height: 28px;
  font-weight: 600;
  height: 54px;
  color: var(--black);
}

.reviews-list-average-rating {
  height: 100%;
  border-right: 1px solid var(--light-grey);
  padding-right: 16px;
  display: flex;
  align-items: center;

  img {
    margin-right: 5px;
    margin-bottom: 8px;
  }
}

.reviews-list-ratings-count {
  padding-left: 16px;
}

.reviews-list-reviews-container {
  width: 100%;
  padding-top: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.reviews-list-review-wrapper {
  width: 45%;
  padding-top: 30px;
}

.reviews-list-load-more {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 30px;
}

@media (max-width: 768px) {
  .reviews-list-header {
    font-size: 18px;
    line-height: 22px;
  }

  .reviews-list-review-wrapper {
    width: 100%;
  }
}

.error-message {
  width: 336px;
  color: var(--error-red);
  margin-bottom: 20px;
}
.error-message > a {
  color: var(--stereo-black);
}
.error-message > span {
  color: var(--stereo-black);
}

.nav-profile-button-mobile {
  border: none;
  border-radius: var(--border-radius-sm);
  background-color: var(--background-color);
  cursor: pointer;
  padding: 12px;

  &:hover {
    background-color: var(--button-secondary-color--hover);
  }
}

.nav-profile-side-panel-logo {
  width: 7.375rem;
  height: 1.25rem;
  margin: 8px 0 16px 8px;
}

.nav-profile-side-bar-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 24px);
}

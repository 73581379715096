.sign-up-screen-container {
  display: flex;
  flex: 1;
  align-items: center;
  width: 100%;
  height: fit-content;
  justify-content: center;
  flex-direction: column;
  background-image: url("../../assets/floating-image-left-right.png");
  background-position: 0% 40%;
  background-repeat: no-repeat;
  background-size: 100%;
  background-color: var(--sky-light-blue);
}

#sign-up-screen-title {
  margin-bottom: 3vh;
  margin-top: 1vh;
  font-style: normal;
  font-weight: 600;
  font-size: 56px;
  line-height: 58px;
  letter-spacing: -0.02em;
  color: var(--header-text);
}

.waveform {
  padding: 20px;
}

/* TODO: Remove once the competition is over. */
.soundoftomorrow-instruction {
  text-align: center;
  padding: 20px;
}

.login-link {
  box-sizing: border-box;
  text-decoration: none;
  color: var(--black);
  display: flex;
  gap: 15px;
  width: 336px;
  padding: 20px;
  font-size: 12px;
  margin-bottom: 20px;
}

.sign-up-form-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  padding-bottom: 1rem;
}

.sign-up-form-container > div {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.sign-up-footer {
  padding-bottom: 2em;
}

@media screen and (max-width: 376px) and (min-height: 800px) {
  .sign-up-screen-container {
    background-image: url("../../assets/floating-image-left-right-mobile.png");
    background-position: 50% 18%;
    background-repeat: no-repeat;
    background-size: 80%;
  }

  .sign-up-form-container {
    position: relative;
    width: 100%;
  }

  .waveform {
    padding: 0;
    margin-top: 3vh;
  }

  .menu {
    position: inherit !important;
  }

  #sign-up-screen-title {
    margin-bottom: 0px;
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 700px) and (min-height: 1200px) {
  .sign-up-screen-container {
    background-image: url("../../assets/floating-image-left-right-mobile.png");
    background-position: 50% 18%;
    background-repeat: no-repeat;
    background-size: 80%;
  }

  .sign-up-form-container {
    position: relative;
    top: 15vh;
    max-height: 0%;
    width: 100%;
  }

  .waveform {
    padding: 0;
    margin-top: 3vh;
  }

  .menu {
    position: inherit !important;
  }

  #sign-up-screen-title {
    margin-bottom: 0px;
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 700px) and (max-height: 800px) {
  .sign-up-screen-container {
    background: var(--sky-light-blue);
  }

  .sign-up-form-container {
    top: 0;
  }

  .menu {
    z-index: 1;
  }

  #sign-up-screen-title {
    margin-bottom: 0px;
    font-size: 2.5rem;
  }

  .waveform {
    padding: 20px;
  }
}

@media screen and (max-width: 700px) and (max-height: 1200px) and (min-width: 376px) and (min-height: 800px) {
  .sign-up-screen-container {
    background: var(--sky-light-blue);
  }

  .sign-up-form-container {
    top: 0;
  }

  .menu {
    z-index: 1;
  }

  #sign-up-screen-title {
    margin-bottom: 0px;
    font-size: 2.5rem;
  }

  .waveform {
    padding: 20px;
  }
}

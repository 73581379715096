.review-aggregate-container {
    min-height: 13px;
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 4px 4px 5px;
    background-color: var(--white-no-dark-theme);
    border-radius: 5px;
    flex-shrink: 0;
}

.review-aggregate-spacing {
    margin-right: 2px;
}

.review-aggregate-text {
    color: var(--black-no-dark-theme);
}
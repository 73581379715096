.add-subscription-container {
  display: flex;
  justify-content: center;
  height: 40vh;
}

.add-subscription-container .project-stepper-container {
  width: 93px !important;
}

.subscription-setup-container {
  text-align: center;
}

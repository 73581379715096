.pagination-div {
  max-width: 450px;
  display: flex;
  justify-content: center;
}

.checkout-screen {
  display: flex;
  flex-direction: column;
  padding-left: var(--project-screen-element-margin);
  padding-right: var(--project-screen-element-margin);
  margin-top: var(--project-screen-element-margin);
  margin-bottom: var(--project-screen-element-margin);
}

.checkout-card {
  width: 100%;
  max-width: 450px;
  height: 100%;
  flex-direction: column;
  padding: 15px;
  margin-bottom: var(--project-screen-element-margin);
  overflow-y: scroll;
}

.payment-screen-column {
  width: 100%;
  max-width: 400px;
  flex-direction: column;
  margin-bottom: var(--project-screen-element-margin);
  overflow-y: auto;
  position: relative;
  margin-bottom: auto;
}

.checkout-card-row-container {
  align-self: center;
  width: 100%;
  max-width: 1000px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: var(--project-screen-element-margin);
  margin-bottom: var(--project-screen-element-margin);
}

.checkout-card-row-container.payment-screen {
  justify-content: space-evenly;
  align-items: center;
}

.checkout-footer-previous-button {
  margin-right: auto;
}

.checkout-footer-next-button {
  margin-left: auto;
}

.calendar-checkout-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: center;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: var(--project-screen-element-margin);
  min-width: 450px;
}

.project-alts-cart-table {
  border-collapse: collapse;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: var(--project-screen-element-margin);
  border-radius: 15px;
  background: var(--white);
  box-shadow: 0px 0px 6px rgba(109, 109, 109, 0.18);
  margin-bottom: var(--project-screen-element-margin);
  width: 100%;
  max-width: 450px;
  height: 100%;
}

.project-booking-card-body {
  max-height: 250px;
  height: fit-content;
  margin-bottom: var(--project-screen-element-margin);
  overflow-y: scroll;
}

.checkout-loading-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 521px;
  width: 100%;
}

@media (max-width: 1115px) {
  .checkout-card {
    max-width: unset;
    max-height: unset;
    height: auto;
  }

  .payment-screen-column {
    max-width: unset;
    max-height: unset;
    height: auto;
  }

  .project-alts-cart-table {
    height: unset;
    display: block;
  }

  .project-booking-card-body {
    height: unset;
  }
}

@media (max-width: 500px) {
  .calendar-checkout-container {
    min-width: 100%;
  }
}

.competition-promo-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 300px;
}

.competition-subheader {
  font-size: 26px;
  font-weight: 400;
}
@media (max-width: 768px) {
  .competition-subheader {
    font-size: 18px;
  }
}

.competition-text-content {
  padding: 20px;
  margin-top: 40px;
}

.competition-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.competition-no-thanks {
  cursor: pointer;
  font-size: 14px;
  text-decoration: underline;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 20px;
}

.competition-quotation {
  color: #ea0053;
}

.reschedule-screen-alts {
  background-color: var(--white);
  border-radius: 17px;
  box-shadow: 0 0 6px rgba(109, 109, 109, 0.18);
  display: flex;
  justify-content: center;
  text-align: left;
  align-items: center;
  width: calc(100% - 3em);
  flex-direction: column;
  padding: var(--project-screen-element-margin);
}

.alt-lists-reschedule {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  margin-left: 20px;
  align-items: flex-start;
}

.payment-screen-column > .v1-button {
  width: 100%;
}

.project-review-rating {
  transform: translateX(-3%);
  margin-top: 10px;
  margin-bottom: 10px;
}

.project-review.card-container > .v1-button {
  margin-left: auto;
}

.has-review {
  transform: translateX(0%);
}

.show-edit-review-button {
  animation: fadeIn 1s;
  transform: translateX(0%);
  animation-fill-mode: forwards;
}

.hide-edit-review-button {
  transform: translateX(100%);
  animation: fadeOut 1s;
  animation-fill-mode: forwards;
}

.project-review.card-container {
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  height: auto;
  margin-bottom: var(--project-screen-element-margin);
}

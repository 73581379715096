.studio-screen-request-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  height: 189px;
  border: 1px var(--black) solid;
  border-radius: 10px;
  margin-left: 25px;
  margin-right: 25px;
  margin-bottom: 25px;
  background-color: var(--white);
}

.browse-studio-screen > .studio-screen-request-container {
  margin-bottom: 30px;
}

.studio-screen-request-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.studio-screen-request-button > .b1 {
  color: var(--transparent-grey);
  margin-bottom: 15px;
}

.studio-screen-request-button > .studio-screen-request-logo {
  margin-bottom: 10px;
}

.studio-request-centered-text {
  text-align: center;
}
.logged-in-base-icons {
  margin-right: 20px;
  cursor: pointer;
}

.logged-in-header-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-right: auto;
  align-content: flex-end;
}

.user-name-section-header {
  white-space: break-spaces;
  /*TODO: add ellipsis for users with extrenously large usernames.*/
}

.logged-in-header-container > .user-profile-image {
  margin-left: 20px;
}

.center-nav-section {
  width: 100%;
  display: flex;
  align-items: center;
}
.nav-icon-row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.center-nav-section > .search-input {
  max-width: 257px;
  border: 0.75px solid var(--light-grey);
  box-sizing: border-box;

  margin: 28px 19px 28px auto;
  padding: 6px;
}

.center-nav-section > .search-input > .input {
  font-size: 14px;
  height: 100%;
}

@media screen and (max-width: 1400px) {
  .center-nav-section > .search-input {
    display: none;
  }
}

@media screen and (max-width: 1200px) {
  .nav-icon-row {
    display: none;
  }
}

.three-dot-drop-down-button {
  cursor: pointer;
}

.three-dot-drop-down-button.notification-content {
  width: 150px;
  height: 50px;
  top: 20px;
  left: -100px;
}

.log-out-button-container {
  background-color: var(--white);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.log-out-button-container:hover {
  filter: brightness(85%);
}

.search-button {
  cursor: pointer;
  border-radius: 10px;
  font: inherit;
  display: flex;
  padding-right: 5px;
  text-align: left;
  width: 150px !important;
  margin-right: 30px;
  vertical-align: middle;
  display: flex;
  align-items: center;
}

.search-button > img {
  vertical-align: sub;
  margin-right: 9px;
}

.search-button > p:hover {
  text-decoration: underline;
}

.feedback-button {
  cursor: pointer;
  border: none;
  outline: none;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--boomy-orange);
  padding: 5px;
  border-radius: 5px;
  margin-right: 5px;
}
.feedback-button:hover {
  transform: scale(0.95);
  background-color: var(--boomy-orange);
  transition: all 0.2s ease-in-out;
}

.feedback-button > p {
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: capitalize !important;
  color: var(--white);
  white-space: nowrap !important;
}

.new-feature-pill {
  width: 40px;
  min-width: 40px;
}
.navbar-logo-left-container-2 {
  z-index: 5;

  width: 1030px;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 15px 20px;

  background-color: transparent;
}

@media screen and (max-width: 767px) {
  .navbar-logo-left-container-2 {
    max-width: 100%;
  }
}

.navbar-logo-left-container-2.shadow-three {
  width: 100%;
  margin-bottom: 0px;
  padding: 20px 5%;
}

.container-10 {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

@media screen and (max-width: 991px) {
  .container-10 {
    max-width: 728px;
  }
}

@media screen and (max-width: 479px) {
  .container-10 {
    max-width: none;
  }
}

.navbar-wrapper-2 {
  display: flex;
  max-width: 1380px;
  justify-content: space-between;
  align-items: center;
}

@media screen and (min-width: 1440px) {
  .navbar-wrapper-2 {
    margin-right: auto;
    margin-left: auto;
  }
}

.navbar-brand-2 {
  margin-left: 20px;
}

@media screen and (max-width: 767px) {
  .navbar-brand-2 {
    padding-left: 0px;
  }
}

@media screen and (max-width: 767px) {
  .image-27 {
    max-width: 80%;
  }
}

@media screen and (max-width: 991px) {
  .nav-menu-wrapper-2 {
    background-color: transparent;
  }
}

.nav-menu-two-2 {
  display: flex;
  margin-bottom: 0px;
  justify-content: flex-end;
  align-items: center;
  grid-column-gap: 5px;
  grid-row-gap: 5px;
}

@media screen and (max-width: 991px) {
  .nav-menu-two-2 {
    display: flex;
    margin-top: 10px;
    padding: 20px;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;

    border-radius: 50px;

    background-color: hsla(0, 0.00%, 100.00%, 1.00);

    box-shadow: 0 8px 50px 0 hsla(0, 0.00%, 0.00%, 0.05);
  }
}

@media screen and (max-width: 767px) {
  .nav-menu-two-2 {
    padding-bottom: 30px;
    flex-direction: column;
    border-radius: 20px;
  }
}

@media screen and (max-width: 479px) {
  .nav-menu-two-2 {
    flex-direction: column;
  }
}

.nav-link-2 {
  margin-right: 5px;
  margin-left: 5px;
  padding: 5px 10px;

  color: #1a1b1f;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-decoration: none;
}

.nav-link-2:hover {
  color: rgba(26, 27, 31, 0.75);
}

.nav-link-2:focus-visible {
  border-radius: 4px;

  outline-color: hsla(214.6153846153846, 100.00%, 37.00%, 1.00);
  outline-offset: 0px;
  outline-style: solid;
  outline-width: 2px;

  color: hsla(214.6153846153846, 100.00%, 37.00%, 1.00);
}

@media screen and (max-width: 991px) {
  .nav-link-2 {
    padding-right: 5px;
    padding-left: 5px;
  }
}

@media screen and (max-width: 767px) {
  .nav-link-2 {
    display: inline-block;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.nav-divider-2 {
  width: 1px;
  height: 22px;
  margin-right: 15px;
  margin-left: 15px;
  background-color: hsla(212.00000000000006, 38.46%, 92.35%, 1.00);
}

@media screen and (max-width: 767px) {
  .nav-divider-2 {
    width: 200px;
    height: 1px;
    max-width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 767px) {
  .mobile-margin-top-10 {
    margin-top: 10px;
  }
}

.button-primary-6 {
  padding: 12px 25px;

  border-style: solid;
  border-width: 2px;
  border-color: hsla(0, 0.00%, 37.65%, 1.00);
  border-radius: 11px;

  background-color: transparent;

  transition: all 200ms ease;

  color: hsla(0, 0.00%, 0.00%, 1.00);
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.button-primary-6:hover {
  background-color: #32343a;
  color: hsla(0, 0.00%, 100.00%, 1.00);
}

.button-primary-6:active {
  background-color: #43464d;
}

@media screen and (max-width: 991px) {
  .menu-button-2 {
    padding: 12px;
  }
}

@media screen and (max-width: 991px) {
  .menu-button-2:global(.w--open) {
    background-color: hsla(213.60000000000002, 16.34%, 70.00%, 1.00);
    color: hsla(0, 0.00%, 100.00%, 1.00);
  }
}
.rescheduler-modal-container.card-container {
  padding: var(--project-screen-element-margin);
  width: 400px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.rescheduler-modal-container.card-container > .container-fluid.centered-screen {
  min-height: 400px;
  height: fit-content;
}

.rescheduler-modal-body {
  display: flex;
  max-width: fit-content;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: auto;
}

.rescheduler-modal-body
  > .booking-calendar-container
  > .react-calendar
  > .react-calendar__navigation
  > .react-calendar__navigation__next-button {
  justify-content: center;
  align-items: center;
}
.rescheduler-modal-body
  > .booking-calendar-container
  > .react-calendar
  > .react-calendar__navigation
  > .react-calendar__navigation__prev-button {
  justify-content: center;
  align-items: center;
}

.rescheduler-modal-requested-datetime-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.rescheduler-modal-selector-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 75%;
  margin-bottom: 15px;
}

.rescheduler-modal-selector-container > .b1 {
  margin-bottom: 15px;
}

@media screen and (max-width: 450px) {
  .rescheduler-modal-container.card-container {
    padding: var(--project-screen-element-margin);
    flex-direction: column;
    width: 85vw;
  }
}

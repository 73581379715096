.calendar-setup-loading-container {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.calendar-setup-container {
  flex-direction: column;
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.calendar-setup-container > p {
  padding: 1em;
  text-align: center;
}

.calendar-setup-button {
  width: 80%;
  background-color: var(--white);
  color: var(--black);
  border: 1px solid var(--black);
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 50px;
  cursor: pointer;
}

.calendar-setup-button:hover {
  filter: brightness(0.9);
}

.google-product-span {
  display: inline-block;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 20px !important;
}

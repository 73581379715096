.exit-icon {
  cursor: pointer;
  position: absolute;
  top: 35px;
  right: 30px;
  padding: 14px;
  color: var(--black);
  z-index: var(--modal-exit-icon-z-index);
}

.exit-icon.hide {
  display: none;
}

.base-modal-header {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  z-index: 1;
  position: relative;
}

.manage-budget-approvers-container {
    width: 100%;
}

.manage-budget-approver-modal {
    flex-direction: column;
    padding: 10px;
    display: flex;
    min-height: 100px;
}

.manage-budget-approver-modal-button-container {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
}

.manage-budget-approver-modal-button-container > * {
    flex:1;
    margin: 0 5px;
}

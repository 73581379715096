.explore-section-header-text {
  font-size: 24px;
  line-height: 28px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.48px;
}

.explore-header-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: baseline;
  border-bottom: 1px solid var(--light-grey);
  margin-bottom: 15px;
  padding-bottom: 15px;
}

.explore-view-more-top-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.explore-view-more-bottom-button {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  margin-bottom: 30px;
  width: fit-content;
  margin-left: auto;
}
.verified-list-container {
  min-height: 335px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 30px;
}

.verified-list-container > .trending-profile-link-container >.trending-profile-card-container {
  margin: 0.1em;
  margin-bottom: 30px;
}

@media screen and (max-width: 768px) {
  .verified-list-container {
    justify-content: center;
  }
  .explore-header-container {
    width: calc(100% - 30px);
    margin-left: auto;
    margin-right: auto;
  }
}

.artist-review-file-download-button-container {
  display: flex;
  align-items: center;
  font-size: 12px;
  cursor: pointer;
  font-weight: bold;
  transition: all ease-in 0.3s;
}

.artist-review-file-download-button-container:hover {
  transform: scale(0.9);
}

.artist-review-download-text {
  margin-right: 8px;
}

.full-width-trophy-item,
.trophy-list-item {
  width: 100%;
  margin-bottom: 1em;
  margin-left: 1em;
  display: flex;
}

.full-width-trophy-item {
  position: relative;
  width: 100%;
  height: 40px;
}

@media screen and (max-width: 1200px) {
  .trophy-card-container > .full-width-trophy-item {
    max-width: 200px;
  }
}

@media screen and (max-width: 500px) {
  .trophy-card-container > .trophy-list-item {
    max-width: 200px;
  }
}

.trophy-texts {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
}

.studio-subheader-badges-container .trophy-texts {
  margin-left: 5px;
}

.trophy-list-text,
.trophy-list-textStatus {
  align-items: left;
  margin-left: 15px;
  display: flex;
  font-weight: bolder;
  padding-left: 15px;
  padding-right: 5px;
  text-align: left;
  margin: 0;
}

.trophy-list-textStatus {
  font-weight: 400;
  color: var(--medium-grey);
  flex: 1;
}

.badge-list {
  min-width: 220px;
}

.trophy-card-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  height: fit-content;
}

@media screen and (max-width: 991px) {
  .badge-list {
    width: 100%;
    margin-left: 25px;
    min-width: 210px;
  }
}

@media screen and (max-width: 767px) {
  .badge-list {
    width: 100%;
    margin: 0;
    min-width: unset;
  }
}

.trophy-container.card-container {
  flex-wrap: wrap;
  justify-content: flex-start;
  background-color: var(--smooth-ivory);
  padding-top: 20px;
}

.trophy-list-shared-muso-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  align-items: flex-start;
  padding-bottom: 10px;
}

.trophy-list-single-column-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 50%;
  height: 100%;
}

.trophy-list-single-column-container > .trophy-list-item {
  max-width: 220px;
}

.trophy-column-header {
  display: flex;
  flex-direction: row;
}

.trophy-list-header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

@media screen and (max-width: 1200px) {
  .trophy-card-container {
    flex-direction: column;
  }
  .trophy-card-container ul {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}

.trophy-loader {
  filter: grayscale(100%);
}

/* Trophy Card CSS */

.trophy-column-container {
  background-color: var(--white);
  padding: 35px 30px 35px 30px;
  width: calc(100% - 60px);
  height: calc(100% - 70px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: auto;
}

.trophy-items-container {
  display: flex;
  align-content: flex-start;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  padding-left: 0px;
}

.view-more-container {
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  align-self: flex-start;
  color: var(--black);
}

.trophy-items-container > .trophy-list-item {
  max-width: 220px;
}

@media screen and (max-width: 480px) {
  .trophy-column-container {
    padding: 30px 15px;
    height: calc(100% - 60px);
    width: calc(100% - 30px);
  }

  .trophy-items-container {
    height: 100%;
    width: 100%;
  }
  .trophy-items-container > .trophy-list-item {
    max-width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .trophy-texts {
    padding-right: 15px;
    margin-right: auto;
    overflow: hidden;
    position: relative;
  }
}

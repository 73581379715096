.invoice-container {
  background-color: var(--white);
}
.invoice-breakdown {
  background-color: var(--twilight-blue);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.invoice-subtotal {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.breakdown-text {
  color: var(--gray-no-dark-theme);
}

.invoice-service {
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
}

.invoice-total {
  background-color: var(--sky-light-blue);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.invoice-calculated {
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
}

.invoice-subtotal-text {
  color: var(--gray-no-dark-theme);
}

.invoice-total-text {
  color: var(--black-no-dark-theme);
}

.invoice-breakdown:first-child {
  padding-top: 5px;
}

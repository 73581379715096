.chat-room-wrapper {
    max-height: 100%;
    overflow: auto;
    height: 100%;
    flex: 1;
}

.channel-list-wrapper {
    max-height: 100%;
    overflow: auto;
}

.str-chat__channel-list-messenger {
    background-color: var(--smooth-ivory);
}

.str-chat {
    max-height: initial;
    height: 100%;
}

.str-chat.messaging, .str-chat__container, .str-chat__list, .str-chat__main-panel {
    max-height: initial;
    background-color: var(--smooth-ivory);
}

.str-chat.str-chat-channel.messaging  {
    max-height: 100vh;
}
.str-chat__list {
    flex-grow: 1;
    min-height: 36vh; /* Prevents the list from being closed with no messages */
    max-height: 70vh; /* This is a magic number calculating the height between the header and the input footer */
}

.str-chat__message-input {
    position: sticky;
    bottom: 0;
}

.str-chat__modal__inner {
    height: 80%;
    width: 95%;
    & .image-gallery, & .image-gallery-content, & .image-gallery-slide-wrapper, & .image-gallery-slides {
        height: 100%;
    }
    & .image-gallery-slides {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        & .image-gallery-slide {
            position: relative;
        }
    }
}
.studio-calendar-widget-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  background-color: var(--white);
  max-width: calc(555px - 60px);
  overflow: hidden;
  padding: 30px;
  margin-left: auto;
  width: 80%;
  height: fit-content;
}

.recording-calendar-room-options-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 30px;
}

.recording-calendar-room-option-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.recording-calendar-room-option-container:first-child {
  margin-right: 10px;
}

.recording-calendar-room-option-container
  > .drop-down-selector-container
  > .drop-down-select-content
  > .select-container {
  border-radius: 8px;
  height: 45px;
  border: solid 0.75px var(--light-grey);
  margin-top: 0.5em;
}
.recording-calendar-room-option-container
  > .drop-down-selector-container
  > .drop-down-select-content
  > .select-container
  > .select-arrow-container {
  border: none;
}

.calendar-text-view {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
}

.calendar-text-view > p {
  text-align: center;
  color: var(--medium-grey);
}

.studio-calendar-container {
  width: 100%;
  height: unset;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.studio-calendar-widget-button {
  margin-top: 24px;
  border-color: var(--light-grey);
  border-width: 0.5px;
  font-weight: 600;
  text-transform: unset;
}

.studio-calendar-header-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.studio-calendar-header-container-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.price-container {
  position: relative;
}

.edit-service-button {
  cursor: pointer;
  position: absolute;
  right: -20px;
  top: 0;
}

.studio-calendar-header-container-row:not(:first-child) > p {
  color: var(--medium-grey);
}

.studio-calendar-widget-header-text {
  margin-bottom: 10px;
}

.studio-calendar-disable-button {
  color: var(--grey);
  cursor: not-allowed;
}

.studio-calendar-booking-status-switch {
  margin-top: 10px;
}

.studio-calendar-discount-rates-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  height: inherit;
  overflow-x: auto;
  width: 100%;
}

.studio-calendar-widget-container > .studio-calendar-discount-rates-container {
  margin-top: 5px;
  margin-bottom: 5px;
}
.studio-calendar-discount-rates-container
  > .studio-calendar-discount-rate-container {
  margin-right: 10px;
}

.studio-calendar-discount-rate-container {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: fit-content;
  border-radius: 50px;
  cursor: pointer;
  border: 1px solid var(--black);
  padding: 10px 15px;
}

.studio-calendar-discount-rate-selected {
  background-color: var(--black);
  text-decoration-color: var(--white);
}
.studio-calendar-discount-rate-selected > p {
  color: white;
}

@media screen and (max-width: 768px) {
  .studio-calendar-discount-rates-container {
    width: 300px;
    overflow-x: scroll;
  }
}

.studio-calendar-container > .booking-calendar-container {
  width: 100%;
  height: fit-content;
  padding: 0;
}

.studio-calendar-container > .booking-calendar-container > .react-calendar {
  max-width: unset;
  border: none;
  background-color: var(--white);
}

.studio-rooom-calendar-breakdown-view {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.studio-rooom-calendar-breakdown-view {
  margin-bottom: 10px;
}

.studio-rooom-calendar-breakdown-view > p:first-of-type {
  text-transform: capitalize;
}

.studio-rooom-calendar-breakdown-view:last-child {
  margin-top: 15px;
  padding-top: 15px;
  border-top: solid 1px var(--medium-grey);
}

.studio-rooom-calendar-breakdown-view:last-child > p {
  font-weight: 600;
}

.studio-calendar-header-container.recording-booking {
  margin-bottom: 30px;
}

.completed-session-date {
  position: relative;
  z-index: 1;
}

.not-currently-selected-date {
  position: relative;
  z-index: 1;
}

.invalid-session-date > abbr {
  color: var(--notification-indicator-color);
}

.invalid-session-date--selected {
  position: relative;
  z-index: 1;
}

.invalid-session-date--selected > abbr {
  color: var(--notification-indicator-color);
}

.invalid-session-date--selected::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--checkbox-color--disabled);
  clip-path: circle(25% at 50% 50%);
}

.completed-session-date::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--boomy-orange) !important;
  clip-path: circle(25% at 50% 50%);
}

.completed-session-date > abbr {
  color: black !important;
}

.not-currently-selected-date::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--light-grey);
  clip-path: circle(25% at 50% 50%);
}

@media screen and (max-width: 768px) {
  .studio-calendar-container > .booking-calendar-container {
    max-width: unset;
    height: 297px;
  }

  .studio-calendar-discount-rates-container {
    flex-wrap: nowrap;
  }

  .studio-calendar-header-container-row:not(:first-child)
    > p:not(:first-child) {
    display: none;
  }
}

@media screen and (max-width: 991px) {
  .studio-calendar-widget-container {
    margin-right: auto;
  }
}

@media screen and (min-width: 992px) and (min-height: 968px) {
  .studio-calendar-widget-container {
    position: sticky;
    top: calc(var(--header-height) + 8px);
  }
}

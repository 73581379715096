.user-card-container {
  width: auto;
  height: 64px;
  background-color: var(--smooth-ivory);
  display: flex;
  justify-content: flex-start;
  font-family: Roobert;
  font-size: 12px;
  font-weight: 400;
}

.profile-icon {
  padding-right: 10px;
}

.profile-info {
  display: flex;
  flex-direction: column;
}

.profile-username-display {
  display: flex;
}

.profile-username-display p {
  font-size: 22px;
  font-weight: 600;
  letter-spacing: -0.48px;
  display: flex;
  justify-content: space-between;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.verified-user-container {
  align-items: center;
  padding-left: 5px;
}

.account-types {
  color: var(--gray-500);
}

.profile-stats {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.profile-stats span {
  padding-right: 2px;
  color: var(--gray-500);
}

.profile-rating {
  width: fit-content;
  border-radius: 5px;
  padding: 3px 4px 4px 5px;
  display: flex;
  align-items: center;
}

.profile-rating span {
  font-weight: 800 !important;
  letter-spacing: -0.24px;
}

@media (max-width: 768px) {
  .user-card-container {
    width: 100%;
  }

  .profile-username-display {
    font-size: 18px;
    justify-content: flex-start;
  }

  .profile-username-display span {
    padding-right: 5px;
  }

  .profile-stats {
    margin-top: -5px;
  }

  .profile-rating {
    margin-top: 5px;
  }
}

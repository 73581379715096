.nav-search-results-container {
  border-radius: 0 0 var(--border-radius-md) var(--border-radius-md);
  background-color: var(--foreground-color);
  max-height: 0;
  overflow: hidden;
  padding-left: 16px;
  position: absolute;
  left: 0px;
  width: calc(100% - 16px);
  z-index: 1;
  transition: max-height 0.2s ease-in-out;
}

.nav-search-results-container.show {
  transition: max-height 0.5s ease-in-out;
  -moz-box-shadow: var(--box-shadow-size-and-color);
  -webkit-box-shadow: var(--box-shadow-size-and-color);
  box-shadow: var(--box-shadow-size-and-color);
  max-height: 1500px;
}

.nav-entity-search-result-list {
  margin: 0 !important;
  padding: 16px 0 6px 0;
}

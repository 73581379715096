.collapsable-header {
  color: var(--medium-grey);
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  align-items: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
}

.extend-text-container {
  &:hover {
    cursor: pointer;
  }
}

.expandable-text-area .extend-text {
  color: var(--black);
  cursor: pointer;
  margin-right: 5px;
}

.body-text {
  color: var(--black);
  background-color: inherit;
  border: none;
  outline: none;
  resize: none;
  width: 100%;
  -webkit-mask-image: unset;
  mask-image: unset;
  white-space: pre-wrap;
}

.body-text-clamped {
  -webkit-line-clamp: 3;
  line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.body-text-fade {
  -webkit-mask-image: linear-gradient(
    to bottom,
    var(--black) 50%,
    transparent 100%
  );
  mask-image: linear-gradient(to bottom, var(--black) 50%, transparent 100%);
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h1,
.h1,
h2,
.h2,
.h2-semi-bold,
h3,
.h3,
h4,
.h4,
h5,
.h5,
.h6-semi-bold,
h6,
.h6,
.h7-semi-bold,
.h7,
.h8-semi-bold,
.b1,
.b1-semi-bold,
.b2,
.b2-semi-bold,
.b3,
.b3-semi-bold,
.b4,
.label,
.label-semi-bold,
.label2,
.label2-semi-bold,
.label-button,
.caption,
.caption-semi-bold,
.semi-bold-ul,
p,
strong,
ul,
li,
tr,
td,
label,
header {
  color: var(--black);
}
input {
  color: var(--gray-950);
}

body {
  background-color: var(--smooth-ivory);
}

p [data-theme="dark"] {
  color: var(--black);
}

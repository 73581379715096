.profile-drop-down {
  align-items: center;
  position: relative;
  display: flex;
  background-color: var(--background-color);
  border-radius: 0 0 var(--border-radius-sm) var(--border-radius-sm);
  cursor: pointer;
}

.profile-drop-down.drop-down-enabled {
  -moz-box-shadow: var(--box-shadow-size-and-color);
  -webkit-box-shadow: var(--box-shadow-size-and-color);
  box-shadow: var(--box-shadow-size-and-color);
}

.drop-down-list {
  left: 0px;
  width: 100%;
  position: absolute;
  top: 0px;
  background-color: var(--background-color);
  z-index: var(--nav-profile-drop-down-z-index);
  padding: 0;
  max-height: 0;
  overflow-y: hidden;
  border-radius: 0 0 var(--border-radius-sm) var(--border-radius-sm);
  transition: var(--nav-profile-dropdown-transition--up);
}

.drop-down-list.drop-down-enabled {
  transition: var(--nav-profile-dropdown-transition--down);
  -moz-box-shadow: var(--box-shadow-size-and-color);
  -webkit-box-shadow: var(--box-shadow-size-and-color);
  box-shadow: var(--box-shadow-size-and-color);
  max-height: 1500px;
}

.promo-switch-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.engineear-campaign-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: auto;
  align-items: center;
  position: relative;
  padding: var(--project-screen-element-margin);
  padding: 10px 0;
  width: 90%;
}

.platform-opt-in-more-info {
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .engineear-campaign-header
    > .tooltip-helper-container.tooltip
    > .tooltiptext {
    transform: translate(-100%, 25px);
  }
}

.switch {
  min-width: 54px;
}

.competition-modal {
  display: block;
  flex-direction: column;
  height: fit-content;
  padding: var(--project-screen-element-margin);
  animation: fadeIn 500ms ease-in-out;
  color: var(--black);
  width: 90%;
  max-height: 80hv;
  overflow-y: auto;
}

.custom-map-marker {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 52px;
  height: 26px;
  border-radius: 12.5px;
  color: var(--black-no-dark-theme);
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  z-index: 10;
}

.custom-map-marker-studio {
  background-color: var(--sky-light-blue);
  border: 1px solid var(--deep-blue);
}

.custom-map-marker-user {
  background-color: var(--echo-orange);
  border: 1px solid var(--boomy-orange);
}

.map-search-display-center-button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 70px;
  left: 20px;
  z-index: 10;
  height: 40px;
  width: 40px;
  padding: 4px;
  background-color: var(--white-no-dark-theme);
  border-radius: 50%;
  box-shadow: 5px 5px 10px var(--gray-no-dark-theme);
  cursor: pointer;
}

.map-search-display-center-icon {
  height: 20px;
  width: 20px;
  margin-right: 3px;
  margin-top: 3px;
}

.map-search-display-notification {
  position: relative;
  display: flex;
  width: 250px;
  justify-content: center;
  top: 0px;
  left: calc(50% - 125px);
  z-index: 10;
  border: 1px solid var(--black-no-dark-theme);
  background-color: var(--smooth-ivory-no-dark-theme);
  padding: 8px;
  font-weight: 600;
}

.map-search-display-profile-container {
  padding: 10px;
  background-color: var(--white-no-dark-theme);
  box-shadow: 5px 5px 10px var(--gray-no-dark-theme);
  border-radius: 5px;
  z-index: 10;
  margin: 10px;
  position: fixed;
  left: -50vw;
  top: -25vh;
}

@media (min-width: 768px) {
  .map-quadrant-TopLeft {
    position: absolute;
    top: 20px;
    left: -10px;
  }

  .map-quadrant-TopRight {
    position: absolute;
    top: 20px;
    left: -262px;
  }

  .map-quadrant-BottomLeft {
    position: absolute;
    top: -306px;
    left: -10px;
  }

  .map-quadrant-BottomRight {
    position: absolute;
    top: -306px;
    left: -262px;
  }
}

.map-search-display-empty-state {
  width: 800px;
  position: relative;
  z-index: 10;
  top: 100px;
  left: calc(50% - 400px);
  box-shadow: 5px 5px 10px var(--gray-no-dark-theme);
  border-radius: 10px;
}

@media (max-width: 768px) {
  .map-search-display-empty-state {
    width: calc(100vw - 20px);
    position: relative;
    z-index: 10;
    top: 100px;
    left: calc(50% - 50vw + 10px);
    box-shadow: 5px 5px 10px var(--gray-no-dark-theme);
    border-radius: 10px;
  }
}

.map-search-display-empty-state .studio-screen-request-container {
  margin: 0;
  border: none;
}

.map-search-display-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.map-search-pill-icon {
  margin-right: 4px;
}

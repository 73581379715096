.studio-modal {
  background-color: var(--white);
  border-radius: 4px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 97vw;
  max-width: 744px;
  max-height: 90vh;
  min-height: 395px;
}

.studio-room-modal {
  min-height: 200px;
}

.modal-footer {
  justify-content: flex-end !important;
  gap: 8px;
}

.modal-header {
  background-color: var(--white);
  border-bottom: 1px solid var(--light-grey);
  border-top: 1px solid var(--light-grey);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
  position: relative;
  height: fit-content;
}

.modal-header > h2 {
  width: 100%;
}
.modal-header:first-child {
  border-top: none;
}

.close-modal-button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: auto;
  margin-top: auto;
}

.modal-body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 16px;
  overflow-y: scroll;
  margin: 0.2em;
}

.modal-body > * {
  margin-bottom: 0.5em;
}

.studio-save-button {
  margin-left: auto !important;
}

.date-input-studio-birthdate {
  all: unset;
  transition: all ease-in 500ms;
  color: var(--black);
  border: 1px solid var(--light-grey);
  box-sizing: border-box;
  border-radius: 10px;
  padding: 10px;
}

.remove-studio-calendar-integration-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 1em;
  text-align: center;
}

.edit-studio-modal-checkbox .check-box-container {
  padding-left: 0;
}

.edit-modal-primary-image {
  height: 300px;
  min-height: 300px;
  width: 100%;
  display: flex;
}

.edit-modal-primary-image .primary-entity-photo {
  height: 300px !important;
  width: 100%;
  object-fit: contain;
}

/* DO NOT REMOVE. This makes the google autocomplete suggestions show up. */
.pac-container {
  z-index: var(--location-auto-complete-z-index) !important;
}

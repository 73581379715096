.notifications-table {
  flex: 1;
  width: 100%;
  max-width: 400px;
}

.notifications-table > tbody {
  width: inherit;
}

@media screen and (max-width: 768px) {
  .notifications-table {
    max-width: 100%;
  }
}

.notifications-row {
  background-color: var(--white);
}

.notifications-row:hover {
  filter: brightness(95%);
}

.notification-link {
  text-decoration: none;
  color: inherit;
}

.load-more-link {
  background-color: unset;
  border: none;
  outline: none;
  text-transform: uppercase;
  align-self: center;
  cursor: pointer;
  width: 100%;
  height: 30px;
  margin-bottom: 50px;
}

.no-notifications-text {
  margin: 24px;
}

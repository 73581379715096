.over-view-screen-tab-list-container {
  display: flex;
  width: 100%;
}
.over-view-screen-tab-list-container:first-child {
  margin-right: auto;
}

.project-collaborators-header {
  display: none;
}

.project-overview-flex-container {
  display: flex;
  flex-direction: row;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  margin-top: var(--project-screen-element-margin);
  margin-bottom: 100px;
}

.project-overview-left-column {
  margin-right: var(--project-screen-element-margin);
}

.project-overview-main-view {
  flex: 1;
}

.project-overview-main-view > div:first-of-type {
  margin-bottom: var(--project-screen-element-margin);
  width: 100%;
}

.project-overview-title-row {
  display: flex;
  flex-direction: row;
}
.project-overview-title-row > .title-card {
  flex: 3;
}

.purchase-order-approval-container {
  flex: 2;
  display: flex;
  margin-left: 1em;
}

.asset-approval-modal {
  flex-direction: column;
  display: flex;

}

.asset-review-card {
  display: flex;
  padding: 30px;
  width: 100%;
  min-height: 200px;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  overflow: visible;
}

.asset-review-card > .asset-review-drop-down-container {
  width: 100%;
}

.asset-review-card > .asset-review-drop-down-container  >  button {
  width: 100%;
}


.asset-review-card > p > span {
  margin-right: 0.5em;
  font-weight: bold;
}

@media screen and (max-width: 1128px) {
  .project-overview-title-row {
    flex-direction: column;
    align-items: center;
  }
  .purchase-order-approval-container {
    flex: 3;
    display: flex;
    width: 100%;
    margin-left: 0;
  }
}

@media screen and (max-width: 1128px) {
  .project-overview-flex-container {
    flex-direction: column;
    align-items: center;
  }
  .project-overview-left-column {
    display: none;
  }
  .project-collaborators-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  .project-collaborators-header > .collaborators-title {
    margin-bottom: 5px;
  }
  .project-overview-main-view {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}

.project-page-container {
  background-color: var(--smooth-ivory);
  display: flex;
  justify-content: center;
  align-items: center;
}

.project-page-flex-container {
  position: absolute;
  top: 105px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100% - 105px);
  background-color: var(--smooth-ivory);
}

.project-chat-panel {
  width: auto;
  height: auto;
  overflow-x: scroll;
  border-top-left-radius: 17px;
  border-top-right-radius: 17px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--white) !important;
  box-shadow: 0 0 6px rgba(109, 109, 109, 0.18) !important;
  padding: 0;
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: var(--project-chat-panel-z-index);
  transition: all ease-in-out 500ms;
}

.project-chat-header {
  border-bottom: solid thin var(--light-grey);
  padding-top: 1em;
  padding-bottom: 1em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
}
.x-icon-container {
  position: absolute;
  right: 10px;
  display: flex;
  justify-content: center;
  transition: all ease-in 500ms;
}

.x-icon-container:hover {
  transform: scale(1.2);
  cursor: pointer;
}

.project-chat-panel .str-chat__input-flat {
  padding: 10px;
}

.floating-chat-button-container {
  position: fixed;
  right: 5vw;
  bottom: 16px;
  z-index: var(--floating-chat-button-z-index);
  animation: fadeIn 1s ease-in-out;
}

.project-chat-panel:not(.show) {
  transform: translateY(125%);
}

@media screen and (max-width: 768px) {
  .floating-chat-button-container {
    bottom: calc(var(--bottom-nav-height) + 8px);
  }
  .str-chat__main-panel  {
    min-width: 100vw;
  }
}

@media screen and (max-width: 960px) {
  .str-chat-channel-list .str-chat__channel-list-messenger__main {
    background-color: var(--white);
    padding: 0;
    border-radius: 0;
    border: solid thin lightgray;
  }
  .message-screen-container {
    align-items: flex-start;
  }
}

.icon-button-container {
  display: flex;
}

.icon-button-container .remove-icon-animation {
  right: 4px;
}

.icon-button {
  background: transparent;
  border: none;
  cursor: pointer;
}

.svg-icon {
  width: 38px;
}

.icon-button-edit {
  background: transparent;
  border: none;
  cursor: pointer;
}

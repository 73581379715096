.request-modal-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: var(--white);
  padding: 30px 40px 33px 40px;
  border-radius: 15px;
}

.request-modal-setup-profile {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 10px;
}

.request-modal-setup-profile-alt-exit-button {
  cursor: pointer;
}

.request-modal-body {
  overflow-y: auto;
}

.request-modal-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
}

.request-modal-header > .studio-screen-request-logo {
  margin-bottom: 10px;
}

.request-modal-header > .b1 {
  color: var(--transparent-grey);
  margin-bottom: 15px;
}

.request-body-element {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}

.request-modal-body > .request-body-element {
  margin-bottom: 15px;
}

.request-body-element > .b3-semi-bold {
  margin-bottom: 4px;
}

.request-modal-checkbox {
  margin-bottom: 30px;
}

.request-modal-checkbox > .check-box-container {
  padding-left: unset;
}

.request-modal-body > .v1-button {
  width: 100%;
  border: unset;
}

.request-modal-success-screen-overlay {
  width: inherit;
  height: 100%;
  display: flex;
  position: absolute;
  border-radius: 15px;
  flex-direction: column;
  justify-content: space-evenly;
  transition: all linear 0.5s;
  top: 1em;
  left: 1em;
  right: 1em;
  bottom: 1em;
  z-index: 1;
  background-color: var(--white);
}

.request-modal-success-screen-overlay.hide {
  transform: translateX(200%);
}

.request-modal-success-screen-overlay.show {
  transform: translateX(0%);
}

.request-body-element > .drop-down-selector-container > select {
  border-radius: 8px;
  border: 0.75px solid var(--light-grey);
}

.request-body-element > select {
  width: 100%;
  text-align: left;
  height: 45px;
  border-radius: 8px;
  border: 0.75px solid var(--light-grey);
  background-color: var(--white);
  color: var(--black);
}

.request-studio-phone-input {
  width: 100%;
  height: 45px;
  background: var(--white);
  padding: 10px;
  border: 0.75px solid var(--light-grey);
  box-sizing: border-box;
  border-radius: 8px;
}

.request-studio-phone-input > .PhoneInputInput {
  border: none;
}
.request-studio-phone-input > .PhoneInputInput:focus {
  outline: none;
}

@media screen and (max-width: 1290px) {
  .request-modal-success-screen-overlay {
    width: inherit;
    height: 100%;
    display: flex;
    position: absolute;
    border-radius: 15px;
    flex-direction: column;
    justify-content: space-evenly;
    transition: all linear 0.5s;
  }

  .request-modal-body {
    height: 350px;
    width: unset;
    overflow-y: auto;
  }
}

@media screen and (max-width: 768px) {
  .request-modal-container {
    padding-left: 30px;
    padding-right: 30px;
  }
}

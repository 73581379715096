.checkout-screen-header-container {
  position: relative;
}

.card-header-row {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.checkout-header {
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.02em;
  margin-bottom: 10px;
}

.card-header {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
}

@media (max-width: 576px) {
  .checkout-header {
    width: 100%;
    font-size: 18px;
  }
}

.card-header-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.select-tracking-engineer-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
}

.select-tracking-engineer-option-left {
  display: flex;
  align-items: center;
}

.select-tracking-engineer-option img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

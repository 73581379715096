.sign-in-screen-container {
  display: flex;
  flex: 1;
  align-items: center;
  width: 100%;
  height: fit-content;
  justify-content: center;
  flex-direction: column;
  background-image: url("../../assets/floating-image-left-right.png");
  background-position: 0% 40%;
  background-repeat: no-repeat;
  background-size: 100%;
  background-color: var(--echo-orange);
}

.sign-in-form-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
}

.sign-in-form-container > div {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

@media screen and (max-width: 376px) and (min-height: 800px) {
  .sign-in-screen-container {
    background-image: url("../../assets/floating-image-left-right-mobile.png");
    background-position: 50% 25%;
    background-repeat: no-repeat;
    background-size: 90%;
  }

  .sign-in-form-container {
    position: relative;
    top: 20%;
    max-height: 0%;
    width: 100%;
  }

  .menu {
    position: inherit !important;
  }
}

@media screen and (max-width: 700px) and (min-height: 1200px) {
  .sign-in-screen-container {
    background-image: url("../../assets/floating-image-left-right-mobile.png");
    background-position: 50% 25%;
    background-repeat: no-repeat;
    background-size: 90%;
  }

  .sign-in-form-container {
    position: relative;
    top: 20%;
    max-height: 0%;
    width: 100%;
  }

  .menu {
    position: inherit !important;
  }
}

@media screen and (max-width: 700px) and (max-height: 800px) {
  .sign-in-screen-container {
    background: var(--echo-orange);
  }

  .sign-in-form-container {
    top: 0;
  }

  .menu {
    z-index: 1;
  }
}

@media screen and (max-width: 700px) and (max-height: 1200px) and (min-width: 376px) and (min-height: 800px) {
  .sign-in-screen-container {
    background: var(--echo-orange);
  }

  .sign-in-form-container {
    top: 0;
  }

  .menu {
    z-index: 1;
  }
}
